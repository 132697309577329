import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Snackbar,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import oclockTheme from "../../assets/theme";
import oclockLogo from "../../assets/Group3.png";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { FormEvent, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { snackarDataType } from "../../domain/types";

import { useHistory } from "react-router";

export default function Login() {
  const { isAuthenticated, signIn } = useContext(AuthContext);
  const history = useHistory();

  const [matricula, setMatricula] = useState("");
  const [password, setPassword] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [snackbarData, setSnackbarData] = useState<snackarDataType>();

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions

    setIsFetching(true);

    await signIn({ matricula, password })
      .then((result) => {
        setTimeout(() => {
          if (result.statusCode !== 200) {
            setSnackbarData({
              show: true,
              type: "error",
              message: result.body.message,
            });
          }
          else {
            history.push("/")
          }
          setIsFetching(false)
        }, 1000)
      })
      .catch(erro => {
        setIsFetching(false)
        setSnackbarData({
          show: true,
          type: "error",
          message: "Um erro inesperado ocorreu. Favor entrar em contato com o administrador do sistema",
        });
      })
  };

  useEffect(() => {
    document.title = "O'Clock - Login"

    const historyState: any = history.location.state

    if (!isAuthenticated && historyState?.comingFrom && historyState?.comingFrom === "main-page") {
      setSnackbarData({
        show: true,
        type: "info",
        message: "Você será redirecionado após o login"
      })
    }

  }, [isAuthenticated, history.location.state])

  return (
    <ThemeProvider theme={oclockTheme}>
      <Snackbar
        open={snackbarData?.show}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          variant="filled"
          severity={snackbarData?.type}
          onClose={() => {
            setSnackbarData({ ...snackbarData, show: false });
          }}
        >
          <AlertTitle>{snackbarData?.message}</AlertTitle>
        </Alert>
      </Snackbar>

      <Container
        fixed
        sx={{
          backgroundColor: "primary.main",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            color: "white",
          }}
        >
          <header>
            <img src={oclockLogo} alt="O'Clock logo" />
            <p>Sistema para gerenciamento</p>
            <p>de horas</p>
          </header>

          <h3
            style={{
              color: "white",
              fontWeight: 200,
              margin: "20px",
            }}
          >
            Login
          </h3>

          <section>
            <form
              style={{
                textAlign: "left",
              }}
              onSubmit={(e) => handleLogin(e)}
            >
              <div>
                <label htmlFor="matricula" style={{ display: "block" }}>Matrícula</label>
                <Input
                  id="matricula"
                  type="text"
                  value={matricula}
                  onChange={(e) => setMatricula(e.target.value)}
                  required
                />
              </div>
              <div
                style={{
                  margin: "20px 0",
                }}
              >
                <label htmlFor="senha" style={{ display: "block" }}>Senha</label>
                <Input
                  id="senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {isFetching ? <CircularProgress color="inherit" /> : <Button type="submit">Entrar</Button>}

              </Box>
            </form>
          </section>

          <div style={{ marginTop: "20px" }}>
            <Typography sx={{
              color: "secondary.main", cursor: "pointer", "&:hover": {
                textDecorationLine: "underline"
              }
            }} onClick={() => history.push("/redefinir-senha")}>Esqueci minha senha</Typography>
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
