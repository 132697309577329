//importa os atributos HTML para button
import { ButtonHTMLAttributes } from "react";

import style from "./ButtonStyle.module.scss";

//atribui a tipagem de buttons para o elemento HTMLButtonElement
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  bgColor?: string;
  fontSize?: string;
  margin?: string
};

//passa essa tipagem para props, determinando o tipo de valor esperado
//para cada atributo
export default function Button({ fontSize, bgColor, margin, ...props }: ButtonProps) {
  return (
    //desestruturação do objeto em chave="valor" || atributo="valor"
    <button
      style={{ backgroundColor: bgColor || "#FDC600", fontSize: fontSize || '1em', margin: margin || 'none'}} 
      className={style.customButton}
      {...props}
    />
  );
}
