import { Box, Grid, Tooltip, Typography } from "@mui/material";
import LembreteCard from "../../../components/Lembretes/LembreteCard";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { approvedHourDiscount, tecnico } from "../../../domain/types";
import semLembretesIcon from "../../../assets/sem-lembretes.png";
import CardTecsMoreAndLessHours from "./CardTecsMoreAndLessHours";
import HelpIcon from '@mui/icons-material/Help';

type props = {
    approvedDiscountsToRemember: approvedHourDiscount[];
    tecnicoWithLessHours?: tecnico;
    tecnicoWithMoreHours?: tecnico;
}

const AdminHomeSide = ({ approvedDiscountsToRemember,
    tecnicoWithLessHours,
    tecnicoWithMoreHours }: props) => (
    <Grid
        md={4}
        item
        sx={{
            display: "flex",
            height: "100vh",
            flexDirection: "column",
        }}
    >
        <section>
            <header style={{
                display: "flex",
                alignItems: "baseline",
                gap: "5px"
            }}>

                <SectionTitle title="Lembretes" />
                <Tooltip
                    title={
                        <Typography>
                            Os lembretes não deixarão você se esquecer da ausência de colaboradores que possuem desconto de horas aprovados para os próximos dias
                        </Typography>
                    }

                    arrow
                >
                    <HelpIcon
                        sx={{
                            color: '#0D8493',
                            fontSize: "18px"
                        }} />
                </Tooltip>
            </header>                {approvedDiscountsToRemember.length > 0 && <Typography sx={{ marginBottom: "10px" }}>Recordando que o colaborador abaixo estará ausente no período informado</Typography>}

            {
                approvedDiscountsToRemember.length > 0 ?
                    (
                        approvedDiscountsToRemember.map((registry, index) => (
                            <LembreteCard key={index} registroAprovado={registry} />
                        ))
                    )
                    :
                    (
                        <Box
                            sx={{
                                textAlign: "center",
                            }}
                        >

                            <img src={semLembretesIcon} alt="Ícone de não há lembretes" />
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                }}
                            >
                                Não há lembretes para mostrar
                            </Typography>
                        </Box>
                    )
            }
        </section>

        <Box
            sx={{
                marginTop: "15px"
            }}
        >

            <CardTecsMoreAndLessHours
                tecnicoWithLessHours={tecnicoWithLessHours}
                tecnicoWithMoreHours={tecnicoWithMoreHours}
            />
        </Box>
    </Grid>
)

export default AdminHomeSide