import { HttpResponse } from "../http/IHttpClient";
import { HttpClient } from "../http/httpClient";

type props = {
	mes: number;
	ano: number;
	base64DaFolha: string;
};

async function obterPdfFolhaDePontoPreenchida({
	mes,
	ano,
	base64DaFolha,
}: props): Promise<HttpResponse> {
	const httpClient = new HttpClient();
	const httpResponse = await httpClient.request(
		{
			method: "post",
			url: `/FolhaDePonto`,
			body: {
				mes,
				ano,
				base64DaFolha,
			},
		},
		"PontoCerto"
	);

	if (httpResponse.statusCode !== 200){
		return {
			statusCode: httpResponse.statusCode,
			body:
				typeof(httpResponse.body) === "object"
					? httpResponse.body[0]
					: httpResponse.body,
		};
	};
	return httpResponse
}

export default obterPdfFolhaDePontoPreenchida;
