import { Box, Fade, Pagination, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import CardType from "../../domain/enums/cardType"
import { approvedHourDiscount, pendingHourDiscount, pendingHourRegistry, repprovedHourDiscount } from "../../domain/types"
import repproveRegistry from "../../services/administrador/repproveRegistry"
import HourRegistryCard from "../HourRegistryCard/HourRegistryCard"
import HourRegistryCardSkeleton from "../HourRegistryCard/HourRegistryCardSkeleton"

type props = {
    approvedDiscounts: Array<approvedHourDiscount>;
    repprovedDiscounts: Array<repprovedHourDiscount>;
    pendingDiscounts: Array<pendingHourDiscount>;
    sectionToShow: "Pending" | "Approved" | "Repproved";
    approveRegistry: (reg: pendingHourDiscount) => void;
    repproveRegistry: (reg: pendingHourDiscount) => void;
    isFetching: boolean;
}

export default function PaginationOfDiscountCards({ 
        approvedDiscounts, 
        repprovedDiscounts, 
        pendingDiscounts, 
        sectionToShow, 
        approveRegistry, 
        repproveRegistry,
        isFetching 
    }: props) {
    const [pageToView, setPageToView] = useState<number>(1)
    const [totalPagesPerRegisterType, setTotalPagesPerRegisterType] = useState(0)
    const [isChangingPage, setIsChangingPage] = useState<boolean>(false)
    
    useMemo(() => {
        switch (sectionToShow) {
            case "Pending":
                setTotalPagesPerRegisterType(pendingDiscounts.length)
                break;
            case "Approved":
                setTotalPagesPerRegisterType(approvedDiscounts.length)
                break;
            case "Repproved":
                setTotalPagesPerRegisterType(repprovedDiscounts.length)
                break;
        }
        setPageToView(1)
    }, [sectionToShow, approvedDiscounts, repprovedDiscounts, pendingDiscounts])

    const NothingToShow = () => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                gap: "20px"
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="418.306" height="358.951" viewBox="0 0 418.306 358.951">
                <g id="undraw_Done_re_oak4" transform="translate(0 0)">
                    <path id="Path_438" data-name="Path 438" d="M309.721,669.42a14.877,14.877,0,0,0,14.366-2.531c5.032-4.224,6.61-11.179,7.893-17.622l3.8-19.057-7.949,5.473c-5.717,3.936-11.562,8-15.52,13.7s-5.685,13.484-2.506,19.653" transform="translate(-278.31 -340.014)" fill="#e6e6e6" />
                    <path id="Path_439" data-name="Path 439" d="M311.725,704.019c-1-7.289-2.03-14.672-1.326-22.04a34.667,34.667,0,0,1,6.684-18.174,30.233,30.233,0,0,1,7.757-7.029c.775-.489,1.489.739.717,1.227a28.742,28.742,0,0,0-11.369,13.717c-2.475,6.3-2.873,13.158-2.446,19.845.258,4.044.805,8.062,1.355,12.074a.736.736,0,0,1-.5.874.715.715,0,0,1-.874-.5Z" transform="translate(-279.087 -350.214)" fill="#f2f2f2" />
                    <path id="Path_442" data-name="Path 442" d="M324.424,707.261a10.952,10.952,0,0,0,9.542,4.927c4.832-.229,8.858-3.6,12.483-6.8l10.722-9.467-7.1-.34c-5.1-.244-10.338-.474-15.2,1.1s-9.344,5.361-10.232,10.393" transform="translate(-284.584 -365.153)" fill="#e6e6e6" />
                    <path id="Path_443" data-name="Path 443" d="M308.041,726.754c4.817-8.522,10.4-17.994,20.386-21.022a22.75,22.75,0,0,1,8.574-.885c.91.079.683,1.482-.226,1.4a21.134,21.134,0,0,0-13.684,3.62,35.567,35.567,0,0,0-9.405,10.149c-1.558,2.372-2.953,4.844-4.348,7.313C308.894,728.122,307.59,727.553,308.041,726.754Z" transform="translate(-278.231 -368.76)" fill="#f2f2f2" />
                    <path id="Path_141" data-name="Path 141" d="M766.8,410.668H501.244a3.09,3.09,0,0,1-3.086-3.086V160.971a3.09,3.09,0,0,1,3.086-3.087H766.8a3.091,3.091,0,0,1,3.086,3.087V407.58a3.09,3.09,0,0,1-3.087,3.087Z" transform="translate(-351.576 -157.884)" fill="#fff" />
                    <path id="Path_141-2" data-name="Path 141" d="M766.8,410.668H501.244a3.09,3.09,0,0,1-3.086-3.086V160.971a3.09,3.09,0,0,1,3.086-3.087H766.8a3.091,3.091,0,0,1,3.086,3.087V407.58a3.09,3.09,0,0,1-3.087,3.087ZM501.244,159.116a1.854,1.854,0,0,0-1.85,1.85V407.58a1.854,1.854,0,0,0,1.85,1.85H766.8a1.854,1.854,0,0,0,1.85-1.85V160.971a1.854,1.854,0,0,0-1.85-1.85Z" transform="translate(-351.576 -157.884)" fill="#cacaca" />
                    <path id="Path_142" data-name="Path 142" d="M706.095,262.185a2.1,2.1,0,1,0,0,4.191h114.98a2.1,2.1,0,0,0,0-4.191Z" transform="translate(-430.949 -198.103)" fill="#e4e4e4" />
                    <path id="Path_143" data-name="Path 143" d="M706.095,282.65a2.1,2.1,0,1,0,0,4.191h58.7a2.1,2.1,0,0,0,0-4.191Z" transform="translate(-430.949 -205.994)" fill="#e4e4e4" />
                    <path id="Path_142-2" data-name="Path 142" d="M542.526,392.705a2.1,2.1,0,0,0,0,4.191H758.114a2.1,2.1,0,0,0,0-4.191Z" transform="translate(-367.877 -248.431)" fill="#e4e4e4" />
                    <path id="Path_143-2" data-name="Path 143" d="M542.526,413.17a2.1,2.1,0,0,0,0,4.191H701.834a2.1,2.1,0,1,0,0-4.191Z" transform="translate(-367.877 -256.323)" fill="#e4e4e4" />
                    <path id="Path_142-3" data-name="Path 142" d="M542.526,433.172a2.1,2.1,0,0,0,0,4.191H758.114a2.1,2.1,0,1,0,0-4.191Z" transform="translate(-367.877 -264.036)" fill="#e4e4e4" />
                    <path id="Path_143-3" data-name="Path 143" d="M542.526,453.637a2.1,2.1,0,0,0,0,4.191H701.834a2.1,2.1,0,1,0,0-4.191Z" transform="translate(-367.877 -271.927)" fill="#e4e4e4" />
                    <path id="Path_142-4" data-name="Path 142" d="M542.526,474.172a2.1,2.1,0,0,0,0,4.191H758.114a2.1,2.1,0,0,0,0-4.191Z" transform="translate(-367.877 -279.845)" fill="#e4e4e4" />
                    <path id="Path_143-4" data-name="Path 143" d="M542.526,494.637a2.1,2.1,0,0,0,0,4.191H701.834a2.1,2.1,0,1,0,0-4.191Z" transform="translate(-367.877 -287.737)" fill="#e4e4e4" />
                    <path id="Path_411" data-name="Path 411" d="M580.524,287.039a30.105,30.105,0,1,1,30.1-30.105A30.105,30.105,0,0,1,580.524,287.039Z" transform="translate(-371.728 -184.469)" fill="#fdc600" />
                    <path id="Path_412" data-name="Path 412" d="M432.392,496.917a7.525,7.525,0,0,0-9.187-6.981l-9.951-14.024-10,3.969,14.331,19.607a7.566,7.566,0,0,0,14.8-2.571Z" transform="translate(-314.983 -280.516)" fill="#a0616a" />
                    <path id="Path_413" data-name="Path 413" d="M400.215,454.321,370.1,415.259,381.386,379.8c.827-8.915,6.405-11.4,6.642-11.505l.362-.155,9.817,26.181L391,413.544,408.693,443.3Z" transform="translate(-302.199 -238.959)" fill="#3f3d56" />
                    <path id="Path_414" data-name="Path 414" d="M577.093,312.379a7.525,7.525,0,0,0-6.25,9.7l-13.212,11.006,4.73,9.66,18.44-15.8a7.566,7.566,0,0,0-3.708-14.561Z" transform="translate(-374.51 -217.432)" fill="#a0616a" />
                    <path id="Path_415" data-name="Path 415" d="M536.71,339.252l-36.617,33.04-36.224-8.507c-8.952-.135-11.865-5.5-11.985-5.733l-.183-.349,25.343-11.812,19.722,5.7,28.3-19.94Z" transform="translate(-333.663 -224.889)" fill="#3f3d56" />
                    <path id="Path_416" data-name="Path 416" d="M227.247,544.62h9.838l4.68-37.948h-14.52Z" transform="translate(-87.626 -195.374)" fill="#a0616a" />
                    <path id="Path_417" data-name="Path 417" d="M0,0H30.92V11.946H8.675A8.675,8.675,0,0,1,0,3.272V0Z" transform="translate(168.431 358.382) rotate(179.997)" fill="#2f2e41" />
                    <path id="Path_418" data-name="Path 418" d="M163.246,544.62h9.838l4.68-37.948h-14.52Z" transform="translate(-62.948 -195.374)" fill="#a0616a" />
                    <path id="Path_419" data-name="Path 419" d="M0,0H30.92V11.946H8.675A8.675,8.675,0,0,1,0,3.272V0Z" transform="translate(129.109 358.382) rotate(179.997)" fill="#2f2e41" />
                    <path id="Path_420" data-name="Path 420" d="M157.552,342.991l.8,56.174.8,73.828,17.654-1.6,8.828-101.915,11.235,101.915H215.1l1.836-102.718-6.42-22.47Z" transform="translate(-60.752 -132.258)" fill="#2f2e41" />
                    <path id="Path_421" data-name="Path 421" d="M441.837,443.194c-19.209,0-36.891-8.693-37.13-8.812l-.2-.1L402.9,395.6c-.467-1.367-9.672-28.343-11.231-36.917-1.579-8.687,21.313-16.311,24.092-17.2l.631-6.988,25.653-2.764,3.251,8.942,9.2,3.451a4.552,4.552,0,0,1,2.821,5.348l-5.115,20.8L464.7,439.087l-2.69.116A48.833,48.833,0,0,1,441.837,443.194Z" transform="translate(-310.483 -224.917)" fill="#3f3d56" />
                    <circle id="Ellipse_52" data-name="Ellipse 52" cx="18.471" cy="18.471" r="18.471" transform="translate(94.67 76.564) rotate(-28.663)" fill="#a0616a" />
                    <path id="Path_422" data-name="Path 422" d="M424.46,297.109c3.52,3.75,10.057,1.737,10.516-3.386a6.188,6.188,0,0,0-.008-1.2c-.237-2.269-1.548-4.329-1.234-6.725a3.526,3.526,0,0,1,.645-1.651c2.805-3.756,9.39,1.68,12.037-1.72,1.623-2.085-.285-5.368.961-7.7,1.644-3.076,6.514-1.558,9.568-3.243,3.4-1.874,3.194-7.087.958-10.258-2.728-3.867-7.51-5.93-12.233-6.228s-9.413.979-13.822,2.7c-5.01,1.952-9.978,4.651-13.061,9.056-3.749,5.357-4.11,12.559-2.235,18.823C417.692,289.392,421.585,294.048,424.46,297.109Z" transform="translate(-319.683 -195.317)" fill="#2f2e41" />
                    <path id="Path_423" data-name="Path 423" d="M494.281,741.192H260.2a.614.614,0,0,1,0-1.229H494.281a.614.614,0,0,1,0,1.229Z" transform="translate(-259.581 -382.335)" fill="#cacaca" />
                    <path id="Path_424" data-name="Path 424" d="M589.01,280.276a2.143,2.143,0,0,1-1.463-.575l-9.927-9.22a2.15,2.15,0,0,1,2.927-3.151l8.408,7.81L605.591,258.5a2.15,2.15,0,1,1,3.041,3.041l-18.1,18.1A2.146,2.146,0,0,1,589.01,280.276Z" transform="translate(-381.953 -196.44)" fill="#fff" />
                </g>
            </svg>


            <Typography
                sx={{
                    fontSize: "20px",
                    fontWeight: "500"
                }}
            >
                Não há itens para mostrar
            </Typography>
        </Box>
    )

    const PendingDiscountCards = () => (
        <HourRegistryCard
            cardType={CardType.PendingDiscount}
            registry={pendingDiscounts[pageToView - 1]}
            isFetching={isFetching}
            approveRegistry={approveRegistry}
            repproveRegistry={repproveRegistry}
        />
    )

    const RepprovedDiscountCards = () => (
        <HourRegistryCard
            cardType={CardType.RepprovedDiscount}
            registry={repprovedDiscounts[pageToView - 1]}
            isFetching={isFetching}
        />
    )

    const ApprovedDiscountCards = () => (
        <HourRegistryCard
            cardType={CardType.ApprovedDiscount}
            registry={approvedDiscounts[pageToView - 1]}
            isFetching={isFetching}
        />
    )

    const DiscountCardSkeleton = () => (
        <HourRegistryCard
            cardType={CardType.ApprovedDiscount}
            registry={approvedDiscounts[pageToView - 1]}
            isFetching={true}
        />
    )

    const ShowCards = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px"
                }}
            >
                
                {sectionToShow === "Pending" && <PendingDiscountCards />}
                {sectionToShow === "Repproved" && <RepprovedDiscountCards />}
                {sectionToShow === "Approved" && <ApprovedDiscountCards />}
                <Pagination
                    count={totalPagesPerRegisterType}
                    page={pageToView}
                    onChange={(e, targetValue) => {
                        if (targetValue !== null) {
                            setIsChangingPage(true)
                            setPageToView(targetValue)
                            setTimeout(() => setIsChangingPage(false), 100)
                        }
                    }}
                />
            </Box>
        )
    }

    return (
        <Box>
            {!isFetching && totalPagesPerRegisterType === 0 ? <NothingToShow /> : <ShowCards />}
        </Box>
    )
}