import SectionTitle from "../../../components/SectionTitle/SectionTitle"
import TecnicoCardInfo from "../../../components/TecnicoCardInfo/TecnicoCardInfo"
import { tecnico } from "../../../domain/types"

type props = {
    tecnicoWithLessHours?: tecnico,
    tecnicoWithMoreHours?: tecnico
}

const CardTecsMoreAndLessHours = ({ tecnicoWithLessHours, tecnicoWithMoreHours }: props) => {
    if (tecnicoWithLessHours === tecnicoWithMoreHours) return <></>

    if (tecnicoWithMoreHours && tecnicoWithLessHours) {
        return (
            <>
                <SectionTitle title="Técnico com mais horas" />
                <TecnicoCardInfo
                    tecnico={tecnicoWithMoreHours}
                />

                <SectionTitle sx={{ marginTop: "20px" }} title="Técnico com menos horas" />
                <TecnicoCardInfo
                    tecnico={tecnicoWithLessHours}
                />
            </>
        )
    }
    else if (tecnicoWithLessHours) {
        return (
            <>
                <SectionTitle title="Técnico com menos horas" />
                <TecnicoCardInfo
                    tecnico={tecnicoWithLessHours}
                />
            </>
        )
    }
    else if (tecnicoWithMoreHours) {
        return (
            <>
                <SectionTitle title="Técnico com menos horas" />
                <TecnicoCardInfo
                    tecnico={tecnicoWithMoreHours}
                />
            </>
        )
    }
    else {
        return (<></>)
    }
}

export default CardTecsMoreAndLessHours