import { FolhaDePontoEntity } from "../../domain/entities/folhaDePonto";
import { HttpClient } from "../http/httpClient";
type props = {
	mes: number;
	ano: number;
};

type GetFolhaDePontoResponseDTO = {
	mesDeReferencia: number;
	funcionario: {
		id: number;
		podeGerarPdfFolhaPreenchida: boolean;
		horaInicioExpediente: string;
		horaFimExpediente: string;
		horaInicioAlmoco: string;
		horaFimAlmoco: string;
	};
	diasDoMes: {
		id: number
		dia: string;
		diaDaSemana: string;
		feriado: {
			id: number;
			diaDoFeriado: string;
			descricao: string;
		} | null;
		isFinalDeSemana: boolean;
		horaInicioExpediente: string | null;
		horaFimExpediente: string | null;
		horaInicioAlmoco: string | null;
		horaFimAlmoco: string | null;
		codAbono: number | null
	}[];
};

async function getFolhaDePonto({ mes, ano }: props): Promise<{
	statusCode: number;
	data: FolhaDePontoEntity | string;
}> {
	const httpResponse = await new HttpClient().request(
		{
			method: "get",
			url: `/FolhaDePonto/${mes}/${ano}`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("jwt") || ""}`,
			},
		},
		"PontoCerto"
	);

	if (httpResponse.statusCode === 200) {
		const response = httpResponse.body as GetFolhaDePontoResponseDTO;

		return {
			statusCode: 200,
			data: {
				mesDeReferencia: response.mesDeReferencia,
				diasDoMes: response.diasDoMes.map((i) => ({
					id: i.id,
					dia: new Date(i.dia),
					diaDaSemana: i.diaDaSemana,
					isFinalDeSemana: i.isFinalDeSemana,
					horaInicioAlmoco:
						i.horaInicioAlmoco === null
							? null
							: i.horaInicioAlmoco.substring(
									0,
									i.horaInicioAlmoco.lastIndexOf(":")
							  ),
					horaFimAlmoco:
						i.horaFimAlmoco === null
							? null
							: i.horaFimAlmoco.substring(0, i.horaFimAlmoco.lastIndexOf(":")),
					horaFimExpediente:
						i.horaFimExpediente === null
							? null
							: i.horaFimExpediente.substring(
									0,
									i.horaFimExpediente.lastIndexOf(":")
							  ),
					horaInicioExpediente:
						i.horaInicioExpediente === null
							? null
							: i.horaInicioExpediente.substring(
									0,
									i.horaInicioExpediente.lastIndexOf(":")
							  ),
					feriado:
						i.feriado === null
							? null
							: {
									id: i.feriado.id,
									diaDoFeriado: new Date(i.feriado.diaDoFeriado),
									descricao: i.feriado.descricao,
							  },
					codAbono: i.codAbono 
				})),
				funcionario: response.funcionario,
			},
		};
	}
	return {
		statusCode: httpResponse.statusCode,
		data: httpResponse.body[0] as string
	}
}

export default getFolhaDePonto;
