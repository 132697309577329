import { Dialog, DialogContent, DialogActions, Button, Box, Alert, AlertTitle } from "@mui/material"
import obterPdfFolhaDePontoPreenchida from "../../services/folhaDePonto/obterPdfFolhaDePontoPreenchida"
import { useEffect, useState } from "react";
import FileUpload from "../FileUpload/FileUpload";
import Loader from "../Loader/Loader";
import FileDownload from "../FileDownload/FileDownload";

type props = {
    folhaParaImprimir: {
        mes: number;
        ano: number;
    };
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalObterFolhaDePontoPreenchida = ({ folhaParaImprimir, setShow, show }: props) => {
    const [base64FolhaDePontoVazia, setBase64FolhaDePontoVazia] = useState<string>()
    const [base64FolhaDePontoPreenchida, setBase64FolhaDePontoPreenchida] = useState<string>()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState<string>()
   
    useEffect(() => {
        if (show) return
        setIsLoading(false)
        setBase64FolhaDePontoVazia(undefined)
        setBase64FolhaDePontoPreenchida(undefined)
        setErrorMsg(undefined)
    }, [show])

    return (
        <Dialog disableEscapeKeyDown open={show}>
            <DialogContent>
                {((!isLoading && !base64FolhaDePontoPreenchida) || errorMsg) && <FileUpload onFileUpload={(base64) => setBase64FolhaDePontoVazia(base64)} />}
                {!isLoading && base64FolhaDePontoPreenchida && <FileDownload base64={base64FolhaDePontoPreenchida} nomeDoArquivo={`${folhaParaImprimir.mes}/${folhaParaImprimir.ano} - Folha de ponto preenchida`} />}
                {isLoading && <Box>
                    <div>
                        <p>
                            Para receber a folha de ponto preenchida, anexe no campo abaixo o PDF da folha de ponto do mês e ano selecionado
                        </p>
                    </div>
                    <div
                        style={{
                            textAlign: "center",
                            padding: "15px",
                            margin: "15px 0",
                            borderRadius: "1em",
                            backgroundColor: "#F4F4F8",
                        }}
                    >
                        <Loader />
                    </div>
                </Box>}

                {errorMsg && (
                    <Alert variant="filled" severity="error">
                        <AlertTitle>Infelizmente a operação falhou</AlertTitle>
                        {errorMsg}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                {!base64FolhaDePontoPreenchida && <>
                    <Button
                        disabled={isLoading}
                        onClick={() => {
                            if (!isLoading) setShow(false)
                        }}>Cancelar</Button>
                    <Button
                        disabled={!base64FolhaDePontoVazia || isLoading}
                        onClick={() => {
                            if (!base64FolhaDePontoVazia) return
                            setIsLoading(true)
                            setErrorMsg(undefined)
                            obterPdfFolhaDePontoPreenchida({
                                ano: folhaParaImprimir.ano,
                                mes: folhaParaImprimir.mes,
                                base64DaFolha: base64FolhaDePontoVazia
                            })
                                .then(r => {
                                    if (r.statusCode === 200)
                                        setBase64FolhaDePontoPreenchida(r.body)
                                    else {
                                    setErrorMsg(r.body)
                                    }
                                })
                                .catch(e => {
                                    console.log(e)
                                    setErrorMsg("Não foi possível concluir a operação no momento. Por favor, tente novamente mais tarde")
                                }) 
                                .finally(() => setIsLoading(false))
                        }}
                    >
                        Enviar
                    </Button>
                </>
                }

                {base64FolhaDePontoPreenchida && <Button
                    onClick={() => {
                        setShow(false)
                    }}>Fechar</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default ModalObterFolhaDePontoPreenchida