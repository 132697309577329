import axios, { AxiosResponse } from "axios";
import { HttpRequest, HttpResponse, IHttpClient } from "./IHttpClient";

const { REACT_APP_API_BASE_URL, REACT_APP_PONTO_CERTO_API_BASE_URL } =
	process.env;
export class HttpClient implements IHttpClient {
	async request(
		data: HttpRequest,
		api?: "OClock" | "PontoCerto"
	): Promise<HttpResponse> {
		let axiosResponse: AxiosResponse;

		const url =
			(api === "OClock" || api === undefined)
				? REACT_APP_API_BASE_URL
				: REACT_APP_PONTO_CERTO_API_BASE_URL;

		try {
			axiosResponse = await axios.request({
				url: (url) + data.url,
				method: data.method,
				data: data.body,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt") || ""}`,
				},
				params: data.params,
				timeout: 900000,
				timeoutErrorMessage: "Isso está tomando mais tempo do que o esperado",
			});

			return {
				statusCode: axiosResponse.status,
				body: axiosResponse.data,
			};
		} catch (erro: any) {
			if (erro.response) {
				console.log(erro);
				if (erro.response.status === 400 && api === "OClock") {
					console.log(erro);
					return {
						statusCode: erro.status,
						body: "Certifique-se de que todos os campos estão devidamente preenchidos"
					};
				}
				if (erro.response.status === 500) {
					return {
						statusCode: 500,
						body: "Um erro inesperado ocorreu. Favor contatar o administrador do sistema."
					};
				}
				if (erro.response.status === 401) {
					return {
						statusCode: erro.response.status,
						body: "Usuário não autenticado",
					};
				}
				return {
					statusCode: erro.response.status,
					body: erro.response.data,
				};
			} else if (erro.request) {
				console.log("client never received a response, or request never left");
				return {
					statusCode: 408,
					body: "Não foi possível completar a sua requisição, favor tentar novamente. Entre em contato com a equipe técnica caso o problema persista."
				};
			} else {
				console.log("Erro desconhecido/não esperado", erro);
				return {
					statusCode: 500,
					body: "Ops... Algo não está certo! Favor entrar em contato com a equipe técnica para suporte."
					
				};
			}
		}
	}
}
