import { createTheme } from '@material-ui/core/styles';

const oclockTheme = createTheme({
  status: {
    danger: "#E65831",
  },
  palette: {
    primary: {
      main: "#0D8493",
      darker: "#053e85",
    },
    secondary: {
      main: "#FDC600",
      light: "#FFE900",
    },
    error: {
      main: "#E65831",
    },
    success: {
      main: "#25D611",
    },
    neutral: {
      main: "#D0CDE1",
      contrastText: "#F4F4F8",
    },
  },
});

export default oclockTheme;