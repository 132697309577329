import { Box, Button, Typography } from "@mui/material"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const FileDownload = ({ base64, nomeDoArquivo }: { base64: string, nomeDoArquivo: string}) => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = base64;
        link.download = nomeDoArquivo;
        link.click();
    };
    return (
        <Box>
            <div>
                <p>
                    Para receber a folha de ponto preenchida, anexe no campo abaixo o PDF da folha de ponto do mês e ano selecionado
                </p>
            </div>
            <div
                style={{
                    textAlign: "center",
                    padding: "15px",
                    margin: "15px 0",
                    borderRadius: "1em",
                    backgroundColor: "#F4F4F8",
                    cursor: "pointer"
                }}
            >
                <CheckCircleOutlineIcon sx={{ fontSize: "150px!important" }} />
                <Typography variant="body1">
                   Tudo certo! Folha de ponto preenchida e disponível para download
                </Typography>

                <Button onClick={handleDownload}>Baixar folha</Button>
            </div>
        </Box>
    )
}

export default FileDownload;