import { CircularProgress } from "@mui/material";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  AuthContextData,
  AuthenticatedUser,
  SignInCredentials,
} from "../domain/types";
import history from "../services/http/history";
import { login, validateToken } from "../services/userSession";
import { getEmailResetPassword } from "../services/userSession/getEmailResetPassword";
import { setNewPassword } from "../services/userSession/setNewPassword";

export const AuthContext = createContext({} as AuthContextData);

export default function AuthProvider({ children }: any) {
  const [user, setUser] = useState<AuthenticatedUser>();
  const [isCheckingAuthentication, setIsCheckingAuthentication] =
    useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    validateToken()
      .then((validationResult) => {
        if (validationResult.statusCode === 200) {
          const {
            userMatricula,
            userName,
            role,
            hasNegativeBalance,
            hourBalance,
            minutesBalance
          } = validationResult.body;
          setUser({
            userMatricula: validationResult.body.matricula,
            userName: validationResult.body.nomeUsuario,
            role,
            hasNegativeBalance,
            hourBalance,
            minutesBalance
          });
          setIsAuthenticated(true);
        }
        setIsCheckingAuthentication(false);
      })
      .catch((i) => {
        setIsAuthenticated(false);
      });
  }, []);

  async function signIn({ matricula, password }: SignInCredentials) {
    const result = await login({ matricula, password });

    if (result.statusCode === 200) {
      const {
        userName,
        userMatricula,
        role,
        accessToken,
        hasNegativeBalance,
        hourBalance,
        minutesBalance
      } = result.body;
      setUser({
        userMatricula: result.body.matricula,
        userName: result.body.nomeUsuario,
        role,
        hasNegativeBalance,
        hourBalance,
        minutesBalance
      });
      setIsAuthenticated(true);
      localStorage.setItem("jwt", accessToken);
    }

    if(result.statusCode !== 200){
      return {
        statusCode: result.statusCode,
        body: {
          message: result.body
        }
      }
    }
    return result;
  }

  async function sendEmailResetPassword(email: string){
    const result = await getEmailResetPassword(email);
    if (result.statusCode !== 200) {
      return {
        statusCode: result.statusCode,
        body: {
          message: result.body
        }
      }
    }
    return result
  }

  async function definirNovaSenha(novaSenha: string, token: string) {
    const result = await setNewPassword(novaSenha, token);
    if (result.statusCode !== 200) {
      return {
        statusCode: result.statusCode,
        body: {
          message: result.body
        }
      }
    }
    return result
  }

  function signOut() {
    localStorage.clear();
    setUser({} as AuthenticatedUser);
    setIsAuthenticated(false);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signIn,
        user,
        isCheckingAuthentication,
        signOut,
        sendEmailResetPassword,
        definirNovaSenha
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
