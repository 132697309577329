import { approvedHourDiscount, approvedHourRegistry } from "../../domain/types";
import { HttpClient } from "../http/httpClient";
import { HttpResponse } from "../http/IHttpClient";

type props = {
  TecnicoMatricula?: string;
};

async function getApprovedDiscounts({
  TecnicoMatricula = ""
}: props): Promise<Array<approvedHourDiscount>> {
  const httpClient = new HttpClient();
  const httpResponse = await httpClient.request({
    method: "get",
    url: `/HourDiscount/get-approved-discounts`,
    params: TecnicoMatricula !== "" && { matriculaDoTecnico: TecnicoMatricula },
  });

  const requestResult = httpResponse.body;

  return requestResult;
}

export default getApprovedDiscounts;
