import { tecnico } from '../../domain/types';
import { HttpClient } from '../http/httpClient';
import { HttpResponse } from '../http/IHttpClient';


async function getTecnicos(): Promise<Array<tecnico>> {

  const httpClient = new HttpClient();
  const httpResponse = await httpClient.request({
    method: "get",
    url: `/tecnico/get-tecnicos`,
  });
  
  return httpResponse.body as Array<tecnico>
}

export default getTecnicos;