import { ThemeProvider } from "@emotion/react";
import oclockTheme from "../../assets/theme";
import TemplateWithoutSidenav from "../../components/TemplateWithoutSidenav/TemplateWithoutSidenav";
import style from './HoraExtra.module.scss'
import { useHistory } from "react-router-dom";


import { useContext, useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Snackbar, Typography } from "@mui/material";

import useIsUserAdmin from "../../hooks/useIsUserAdmin";
import { approvedHourRegistry, pendingHourRegistry, repprovedHourRegistry, snackarDataType } from "../../domain/types";
import getPendingHours from "../../services/hourRegistries/getPendingHours";
import { AuthContext } from "../../context/authContext";
import getApprovedHours from "../../services/hourRegistries/getApprovedHours";
import getRepprovedHours from "../../services/hourRegistries/getRepprovedHours";
import PaginationOfRegistryCards from "../../components/PaginationOfRegistryCards/PaginationOfRegistryCards";
import approveRegistry from "../../services/administrador/approveRegistry";
import repproveRegistry from "../../services/administrador/repproveRegistry";
import TabsTypeOfRegistry from "../../components/TabsTypeOfRegistry/TabsTypeOfRegistry";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function HoraExtra() {
    const history = useHistory()
    const [snackbarData, setSnackbarData] = useState<snackarDataType | null>();

    const [pendingHours, setPendingHours] = useState<Array<pendingHourRegistry>>([]);
    const [approvedHours, setApprovedHours] = useState([{} as approvedHourRegistry]);
    const [repprovedHours, setRepprovedHours] = useState([{} as repprovedHourRegistry]);
    const [isFetching, setIsFetching] = useState(true);

    const { user } = useContext(AuthContext)

    const [sectionToShow, setSectionToShow] = useState<"Pending" | "Approved" | "Repproved">("Pending");

    const isUserAdmin = useIsUserAdmin()

    const getPendingHourOnChange = async () => {
        await getPendingHours(isUserAdmin ? { TecnicoMatricula: "" } : { TecnicoMatricula: user?.userMatricula })
            .then(res => setPendingHours(res))
            .catch(e => {
                console.log('Falha ao buscar horas aprovadas', e)
                setSnackbarData({ show: true, message: "Não foi possível carregar os dados no momento. Favor tentar novamente mais tarde." })
            })
            .finally(() => {
                setTimeout(() => {
                    setIsFetching(false)
                }, 100)
            })

    }

    const getRepprovedHourOnChange = async () => {
        await getRepprovedHours(isUserAdmin ? { TecnicoMatricula: "" } : { TecnicoMatricula: user?.userMatricula })
            .then(res => setRepprovedHours(res))
            .catch(e => {
                console.log('Falha ao buscar horas aprovadas', e)
                setSnackbarData({ show: true, message: "Não foi possível carregar os dados no momento. Favor tentar novamente mais tarde." })
            })            
            .finally(() => {
                setTimeout(() => {
                    setIsFetching(false)
                }, 100)
            })

    }

    const getApprovedHourOnChange = async () => {
        await getApprovedHours(isUserAdmin ? { TecnicoMatricula: "" } : { TecnicoMatricula: user?.userMatricula })
            .then(res => setApprovedHours(res))
            .catch(e => {
                console.log('Falha ao buscar horas aprovadas', e)
                setSnackbarData({ show: true, message: "Não foi possível carregar os dados no momento. Favor tentar novamente mais tarde." })
            })
            .finally(() => {
                setTimeout(() => {
                    setIsFetching(false)
                }, 100)
            })
    }

    async function getData() {
        await getPendingHourOnChange()
        await getRepprovedHourOnChange()
        await getApprovedHourOnChange()
    }

    function approve(reg: pendingHourRegistry): void {
        setIsFetching(true)
        if (user?.userMatricula === undefined) return
        approveRegistry(reg.id, user.userMatricula, "pendingHourRegistry").then((result) => {
            if (result.statusCode === 409) {
                setSnackbarData({ type: "warning", message: result.body.message, show: true })
            }
            else {
                setSnackbarData({ type: "success", message: result.body.message, show: true })
                getData()
            }
        })
            .catch(e => {
                setSnackbarData({ type: "error", message: "Falha ao aprovar esse registro. Tente novamente mais tarde", show: true })
                console.log(e)
            })
            .finally(() => {
                setIsFetching(false)
            })
    }

    function repprove(reg: pendingHourRegistry): void {
        setIsFetching(true)
        if (user?.userMatricula === undefined) return
        repproveRegistry(reg.id, user.userMatricula, "pendingHourRegistry").then((result) => {
            if (result.statusCode === 409) {
                setSnackbarData({ type: "warning", message: result.body.message, show: true })
            }
            else {
                setSnackbarData({ type: "success", message: result.body.message, show: true })
                getData()
            }
        })
            .catch(e => {
                setSnackbarData({ type: "error", message: "Falha ao reprovar esse registro. Tente novamente mais tarde", show: true })
                console.log(e)
            })
            .finally(() => {
                setIsFetching(false)
            })
    }

    useEffect(() => {
        document.title = "O'Clock - Registros de hora extra"
        getPendingHourOnChange()
    }, [])

    useEffect(() => {
        setIsFetching(true)
    }, [sectionToShow])

    return (
        <ThemeProvider theme={oclockTheme}>
            <Snackbar
                open={snackbarData?.show}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
            >
                <Alert
                    variant="filled"
                    severity={snackbarData?.type}
                    onClose={() => {
                        setSnackbarData({ ...snackbarData, show: false });
                    }}
                >
                    <AlertTitle>{snackbarData?.message}</AlertTitle>
                </Alert>
            </Snackbar>

            <TemplateWithoutSidenav />
            {isUserAdmin && <PageTitle title={"Horas extras"} />}

            <div className={style.container}>

                {
                    !isUserAdmin && (
                        <div className={style.left}>

                            <div className={style.description}>
                                <p className={style.header}>Aqui é onde vêm parar seus cadastros de horas extras!</p>
                                <Typography
                                    component="span"
                                    sx={{
                                        fontSize: "22px"
                                    }}
                                >
                                    Para cadastrar novas horas acesse o&nbsp;
                                    <Typography
                                        component="a"
                                        sx={{
                                            color: "secondary.main",
                                            fontSize: "22px",
                                            fontWeight: 500,
                                            ":hover": {
                                                cursor: "pointer",
                                                textDecoration: "underline"
                                            }
                                        }}
                                        onClick={() => { history.push("/cadastrar-hora") }}
                                    >
                                        ambiente de cadastro de hora extra
                                    </Typography>
                                </Typography>
                            </div>
                            <div className={style.imgArea}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="302.682" height="283.094" viewBox="0 0 302.682 283.094">
                                    <g id="undraw_Time_management_re_tk5w" transform="translate(0 -0.004)">
                                        <path id="Path_459" data-name="Path 459" d="M841.346,596.4l13.579,2.122a44.343,44.343,0,0,0,9.23-19.235c-9.929,8.128-25.028,3.809-37.067,8.246a26.672,26.672,0,0,0-15.957,16.729l-6.636,7.139a44.7,44.7,0,0,0,37.366-2.057,43.171,43.171,0,0,0,8.8-6.316C845.907,600.031,841.346,596.4,841.346,596.4Z" transform="translate(-561.545 -379.817)" fill="#f2f2f2" />
                                        <path id="Path_460" data-name="Path 460" d="M843.595,537.6l13.1-4.156a44.341,44.341,0,0,0-.317-21.333c-5.262,11.7-20.7,14.571-29.5,23.912a26.672,26.672,0,0,0-6.821,22.09l-2.755,9.35a44.694,44.694,0,0,0,32.527-18.506,43.174,43.174,0,0,0,5.058-9.577C849.3,538.817,843.595,537.6,843.595,537.6Z" transform="translate(-568.615 -342.735)" fill="#f2f2f2" />
                                        <path id="Path_461" data-name="Path 461" d="M564.847,659.968a.531.531,0,0,1-.533.533H262.7a.533.533,0,1,1,0-1.066H564.314a.531.531,0,0,1,.533.533Z" transform="translate(-262.165 -424.064)" fill="#ccc" />
                                        <circle id="Ellipse_62" data-name="Ellipse 62" cx="96.314" cy="96.314" r="96.314" transform="translate(28.669 20.549)" fill="#e6e6e6" />
                                        <path id="Path_462" data-name="Path 462" d="M521.913,292.767a82.76,82.76,0,0,1-33.45,66.528c-.694.52-1.4,1.026-2.114,1.519a82.889,82.889,0,0,1-94.621,0c-.712-.493-1.42-1-2.114-1.519a82.884,82.884,0,0,1,0-133.057c.694-.52,1.4-1.026,2.114-1.519a82.889,82.889,0,0,1,94.621,0c.712.493,1.42,1,2.114,1.519A82.761,82.761,0,0,1,521.913,292.767Z" transform="translate(-314.055 -175.904)" fill="#fff" />
                                        <circle id="Ellipse_63" data-name="Ellipse 63" cx="75.707" cy="75.707" r="75.707" transform="translate(49.276 41.156)" fill="#fdc600" />
                                        <path id="Path_463" data-name="Path 463" d="M533.435,226.365c-1.148,0-2.288.03-3.424.081a75.705,75.705,0,0,1-3.206,151.042c2.185.19,4.4.292,6.629.292a75.707,75.707,0,1,0,0-151.415Z" transform="translate(-408.254 -184.998)" opacity="0.2" />
                                        <path id="Path_464" data-name="Path 464" d="M538.627,213.892a2.468,2.468,0,0,0-2.464,2.464v10.751a2.464,2.464,0,0,0,4.928,0V216.356A2.468,2.468,0,0,0,538.627,213.892Z" transform="translate(-413.419 -178.112)" fill="#fff" />
                                        <path id="Path_465" data-name="Path 465" d="M638.2,244.5c-.694-.52-1.4-1.026-2.114-1.519a2.462,2.462,0,0,0-2.2,1.071l-6.052,8.883a2.463,2.463,0,0,0,4.072,2.773l6.052-8.883A2.448,2.448,0,0,0,638.2,244.5Z" transform="translate(-463.789 -194.17)" fill="#fff" />
                                        <path id="Path_466" data-name="Path 466" d="M694.75,334.91a2.461,2.461,0,0,0-3.208-1.353l-9.963,4.045a2.464,2.464,0,0,0,1.855,4.565l9.963-4.045A2.47,2.47,0,0,0,694.75,334.91Z" transform="translate(-492.844 -244.07)" fill="#fff" />
                                        <path id="Path_467" data-name="Path 467" d="M440.961,252.94l-6.052-8.883a2.462,2.462,0,0,0-2.2-1.071c-.712.493-1.42,1-2.114,1.519a2.448,2.448,0,0,0,.237,2.325l6.052,8.883a2.463,2.463,0,0,0,4.072-2.773Z" transform="translate(-355.041 -194.17)" fill="#fff" />
                                        <path id="Path_468" data-name="Path 468" d="M382.4,337.6l-9.963-4.045a2.464,2.464,0,1,0-1.855,4.565l9.963,4.045A2.464,2.464,0,1,0,382.4,337.6Z" transform="translate(-321.164 -244.071)" fill="#fff" />
                                        <path id="Path_469" data-name="Path 469" d="M538.627,540.892a2.468,2.468,0,0,0-2.464,2.464v10.751a2.464,2.464,0,0,0,4.928,0V543.356A2.468,2.468,0,0,0,538.627,540.892Z" transform="translate(-413.419 -358.625)" fill="#fff" />
                                        <path id="Path_470" data-name="Path 470" d="M637.96,525.938l-6.052-8.883a2.463,2.463,0,0,0-4.072,2.773l6.052,8.883a2.463,2.463,0,0,0,2.2,1.071c.712-.493,1.42-1,2.114-1.519A2.448,2.448,0,0,0,637.96,525.938Z" transform="translate(-463.789 -344.872)" fill="#fff" />
                                        <path id="Path_471" data-name="Path 471" d="M693.4,440.6l-9.963-4.045a2.464,2.464,0,0,0-1.855,4.565l9.963,4.045A2.464,2.464,0,0,0,693.4,440.6Z" transform="translate(-492.844 -300.929)" fill="#fff" />
                                        <path id="Path_472" data-name="Path 472" d="M440.312,516.406a2.464,2.464,0,0,0-3.423.65l-6.052,8.883a2.448,2.448,0,0,0-.237,2.325c.694.52,1.4,1.026,2.114,1.519a2.462,2.462,0,0,0,2.2-1.071l6.052-8.883a2.463,2.463,0,0,0-.65-3.422Z" transform="translate(-355.041 -344.872)" fill="#fff" />
                                        <path id="Path_473" data-name="Path 473" d="M383.749,437.91a2.461,2.461,0,0,0-3.207-1.353l-9.963,4.045a2.464,2.464,0,0,0,1.855,4.565l9.963-4.045A2.469,2.469,0,0,0,383.749,437.91Z" transform="translate(-321.164 -300.929)" fill="#fff" />
                                        <path id="Path_474" data-name="Path 474" d="M482.314,386.879a5.158,5.158,0,0,1-4.215,5.943l-43.721,7.437A5.152,5.152,0,0,1,432.65,390.1l43.721-7.437A5.158,5.158,0,0,1,482.314,386.879Z" transform="translate(-353.91 -271.239)" fill="#3f3d56" />
                                        <path id="Path_475" data-name="Path 475" d="M586.441,435.7a3.363,3.363,0,0,1-4.751.019l-47.065-46.7a3.36,3.36,0,0,1,4.733-4.77l47.065,46.7a3.364,3.364,0,0,1,.019,4.751Z" transform="translate(-412.022 -271.615)" fill="#3f3d56" />
                                        <circle id="Ellipse_64" data-name="Ellipse 64" cx="5.824" cy="5.824" r="5.824" transform="translate(119.16 111.04)" fill="#ccc" />
                                        <path id="Path_476" data-name="Path 476" d="M390.62,149.907c-8.939,14.054-22.579,23.381-39.4,29.508a3.162,3.162,0,0,1-4.427-.635h0c-9.137-12.2-7.074-29.635,4.985-38.957A27.805,27.805,0,0,1,391,145.143l.253.337A3.162,3.162,0,0,1,390.62,149.907Z" transform="translate(-305.75 -134.027)" fill="#e6e6e6" />
                                        <path id="Path_477" data-name="Path 477" d="M628.763,145.143a27.805,27.805,0,0,1,39.224-5.32c12.059,9.322,14.122,26.757,4.985,38.957h0a3.162,3.162,0,0,1-4.427.635c-15.615-5.978-28.42-16.324-39.4-29.508a3.162,3.162,0,0,1-.635-4.427Z" transform="translate(-464.049 -134.027)" fill="#e6e6e6" />
                                        <path id="Path_478" data-name="Path 478" d="M619.554,580.776a2.468,2.468,0,0,0-.947,3.353l14.219,25.41a2.464,2.464,0,0,0,4.3-2.406l-14.22-25.41a2.468,2.468,0,0,0-3.353-.947Z" transform="translate(-458.758 -380.469)" fill="#e6e6e6" />
                                        <path id="Path_479" data-name="Path 479" d="M435.824,581.72l-14.22,25.41a2.464,2.464,0,1,0,4.3,2.406l14.22-25.41a2.464,2.464,0,1,0-4.3-2.406Z" transform="translate(-350.007 -380.467)" fill="#e6e6e6" />
                                        <circle id="Ellipse_65" data-name="Ellipse 65" cx="7.616" cy="7.616" r="7.616" transform="translate(169.333 220.345)" fill="#e6e6e6" />
                                        <circle id="Ellipse_66" data-name="Ellipse 66" cx="7.616" cy="7.616" r="7.616" transform="translate(174.709 25.477)" fill="#e6e6e6" />
                                        <circle id="Ellipse_67" data-name="Ellipse 67" cx="7.616" cy="7.616" r="7.616" transform="translate(116.92 7.558)" fill="#e6e6e6" />
                                        <circle id="Ellipse_68" data-name="Ellipse 68" cx="7.616" cy="7.616" r="7.616" transform="translate(61.819 24.581)" fill="#e6e6e6" />
                                        <circle id="Ellipse_69" data-name="Ellipse 69" cx="7.616" cy="7.616" r="7.616" transform="translate(66.747 220.345)" fill="#e6e6e6" />
                                        <path id="Path_480" data-name="Path 480" d="M643.068,483.437a3.815,3.815,0,0,0,5.8.751l11.95,6.4-.641-7.016-11.25-5a3.836,3.836,0,0,0-5.861,4.866Z" transform="translate(-472.093 -323.64)" fill="#ffb7b7" />
                                        <path id="Path_481" data-name="Path 481" d="M663.264,488.233l4.683-6.464,22.56,10.665,28.073-5.267a9.619,9.619,0,0,1,11.392,9.436h0a9.629,9.629,0,0,1-9.6,9.636l-32.172.06Z" transform="translate(-483.582 -325.988)" fill="#3f3d56" />
                                        <path id="Path_482" data-name="Path 482" d="M379.16,584.2l-4.738-2.266,6.509-19.8,6.992,3.345Z" transform="translate(-206.691 -310.31)" fill="#ffb7b7" />
                                        <path id="Path_483" data-name="Path 483" d="M703.12,715.265c-.344.216-.689.428-1.037.64-.693.425-1.392.842-2.091,1.253-.352.205-.707.411-1.058.612v.01l-.021,0-.007,0-.536.132-.769-2.032-.355-.936-.5-1.326-.919-2.426-4.837-12.772,7.521-1.855.282,1.152h0l.139.571,2.628,10.656.536,2.179.017.066.324,1.3.278,1.128.31,1.26Z" transform="translate(-498.887 -444.565)" fill="#ffb7b7" />
                                        <path id="Path_484" data-name="Path 484" d="M741.089,610.024c-.477.766-.964,1.528-1.455,2.283q-1.368,2.109-2.809,4.155s0,0-.007.007a9.819,9.819,0,0,1-1.845,1.347c-.028.021-.059.035-.087.052a6.8,6.8,0,0,1-1.552.623l-.588.146c-.7.167-1.4.31-2.092.435-1.232.219-2.45.376-3.661.473-.1.01-.195.017-.292.024a56.619,56.619,0,0,1-14.888-1.187c-2.5-.473-5.05-1.041-7.691-1.629-.863-.195-1.73-.386-2.607-.578-.066-.014-.136-.031-.205-.045-1.253-.275-2.527-.546-3.832-.814-1.643-.338-3.338-.665-5.1-.974l.125,2.481a18.676,18.676,0,0,0,1.176,5.579,9.628,9.628,0,0,1,.091,7.562l-.606,1.754a5.99,5.99,0,0,1-.616,5.3l-.122.184,5.05,12.776.327.828H695.22l-3.181,0h-5.391l-7.778-15.605-.372-.745v-.038l.8-29.989c.01-.265.031-.533.066-.8a9.118,9.118,0,0,1,3.47-6.139,8.78,8.78,0,0,1,6.539-1.789l2.662.345,5.44.7,3.832.5.077.01,11.3,1.462.811-3.306.15-.056,4.757-1.744,16.454-6.031.042-.014,1.082-.4.129.209c.038.063.077.125.111.188a32.252,32.252,0,0,1,2.6,6.035,94.692,94.692,0,0,1,2.732,10.43q.047.23.084.418l.01.038c.01.059.017.1.021.108A9.731,9.731,0,0,1,741.089,610.024Z" transform="translate(-491.992 -384.177)" fill="#2f2e41" />
                                        <path id="Path_485" data-name="Path 485" d="M723.341,593.184c-.052-.223-.118-.449-.188-.668a47.64,47.64,0,0,0-1.928-4.879c-.821-1.81-1.806-3.748-2.979-5.847q-.851-1.524-1.831-3.17-.491-.825-1.02-1.681c-.014-.026-.029-.052-.045-.076l-.1-.167-16.28,2.179-6.062.811-.362,3.021-.087.748s0,0,0,0l-.244,2.039v0l-.324,2.694-.028,0-9-1.166-9.271-1.2-4.98-.644a8.775,8.775,0,0,0-6.539,1.789,9.122,9.122,0,0,0-3.47,6.139c-.031.244-.052.494-.063.741l-4.7,11.133a71.828,71.828,0,0,0-5.311,20.9l-3.445,11.015.491.045,7.3.679,1.928.181,4.246-6.2c.484-.706.943-1.434,1.371-2.179a33.1,33.1,0,0,0,1.876-3.772l9.184-21.856q1.065.183,2.109.348,4.829.783,9.271,1.277a109.247,109.247,0,0,0,25.391.247q3.127-.381,6.045-1,1.07-.225,2.112-.484c.164-.038.324-.077.484-.118a8.881,8.881,0,0,0,6.212-5.8A9.062,9.062,0,0,0,723.341,593.184Z" transform="translate(-473.549 -378.389)" fill="#2f2e41" />
                                        <path id="Path_486" data-name="Path 486" d="M628.545,734.7l-5.126-2.457-.8-.386-1.284,3.153-8.965,1.973a2.127,2.127,0,0,0-.348,4.047l8.76,3.574,2.781-2.5-.682,3.358,3.3,1.347L629.79,735.3Z" transform="translate(-454.562 -464.044)" fill="#2f2e41" />
                                        <path id="Path_487" data-name="Path 487" d="M458.7,593.34l.327.828H458.7Z" transform="translate(-253.216 -327.537)" fill="#2f2e41" />
                                        <path id="Path_488" data-name="Path 488" d="M704.274,729.055l-5.372,1.861-.843.291,1.306,3.144-4.982,7.71a2.127,2.127,0,0,0,2.6,3.121l8.739-3.624.215-3.734,1.878,2.866,3.292-1.364L705.581,728.6Z" transform="translate(-500.573 -462.247)" fill="#2f2e41" />
                                        <path id="Path_489" data-name="Path 489" d="M779.557,544.512a1.974,1.974,0,0,1-.915-.227L750.3,529.474l-.327-1.341-.617-4.243.009-.04,2.7-12.428c-.049-.107-.153-.282-.269-.479-1.2-2.039-4.866-8.245,2.124-16.79l12.273-14.308,10.533-.453.12.151a27.434,27.434,0,0,1,4.664,25.484l-1.1,19.808,1.176,4a11.037,11.037,0,0,1-.323,7.143l.27,6.477a1.979,1.979,0,0,1-1.969,2.057Z" transform="translate(-531.105 -324.675)" fill="#3f3d56" />
                                        <path id="Path_490" data-name="Path 490" d="M754.735,639.67a3.815,3.815,0,0,0,.953-5.772l6.815-11.72-7.034.4-5.393,11.069a3.836,3.836,0,0,0,4.66,6.027Z" transform="translate(-530.871 -403.497)" fill="#ffb7b7" />
                                        <path id="Path_491" data-name="Path 491" d="M760.271,560.189l-6.3-4.9,11.443-22.175-4.288-28.24a9.619,9.619,0,0,1,9.826-11.057h0a9.629,9.629,0,0,1,9.3,9.93l-1.06,32.154Z" transform="translate(-533.657 -332.633)" fill="#3f3d56" />
                                        <path id="a1ca5f83-7fa1-45b1-9d3b-bf096f25c0e5" d="M771.842,437.065V426.642a13.966,13.966,0,0,1,27.933-.018v.018c2.462,5.13.922,7.568,0,10.423a1.878,1.878,0,0,1-1.875,1.875H773.718A1.878,1.878,0,0,1,771.842,437.065Z" transform="translate(-543.521 -287.841)" fill="#2f2e41" />
                                        <circle id="Ellipse_70" data-name="Ellipse 70" cx="10.454" cy="10.454" r="10.454" transform="translate(227.254 129.513)" fill="#ffb7b7" />
                                        <path id="Path_492" data-name="Path 492" d="M787.975,433.146c-.095-.015-.19-.035-.284-.05-1.631-.289-3.261-.573-4.887-.868l-.15-3.685-1.97,3.3c-4.493,1.491-8.7.648-12.652-1.212a35.721,35.721,0,0,1-3.79-2.089,13.855,13.855,0,0,1,6.807-9.8c.15-.085.294-.159.444-.234h0a.005.005,0,0,0,0,0c.254-.12.514-.234.773-.339a11.516,11.516,0,0,1,12.088,2.344A13.757,13.757,0,0,1,787.975,433.146Z" transform="translate(-539.325 -290.437)" fill="#2f2e41" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    )
                }

                <div className={style.right}>
                    {!isUserAdmin && <PageTitle title={"Horas extras"} />}
                    <div className={style.form} >
                        <fieldset className={style.fieldset}>
                            <TabsTypeOfRegistry
                                sectionToShow={sectionToShow}
                                onApprovedSelect={() => {
                                    setSectionToShow("Approved")
                                    getApprovedHourOnChange()
                                }}
                                onRepprovedSelect={() => {
                                    setSectionToShow("Repproved")
                                    getRepprovedHourOnChange()
                                }}
                                onPendingSelect={() => {
                                    setSectionToShow("Pending")
                                    getPendingHourOnChange()
                                }}
                            />
                            <Box
                                marginTop="5%"
                            >
                                <PaginationOfRegistryCards
                                    approvedHours={approvedHours}
                                    repprovedHours={repprovedHours}
                                    pendingHours={pendingHours}
                                    sectionToShow={sectionToShow}
                                    approveRegistry={approve}
                                    repproveRegistry={repprove}
                                    isFetching={isFetching}
                                />
                            </Box>
                        </fieldset>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}