import { Avatar } from "@mui/material";

type props = {
    name: string
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
    if (color === "#53f72b") color = "#462384"
    return color;
}

export default function CustomAvatar({name}: props){
    return (
        <Avatar
            sx={{
                backgroundColor: stringToColor(name),
                fontSize: "160%",
                width: "50px",
                height: "50px"
            }}
        >
            {name.toLocaleUpperCase().split("")[0]}
        </Avatar>
    )
}