import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { TimeField, TimePicker } from '@mui/x-date-pickers';
import { useState, useEffect } from 'react';
import { DefinicoesDeExpedienteDeFuncionario } from '../../../domain/entities/definicoesDeExpedienteDeFuncionario';
import buscarDefinicoesDeExpedienteDeFuncionario from '../../../services/folhaDePonto/buscarDefinicoesDeExpedienteDeFuncionario';
import Button from '../../../components/Button/Button';

const ConfiguraExpedienteFuncionario = () => {
    const [isFetching, setIsFetching] = useState(true)
    const [data, setData] = useState<{ statusCode: number, data: DefinicoesDeExpedienteDeFuncionario | string }>()

    useEffect(() => {
        const getData = async () => {
            buscarDefinicoesDeExpedienteDeFuncionario().then(result => {
                if (result.statusCode === 200) {
                    setData({ data: result.data as DefinicoesDeExpedienteDeFuncionario, statusCode: 200 })
                }
                setData({ data: result.data as DefinicoesDeExpedienteDeFuncionario, statusCode: result.statusCode })


            }).catch(e => console.log("caiu no catch", e))
                .finally(() => setIsFetching(false))
        }
        getData()
    }, [])

    return (
        <div>
            {data && data?.statusCode === 200 && (
                <>
                    <Typography variant="h6" sx={{ color: 'primary.main' }}>Dados do funcionário</Typography>
                    <Grid spacing={4} container>
                        <Grid item xs={12} sm={6} md={2.5}>
                            <p>Início do expediente</p>
                            <Typography variant='body1'>
                                {isFetching ? <Skeleton /> : (data?.data as DefinicoesDeExpedienteDeFuncionario).horaInicioExpediente?.toLocaleTimeString("pt-BR", {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                })}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={2.5}>
                            <p>Início do almoço</p>
                            {isFetching ? <Skeleton /> : (data?.data as DefinicoesDeExpedienteDeFuncionario).horaInicioAlmoco?.toLocaleTimeString("pt-BR", {
                                hour: "2-digit",
                                minute: "2-digit"
                            })}
                        </Grid>

                        <Grid item xs={12} sm={6} md={2.4}>
                            <p>Fim do almoço</p>
                            {isFetching ? <Skeleton /> : (data?.data as DefinicoesDeExpedienteDeFuncionario).horaFimAlmoco?.toLocaleTimeString("pt-BR", {
                                hour: "2-digit",
                                minute: "2-digit"
                            })}
                        </Grid>

                        <Grid item xs={12} sm={6} md={2.5}>
                            <p>Fim do expediente</p>
                            {isFetching ? <Skeleton /> : (data?.data as DefinicoesDeExpedienteDeFuncionario).horaFimExpediente?.toLocaleTimeString("pt-BR", {
                                hour: "2-digit",
                                minute: "2-digit"
                            })}
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    )
}

export default ConfiguraExpedienteFuncionario