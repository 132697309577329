import {
    Box,
    Container,
    ThemeProvider
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import oclockLogo from "../../assets/Group3.png";
import oclockTheme from "../../assets/theme";
import { AuthContext } from "../../context/authContext";

import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import DefinirNovaSenhaForm from "./components/DefirnirNovaSenhaForm";
import GerarTokenForm from "./components/GerarTokenForm";

export default function RedefinirSenha() {
    const { isAuthenticated } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation()
    const [tokenRedefinicaoSenha, setTokenRedefinicaoSenha] = useState<string>()
    

    useEffect(() => {
        document.title = "O'Clock - Redefinir senha"
        if(isAuthenticated) history.push("/")
    }, [isAuthenticated, history.location.state])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get('token');
        history.push({
            pathname: location.pathname,
            search: ''
        });

        if (paramValue) setTokenRedefinicaoSenha(paramValue)
    }, [])

    return (
        <ThemeProvider theme={oclockTheme}>
            <Container
                fixed
                sx={{
                    backgroundColor: "primary.main",
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        textAlign: "center",
                        color: "white",
                    }}
                >
                    <header>
                        <img src={oclockLogo} alt="O'Clock logo" />
                        <p>Sistema para gerenciamento</p>
                        <p>de horas</p>
                    </header>

                    <h3
                        style={{
                            color: "white",
                            fontWeight: 200,
                            margin: "20px 0 0 0",
                        }}
                    >
                        Redefinir senha
                    </h3>
                    {!tokenRedefinicaoSenha && <GerarTokenForm />}

                    {tokenRedefinicaoSenha && <DefinirNovaSenhaForm token={tokenRedefinicaoSenha}/>}
                </Box>
            </Container>
        </ThemeProvider>
    );
}
