import { Typography } from "@mui/material";
import { ThemeProvider } from "@mui/private-theming";
import oclockTheme from "../../assets/theme";

type Props = {
    title: string,
    sx?: object
}

export default function PageTitle({ title, sx }: Props) {
    return (
        <ThemeProvider theme={oclockTheme}>
            <Typography
                sx={{
                    color: '#0D8493',
                    fontSize: '26px',
                    fontWeight: 500,
                    ...sx
                }}
            >
                {title}
            </Typography>
        </ThemeProvider>
    )
}