import { HttpClient } from "../http/httpClient";
import { HttpResponse } from "../http/IHttpClient";

async function registerTecnico(
  name: string,
  matricula: string,
  adminRegisterMatricula: string
): Promise<HttpResponse> {
  const httpClient = new HttpClient();

  const httpResponse = await httpClient.request({
    method: "post",
    url: `/administrator/register-tecnico`,
    body: {
      name,
      matricula,
      adminRegisterMatricula,
    },
  });

  return httpResponse;
}

export default registerTecnico;
