import { Alert, AlertTitle, Box, CircularProgress, Snackbar } from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import { AuthContext } from "../../../context/authContext";
import { snackarDataType } from "../../../domain/types";

const DefinirNovaSenhaForm = ({ token }: { token: string }) => {
    const { definirNovaSenha } = useContext(AuthContext);
    const [isFetching, setIsFetching] = useState(false);
    const [snackbarData, setSnackbarData] = useState<snackarDataType>();
    const [novaSenha, setNovaSenha] = useState<string>()
    const history = useHistory()
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!novaSenha) return
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setIsFetching(true);

        await definirNovaSenha(novaSenha, token)
            .then((result) => {
                setTimeout(() => {
                    if (result.statusCode !== 200) {
                        setSnackbarData({
                            show: true,
                            type: "error",
                            message: result.body.message,
                        });
                    }
                    else {
                        setNovaSenha("")
                        setSnackbarData({
                            show: true,
                            type: "info",
                            message: "Senha redefinida com sucesso. Aguarde enquanto te redirecionamos para a tela de login"
                        });
                        setTimeout(() => { history.push("/login") }, 5000)
                    }
                    setIsFetching(false)
                }, 1000)
            })
            .catch(erro => {
                setIsFetching(false)
                setSnackbarData({
                    show: true,
                    type: "error",
                    message: "Um erro inesperado ocorreu. Favor entrar em contato com o administrador do sistema",
                });
            })
    };
    return (
        <>
            <Snackbar
                open={snackbarData?.show}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
            >
                <Alert
                    variant="filled"
                    severity={snackbarData?.type}
                    onClose={() => {
                        setSnackbarData({ ...snackbarData, show: false });
                    }}
                >
                    <AlertTitle>{snackbarData?.message}</AlertTitle>
                </Alert>
            </Snackbar>
            <p style={{ fontSize: ".9em" }}>Digite sua nova senha no campo abaixo</p>

            <section>
                <form
                    style={{
                        textAlign: "center",
                    }}
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <div style={{ margin: "20px 0" }}>
                        <Input
                            id="matricula"
                            type="password"
                            value={novaSenha}
                            onChange={(e) => setNovaSenha(e.target.value)}
                            required
                            width="400px"
                        />
                    </div>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        {isFetching ? <CircularProgress color="inherit" /> :
                            <Button type="submit">Redefinir</Button>
                        }
                    </Box>
                </form>
            </section>
        </>
    )
}

export default DefinirNovaSenhaForm