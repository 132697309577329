import { Typography } from "@mui/material";
import { useHistory } from "react-router";
import { pendingHourDiscount, pendingHourRegistry } from "../../domain/types";
import SectionTitle from "../SectionTitle/SectionTitle";

type props = {
    pendingHours: Array<pendingHourRegistry> | undefined;
    pendingDiscounts: Array<pendingHourDiscount> | undefined;
};

export default function RegistrosPendentesHomeSection({
    pendingHours,
    pendingDiscounts,
}: props) {

    const history = useHistory()

    return (
        <>
            {((pendingDiscounts && pendingDiscounts?.length > 0) || (pendingHours && pendingHours.length > 0)) && <SectionTitle title="Cadastros pendentes" />}
            {
                pendingHours && pendingHours.length > 0 &&
                <>
                    <Typography
                        sx={{
                            color: "#0D8493",
                            fontSize: "20px",
                        }}
                    >
                        Horas extras
                    </Typography>
                    <Typography
                        sx={{
                            color: "error.main",
                            ":hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={() => {
                            history.push("/hora-extra");
                        }}
                    >
                        {pendingHours.length} registro(s) em pendência
                    </Typography>
                </>
            }


            {
                pendingDiscounts && pendingDiscounts.length > 0 &&
                <>
                    <Typography
                        sx={{
                            color: "#0D8493",
                            fontSize: "20px",
                        }}
                    >
                        Desconto de horas extras
                    </Typography>
                    <Typography
                        sx={{
                            color: "error.main",
                            ":hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={() => {
                            history.push("/desconto-de-hora");
                        }}
                    >
                        {pendingDiscounts.length} registro(s) em pendência
                    </Typography>
                </>
            }
        </>
    );
}
