import { DefinicoesDeExpedienteDeFuncionario } from "../../domain/entities/definicoesDeExpedienteDeFuncionario";
import createDateFromTime from "../../helpers/createDateFromTime";
import { HttpClient } from "../http/httpClient";

type GetDefinicoesDeExpedienteDeFuncionarioDTO = {
	id: number;
	horaInicioExpediente: string
	horaFimExpediente: string
	horaInicioAlmoco: string
	horaFimAlmoco: string
	periodosPermitidosGerarFolha: [string];
};

async function buscarDefinicoesDeExpedienteDeFuncionario(): Promise<{
	statusCode: number;
	data: DefinicoesDeExpedienteDeFuncionario | string;
}> {
	const httpClient = new HttpClient();
	const httpResponse = await httpClient.request(
		{
			method: "get",
			url: `/Funcionario`,
		},
		"PontoCerto"
	);

	if (httpResponse.statusCode === 200) {
		const response =
			httpResponse.body as GetDefinicoesDeExpedienteDeFuncionarioDTO;

		return {
			data: {
				idFuncionario: response.id,
				horaFimAlmoco:
					response.horaFimAlmoco === null
						? null
						: createDateFromTime(response.horaFimAlmoco),
				horaFimExpediente:
					response.horaFimExpediente === null
						? null
						: createDateFromTime(response.horaFimExpediente),
				horaInicioAlmoco:
					response.horaInicioAlmoco === null
						? null
						: createDateFromTime(response.horaInicioAlmoco),
				horaInicioExpediente:
					response.horaInicioExpediente === null
						? null
						: createDateFromTime(response.horaInicioExpediente),
			},
            statusCode: 200
		};
	}
	return {
		statusCode: httpResponse.statusCode,
		data: httpResponse.body[0] as string,
	};
}

export default buscarDefinicoesDeExpedienteDeFuncionario;