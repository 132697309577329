enum CardType {
  "ApprovedRegistry" = "ApprovedRegistry",
  "RepprovedRegistry" = "RepprovedRegistry",
  "PendingRegistry" = "PendingRegistry",

  "ApprovedDiscount" = "ApprovedDiscount",
  "RepprovedDiscount" = "RepprovedDiscount",
  "PendingDiscount" = "PendingDiscount",
}

export default CardType