import { HttpClient } from "../http/httpClient";

type props = {
    registroId: number
	novosDadosDoDia: {
		horaInicioExpediente: string;
		horaFimExpediente: string;
		horaInicioAlmoco: string;
		horaFimAlmoco: string;
		codAbono?: number
	};
};

async function atualizarRegistroDeDiaDaFolha({novosDadosDoDia, registroId}: props): Promise<boolean> {
	const httpClient = new HttpClient();
	const httpResponse = await httpClient.request(
		{
			method: "put",
			url: `/FolhaDePonto/${registroId}`,
            body: novosDadosDoDia
		},
		"PontoCerto"
	);

    return httpResponse.statusCode === 200
}

export default atualizarRegistroDeDiaDaFolha;