import React, { useContext, useEffect } from "react";
import { Provider } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";

import "./assets/global.css";
import CadastroDeHora from "./views/CadastroDeHora/CadastroDeHora";

import Main from "./views/Main/Main";
import Home from "./views/Home/Home";
import Login from "./views/Login/Login";
import { AuthContext } from "./context/authContext";
import CadastroDeDesconto from "./views/CadastroDeDesconto/CadastroDeDesconto";
import HoraExtra from "./views/HoraExtra/HoraExtra";
import DescontoDeHora from "./views/DescontoDeHora/DescontoDeHora";
import Colaboradores from "./views/Colaboradores/Colaboradores";
import OClockBackdrop from "./components/OClockBackdrop/OClockBackdrop";
import AdminHome from "./views/Home/Admin/AdminHome";
import FolhaDePonto from "./views/FolhaDePonto/FolhaDePonto";
import RedefinirSenha from "./views/RedefinirSenha/RedefinirSenha";
import { store } from "./store/store";

type CustomRouteProps = {
  isPrivate?: boolean;
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>
  | undefined;
  path: string;
  exact?: boolean;
};

export default function Routes() {
  useEffect(() => { document.title = "O'Clock" }, [])

  const { isAuthenticated, isCheckingAuthentication } =
    useContext(AuthContext);

  const CustomRoute = ({ isPrivate, ...rest }: CustomRouteProps) => {
    if (isPrivate && !isAuthenticated) {
      return <Redirect to="login" />;
    } else {
      return <Route {...rest} />;
    }
  };

  if (isCheckingAuthentication) {
    return (
      <OClockBackdrop
        open={true}
      />
    )
  } else {
    return (
      <Provider store={store}>
        <Switch>
          <CustomRoute path="/" exact component={Main} />
          <CustomRoute path="/login" exact component={Login} />
          <CustomRoute path="/redefinir-senha" component={RedefinirSenha} />
          <CustomRoute isPrivate path="/colaboradores" component={Colaboradores} />
          <CustomRoute isPrivate path="/folha-de-ponto" component={FolhaDePonto} />
          <CustomRoute isPrivate path="/home" component={Home} /> *
          <CustomRoute isPrivate path="/colaboradores" component={Colaboradores} />
          <CustomRoute path="test/admin-home" exact component={AdminHome} />
          <CustomRoute
            isPrivate
            exact
            path="/cadastrar-hora"
            component={CadastroDeHora}
          />
          <CustomRoute isPrivate path="/cadastrar-desconto" component={CadastroDeDesconto} />
          <CustomRoute isPrivate path="/hora-extra" component={HoraExtra} />
          <CustomRoute isPrivate path="/desconto-de-hora" component={DescontoDeHora} />
        </Switch>
      </Provider>
    )
  }
}
