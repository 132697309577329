import { approvedHourRegistry, pendingHourDiscount, pendingHourRegistry } from '../../domain/types';
import { HttpClient } from '../http/httpClient';
import { HttpResponse } from '../http/IHttpClient';

type props = {
  TecnicoMatricula?: string;
}

async function getPendingDiscounts({TecnicoMatricula = ''}: props): Promise<Array<pendingHourDiscount>> {

  const httpClient = new HttpClient();
  const httpResponse = await httpClient.request({
    method: "get",
    url: `/HourDiscount/get-pending-discounts`,
    params: TecnicoMatricula !== '' && {matriculaDoTecnico: TecnicoMatricula} 
  });

  const requestResult = httpResponse.body;
  return requestResult
}

export default getPendingDiscounts;