import { Box } from "@mui/material"
import Button from "../Button/Button"

type props = {
    sectionToShow: "Pending" | "Approved" | "Repproved"
    onPendingSelect: () => void
    onApprovedSelect: () => void
    onRepprovedSelect: () => void
}

const TabsTypeOfRegistry = ({
        sectionToShow,
        onPendingSelect,
        onApprovedSelect,
        onRepprovedSelect
    }: props) => (
    <Box
        sx={{
            marginTop: "10px",
            display: "flex",
            gap: "15px",
            justifyContent: "center"
        }}
    >
        <Button
            bgColor={sectionToShow === "Pending" ? "#b08a0087" : "#FDC600"}
            onClick={() => {
                onPendingSelect()
            }}
        >
            Pendentes
        </Button>
        <Button
            bgColor={sectionToShow === "Approved" ? "#b08a0087" : "#FDC600"}
            onClick={() => {
                onApprovedSelect()
            }}
        >
            Aprovadas
        </Button>
        <Button
            bgColor={sectionToShow === "Repproved" ? "#b08a0087" : "#FDC600"}
            onClick={() => {
                onRepprovedSelect()
            }}
        >
            Reprovadas
        </Button>
    </Box>
)
export default TabsTypeOfRegistry;