import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Box, Snackbar } from "@material-ui/core";

import Button from "../../components/Button/Button";
import style from "./CadastroDeHora.module.scss";
import me from "../../assets/hora-extra-icon.png";
import theme from "../../assets/theme.js"

import {
  formSelector,
  setPeriodStart,
  setPeriodEnd,
  setReason,
} from "../../slices/formSlice";
import { FormEvent, useContext, useEffect, useState } from "react";
import registerHour from "../../services/tecnico/registerHour";
import { CircularProgress } from "@material-ui/core";
import clockAvatar from "../../assets/clock-avatar.png";
// import { Style } from "@material-ui/icons";
import Fade from "@mui/material/Fade";
import { Alert, AlertTitle, ThemeProvider } from "@mui/material";
import { snackarDataType } from "../../domain/types";
import TemplateWithoutSidenav from "../../components/TemplateWithoutSidenav/TemplateWithoutSidenav";
import OClockBackdrop from "../../components/OClockBackdrop/OClockBackdrop";
import { AuthContext } from "../../context/authContext";

export default function CadastroDeHora() {
  const history = useHistory();
  const dispatch = useDispatch();
  const selector = useSelector(formSelector);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [isFetching, setIsFetching] = useState(false);

  const [snackbarData, setSnackbarData] = useState<snackarDataType | null>();

  const [showModal, setShowModal] = useState(false);

  const {user} = useContext(AuthContext)

  type MinAndMaxTimers = {
    min: Date | undefined;
    max: Date | undefined;
  };

  const [startDateTimers, setStartDateTimers] = useState({
    min: undefined,
    max: undefined,
  } as MinAndMaxTimers);

  const [endDateTimers, setEndDateTimers] = useState({
    min: undefined,
    max: undefined,
  } as MinAndMaxTimers);

  function resetTimers() {
    setStartDateTimers({ min: undefined, max: undefined });
    setEndDateTimers({ min: undefined, max: undefined });
  }

  async function handleEnviar(e: FormEvent) {
    setSnackbarData({ show: false })
    e.preventDefault();
    const hasInvalidInputs = checkInputs();

    if (hasInvalidInputs) return
    if (user === undefined) return

    setIsFetching(true);

    const { period, reason } = selector;

    const result = await registerHour(user.userMatricula, period.started, period.finished, reason)

    setTimeout(() => {
      setSnackbarData({
        show: true,
        message: result.body.message,
        type: result.statusCode !== 201 ? 'error' : 'success'
      })

      setIsFetching(false)
    }, 1000)
  }

  function handleFocus(target: any) {
    target.classList.contains("is-invalid") &&
      target.classList.remove("is-invalid");
  }

  function checkInputs(): boolean {
    if (!startDate)
      document.getElementById("startDate")?.classList.add("is-invalid");
    if (!endDate)
      document.getElementById("endDate")?.classList.add("is-invalid");

    const textarea = document.querySelector("textarea");
    textarea?.value.trim() === "" && textarea?.classList.add("is-invalid");
    const allInputs = document.querySelectorAll("input");
    allInputs.forEach(
      (input) => input.value.trim() === "" && input.classList.add("is-invalid")
    );
    return document.querySelectorAll('.is-invalid').length > 0
  }

  useEffect(() => {
    setIsFetching(true)

    const allInputsToAvoidTyping: NodeListOf<Element> =
      document.querySelectorAll(".inputToAvoidTyping");
    for (let input of allInputsToAvoidTyping as any) {
      input.readOnly = true;
    }

    setIsFetching(false)
  }, []);

  useEffect(() => {
    dispatch(setPeriodStart(startDate));
    dispatch(setPeriodEnd(endDate));

    const startTotalOfDays = startDate !== null && daysFromRefDate(startDate);
    const endTotalOfDays = endDate !== null && daysFromRefDate(endDate);

    resetTimers();

    if (startTotalOfDays !== endTotalOfDays) return;

    let maxTime = new Date();
    maxTime.setHours(23, 59, 0, 0);

    let minTime = new Date();
    minTime.setHours(0, 0, 0, 0);

    if (startDate !== null) {
      setEndDateTimers({ min: startDate, max: maxTime });
    } else {
      setEndDateTimers({ min: minTime, max: maxTime });
    }

    if (endDate !== null) {
      setStartDateTimers({ min: minTime, max: endDate });
    } else {
      setStartDateTimers({ min: minTime, max: maxTime });
    }
  }, [startDate, endDate]);

  //calcular o número de dias desde o moon landing
  const daysFromRefDate = (date: Date) =>
    date &&
    Math.floor((date.getTime() - date.getTimezoneOffset() * 60000) / 86400000);

  if (isFetching) {
    return (
      <OClockBackdrop
        open={isFetching}
      />
    )
  }
  else {
    return (
      <ThemeProvider theme={theme}>

        <TemplateWithoutSidenav />

        <div className={style.container}>
          <Snackbar
            open={snackbarData?.show}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <Alert
              variant="filled"
              onClose={() => {
                setSnackbarData({ ...snackbarData, show: false });
              }}
              severity={snackbarData?.type}
            >
              <AlertTitle>
                {snackbarData?.message}
              </AlertTitle>
            </Alert>
          </Snackbar>

          <Modal
            closeAfterTransition
            BackdropProps={{
              timeout: 1000,
            }}
            open={showModal}
            onClose={() => setShowModal(false)}
          >
            <Fade in={showModal}>
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "70%",
                  margin: "2em",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#e6e4f0",
                  boxShadow: "24px",
                  padding: "4em",
                  borderRadius: "2em",
                  border: "none"
                }}
              >
                <section className={style.modalContent}>
                  <div>
                    <img src={clockAvatar} alt="sadf" width="400px" />
                  </div>
                  <div>
                    <p className={style.modalHeader}>Estamos em evolução!</p>
                    <p className={style.modalBody}>
                      Em breve você poderá acompanhar o status das horas em seu
                      banco e já solicitar o desconto destas...
                    </p>
                  </div>
                </section>
              </Box>
            </Fade>
          </Modal>

          <div className={style.left}>
            <span
              className={style.returnArrowBtn}
              onClick={() => history.goBack() }
            >
              <ArrowBackIcon
                style={{
                  fontSize: "3em",
                  color: "rgb(253, 198, 0)"
                }}
              />
            </span>
            <div className={style.description}>
              <p className={style.header}>Cadastro de hora extra</p>
              <p>
                O cadastro do período trabalhado a mais será enviado para aprovação
                da gestão.
              </p>
              <p>
                Se o cadastro for aprovado, as horas serão adicionadas em seu banco
                de horas.
              </p>
            </div>
            <div className={style.imgArea}>
              <img src={me} />
            </div>
          </div>
          <div className={style.right}>
            <form className={style.form} onSubmit={handleEnviar}>
              <fieldset className={style.fieldset}>
                <legend>Dados do período</legend>

                <div className={style.inputRow}>
                  <div className={style.inputGroup}>
                    <label htmlFor="initialDate">Início</label>

                    <DatePicker
                      selected={startDate || null}
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeFormat="HH:mm"
                      locale={ptBR}
                      onChange={(e: any) => {
                        setStartDate(e);
                      }}
                      onFocus={() => {
                        if (!startDate) setStartDate(endDate);
                      }}
                      timeCaption="Hora"
                      className={"inputToAvoidTyping"}
                      showTimeSelect
                      timeIntervals={1}
                      maxDate={endDate}
                      minTime={startDateTimers.min}
                      maxTime={startDateTimers.max}
                      id="startDate"
                    />
                  </div>

                  <div className={style.inputGroup}>
                    <label htmlFor="finalDate">Término</label>
                    <DatePicker
                      selected={endDate || null}
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeFormat="HH:mm"
                      locale={ptBR}
                      onChange={(e: any) => {
                        setEndDate(e);
                      }}
                      onFocus={(e: any) => {
                        if (!endDate) setEndDate(startDate);
                      }}
                      timeCaption="Hora"
                      className={"inputToAvoidTyping"}
                      showTimeSelect
                      timeIntervals={1}
                      minDate={startDate}
                      minTime={endDateTimers.min}
                      maxTime={endDateTimers.max}
                      id="endDate"
                    />
                  </div>
                </div>

                <div className={style.inputGroup}>
                  <label htmlFor="reason">Motivo do período trabalhado a mais</label>
                  <textarea
                    disabled={isFetching}
                    id="reason"
                    rows={10}
                    className={style.reason}
                    onChange={({ target }) => {
                      dispatch(setReason(target.value));
                    }}
                    onFocus={({ target }) => handleFocus(target)}
                  />
                </div>
              </fieldset>


              <footer className={style.footerUnderForm}>
                <div className={style.submitBtnArea}>
                  {isFetching && <CircularProgress />}
                  {!isFetching && <Button>Enviar</Button>}
                </div>
              </footer>
            </form>
          </div>
        </div>
      </ThemeProvider>
    );
  }

}
