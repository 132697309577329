import { HttpClient } from "../http/httpClient";
import { HttpResponse } from "../http/IHttpClient";

function registerDiscount(
  TecnicoMatricula: string,
  Start: number,
  End: number,
  reason: string
): Promise<HttpResponse> {
  const httpClient = new HttpClient();
  const httpResponse = httpClient.request({
		method: "post",
		url: `/tecnico/register-discount`,
		body: {
			TecnicoMatricula,
			Start,
			End,
		},
	});

  return httpResponse;
}

export default registerDiscount;
