import { ThemeProvider } from "@emotion/react";
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Backdrop, Box, Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridActionsCellItem, GridColDef, GridInputRowSelectionModel, GridRowId, GridRowModes, GridRowModesModel, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import oclockTheme from "../../../assets/theme";
import Loader from "../../../components/Loader/Loader";
import { DiaNaFolhaDePonto, FolhaDePontoEntity } from "../../../domain/entities/folhaDePonto";
import atualizarRegistroDeDiaDaFolha from "../../../services/folhaDePonto/atualizarRegistroDeDiaDaFolha";
import getFolhaDePonto from "../../../services/folhaDePonto/buscarFolhaDePonto";
import AlteraMesAnoDaFolhaParaCarregar from "./AlteraMesAnoDaFolhaParaCarregar";
import ImprimirFolha from "./ImprimirFolharFooterBtn";
import { format, isMatch, isValid, parse } from "date-fns";
import { ptBR } from 'date-fns/locale';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalEditarDiasEmLote from "../../../components/ModalEditarDiasEmLote/ModalEditarDiasEmLote";


const TabelaFolhaDePonto = () => {
    const [folhaParaCarregar, setFolhaParaCarregar] = useState<{ mes: number, ano: number }>({
        mes: new Date().getMonth() + 1,
        ano: new Date().getFullYear()
    })
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    
    const [showModalEditarDiasEmLote, setShowModalEditarDiasEmLote] = useState(false)
    const [idRowsSelecionadas, setIdRowsSelecionadas] = useState<number[]>([])
    const [diaUnicoParaPreencher, setDiaUnicoParaPreencher] = useState<DiaNaFolhaDePonto>()

    const [rows, setRows] = useState<GridRowsProp>([])
    const [isFetching, setIsFetching] = useState(true)
    const [data, setData] = useState<{ statusCode: number, data: FolhaDePontoEntity | string }>()
    const [funcionarioPodeImprimir, setFuncionarioPodeImprimir] = useState(false)
    const handleEditClick = (id: GridRowId) => () => {
        //todos objetos passados como rows da tabela possuem um id, ao efetuar o click de edicação
        //é recebido o id do objeto que está em uma linha da tabela. Logo, esse objeto é definido
        //na RowModesModel com o mode = GridRowModes.Edit. A identificação da linha e do seu estado
        //permite que a tabela se comporte de maneira a habilitar a edição daquela linha.
        //o mesmo ocorre para os casos de cancelamento de edição e gravação
        if (!funcionarioPodeImprimir) return

        const isUsuarioEditandoUmDia = idRowsSelecionadas.length === 0

        if (isUsuarioEditandoUmDia) {
            const diaParaEditar = (data?.data as FolhaDePontoEntity).diasDoMes.find(i => i.id === id as number)
            setDiaUnicoParaPreencher(diaParaEditar)
        }
        setShowModalEditarDiasEmLote(true)
    };

    const isTimeValid = (timeString: string) => {
        return isMatch(timeString, "HH:mm", {
            locale: ptBR
        })
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'dia',
            type: "date",
            headerName: 'Dia',
            width: 100,
            editable: false,
            sortable: funcionarioPodeImprimir,
            disableColumnMenu: true,
            align: "center",
        },
        {
            headerName: '',
            field: 'diaDaSemana',
            type: 'string',
            sortable: false,
            disableColumnMenu: true,
            width: 180,
            editable: false,
            align: 'left',
            headerAlign: 'left',
        },
        {
            headerName: 'Entrada',
            field: 'horaInicioExpediente',
            type: 'time',
            width: 180,
            disableColumnMenu: true,
            editable: true,
            sortable: funcionarioPodeImprimir,
        },
        {
            headerName: 'Saída',
            field: 'horaInicioAlmoco',
            type: 'time',
            width: 180,
            disableColumnMenu: true,
            editable: true,
            sortable: funcionarioPodeImprimir,
        },
        {
            headerName: 'Entrada',
            field: 'horaFimAlmoco',
            type: 'time',
            disableColumnMenu: true,
            width: 180,
            editable: true,
            sortable: funcionarioPodeImprimir,
        },
        {
            headerName: 'Saída',
            field: 'horaFimExpediente',
            type: 'time',
            width: 180,
            disableColumnMenu: true,
            editable: true,
            sortable: funcionarioPodeImprimir,
        },
        {
            headerName: 'Código abono',
            field: 'codAbono',
            type: 'singleSelect',
            width: 360,
            disableColumnMenu: true,
            editable: true,

            valueOptions: [
                { value: null, label: "Nenhum" },
                { value: 1, label: '1 - Afastamento' },
                { value: 2, label: '2 - Compensação' },
                { value: 3, label: '3 - Falta justificada' },
                { value: 4, label: '4 - Feriado' },
                { value: 5, label: '5 - Férias' },
                { value: 6, label: '6 - Parcial justificada' },
                { value: 7, label: '7 - Falta não justificada' },
                { value: 8, label: '8 - Serviço externo' },
                { value: 9, label: '9 - Horas extras' },
                { value: 10, label: '10 - Banco de horas' },
            ],
        },
        {
            field: 'actions',
            type: 'actions',
            disableColumnMenu: true,
            headerName: 'Ações',
            width: 100,
            cellClassName: 'actions',
            renderCell: ({ id, row, api }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return (
                        <>
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={() => {
                                    api.setEditCellValue({
                                        field: "horaInicioExpediente",
                                        id,
                                        value: null
                                    })
                                    api.setEditCellValue({
                                        field: "horaFimExpediente",
                                        id,
                                        value: null
                                    })
                                    api.setEditCellValue({
                                        field: "horaInicioAlmoco",
                                        id,
                                        value: null
                                    })
                                    api.setEditCellValue({
                                        field: "horaFimAlmoco",
                                        id,
                                        value: null
                                    })

                                    api.setEditCellValue({
                                        field: "codAbono",
                                        id,
                                        value: null
                                    })
                                }}

                            />
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={handleSaveClick(id)}
                            />
                        </>
                    )

                } else {
                    return (<GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />)
                }
            },
        }
    ];

    const getData = async () => {
        getFolhaDePonto({ mes: folhaParaCarregar.mes, ano: folhaParaCarregar.ano }).then(result => {
            if (result.statusCode === 200) {
                setFuncionarioPodeImprimir(true)
                setData({ data: result.data as FolhaDePontoEntity, statusCode: 200 })
                setRows((result.data as FolhaDePontoEntity).diasDoMes)
            } else {
                setFuncionarioPodeImprimir(false)
                setData({ data: result.data as FolhaDePontoEntity, statusCode: result.statusCode })
            }


        }).catch(e => console.log("caiu no catch", e))
            .finally(() => {
                setIsFetching(false)
                setIdRowsSelecionadas([])
            })
    }

    useEffect(() => {
        getData()
    }, [folhaParaCarregar])

    useEffect(() => {
        if(!showModalEditarDiasEmLote && diaUnicoParaPreencher !== undefined) setDiaUnicoParaPreencher(undefined)
    }, [showModalEditarDiasEmLote])

    const useStyles: any = makeStyles(() => ({
        overlayContainer: {
            position: 'relative',
            height: "100%",
            width: "100%",
        },
        overlay: {
            filter: "blur(3px)",
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: 1,
        },
        modal: {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
            padding: "35px",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <ThemeProvider theme={oclockTheme}>

            <ModalEditarDiasEmLote 
                recarregarFolhaDePonto={getData} 
                show={showModalEditarDiasEmLote} 
                setShow={setShowModalEditarDiasEmLote} 
                diasIds={idRowsSelecionadas!} 
                diaUnicoParaPreencher={diaUnicoParaPreencher}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ImprimirFolha funcionarioPodeImprimir={funcionarioPodeImprimir} folhaParaImprimir={folhaParaCarregar} />
                <AlteraMesAnoDaFolhaParaCarregar folhaParaCarregar={folhaParaCarregar} setFolhaParaCarregar={setFolhaParaCarregar} />
            </div>

            <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert onClose={() => setShowSnackbar(false)} variant="filled" severity="warning" sx={{ width: '100%', textAlign: "center" }}>
                    Dia editado na folha de ponto possui horário(s) inválido(s) <br />
                    Ajuste os horários para o formato esperado (HH:mm) e tente novamente
                </Alert>
            </Snackbar>,

            {!isFetching && data && data?.statusCode === 200 &&
                <DataGrid
                    rowModesModel={rowModesModel}
                    editMode="row"
                    hideFooter
                    processRowUpdate={(dadosNovos, dadosAntigos) => {
                        const horariosDefinidos = [dadosNovos.horaFimAlmoco,
                        dadosNovos.horaFimExpediente,
                        dadosNovos.horaInicioAlmoco,
                        dadosNovos.horaInicioExpediente]

                        const horarios = horariosDefinidos.filter(i => i !== undefined && i !== null && i !== "")
                        if (horarios.some(i => !isTimeValid(i as string))) {
                            setShowSnackbar(true)
                        }

                        const novosDadosDoDia = {
                            horaFimAlmoco: dadosNovos.horaFimAlmoco,
                            horaFimExpediente: dadosNovos.horaFimExpediente,
                            horaInicioAlmoco: dadosNovos.horaInicioAlmoco,
                            horaInicioExpediente: dadosNovos.horaInicioExpediente,
                            codAbono: dadosNovos.codAbono
                        }
                        atualizarRegistroDeDiaDaFolha({
                            novosDadosDoDia,
                            registroId: dadosNovos.id
                        })
                    }}
                    rowSelection
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(row) => { setIdRowsSelecionadas(row as any) }}
                    rows={rows}
                    columns={columns}
                />
            }

            {isFetching && <Loader />}

            {!isFetching && data && data.statusCode === 400 && (
                <div className={classes.overlayContainer}>
                    <Box>
                        <Paper
                            className={classes.modal}
                            elevation={3}
                            sx={{
                                backgroundColor: "secondary.main",
                            }}
                        >
                            <p>Você não possui permissão para gerar folha de ponto do mês e ano selecionado</p>
                            <p>Contate o administrador do sistema</p>
                        </Paper>
                    </Box>
                    <div className={classes.overlay}>
                        <DataGrid
                            loading
                            disableColumnMenu
                            disableColumnSelector
                            disableColumnFilter
                            disableRowSelectionOnClick
                            disableVirtualization
                            hideFooter
                            rowSelection={false}
                            rowModesModel={rowModesModel}
                            editMode="row"
                            processRowUpdate={(dadosNovos, dadosAntigos) => {
                                const novosDadosDoDia = {
                                    horaFimAlmoco: dadosNovos.horaFimAlmoco,
                                    horaFimExpediente: dadosNovos.horaFimExpediente,
                                    horaInicioAlmoco: dadosNovos.horaInicioAlmoco,
                                    horaInicioExpediente: dadosNovos.horaInicioExpediente,
                                }
                                atualizarRegistroDeDiaDaFolha({
                                    novosDadosDoDia,
                                    registroId: dadosNovos.id
                                })
                            }}
                            rows={rows}
                            columns={columns}
                        />
                    </div>
                </div>
            )}
        </ThemeProvider>
    )
}

export default TabelaFolhaDePonto