import { Box, Typography } from "@mui/material";
import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
const FileUpload = ({ onFileUpload }: {
    onFileUpload: (base64: string) => any
}) => {
    const [arquivo, setArquivo] = useState<File | null>(null)

    const onDrop = useCallback((acceptedFiles) => {
        // Handle the dropped files
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];

            const reader = new FileReader();

            reader.onload = () => {
                const base64Data = reader.result;
                onFileUpload(base64Data as string)
                // Add your logic to handle the Base64 data here
            };

            reader.readAsDataURL(file);

            setArquivo(file)
            // Add your file handling logic here
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'application/pdf': ['.pdf'],
        }
    });
    return (
        <Box>
            <div>
                <p>
                    Para receber a folha de ponto preenchida, anexe no campo abaixo o PDF da folha de ponto do mês e ano selecionado
                </p>
            </div>
            <div
                style={{
                    textAlign: "center",
                    padding: "15px",
                    margin: "15px 0",
                    borderRadius: "1em",
                    backgroundColor: "#F4F4F8",
                    cursor: "pointer"
                }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <UploadFileIcon sx={{ fontSize: "150px!important" }} />
                <Typography variant="body1">
                    {arquivo &&
                        <div style={{ display: "flex", justifyContent: "center", zIndex: 9000 }}>
                            {arquivo.name}
                            <CloseIcon />
                        </div>}
                    {!arquivo && isDragActive && 'Clique aqui para adicionar o PDF'}
                    {!arquivo && !isDragActive && 'Adicione ou arraste e solte o PDF aqui'}
                </Typography>
            </div>
        </Box>
    );
};

export default FileUpload