import { Skeleton, Typography } from "@mui/material";

const HourRegistryCardSkeleton = () => (
    <>
        <Typography variant="h1" width="75%" padding="0" marginTop="-15px"><Skeleton /></Typography>
        <Typography variant="h4" marginBottom="-10px"><Skeleton /></Typography>
        <Typography variant="subtitle1" marginBottom="10px"><Skeleton /></Typography>
        <Typography variant="h4" marginBottom="-10px"><Skeleton /></Typography>
        <Typography variant="subtitle1" marginBottom="10px"><Skeleton /></Typography>
        <Typography variant="h4" marginBottom="-10px"><Skeleton /></Typography>
        <Typography variant="subtitle1" marginBottom="10px"><Skeleton /></Typography>
        <Typography variant="h4" marginBottom="-10px"><Skeleton /></Typography>
        <Typography variant="subtitle1"><Skeleton /></Typography>
    </>
)

export default HourRegistryCardSkeleton