import { HttpClient } from '../http/httpClient';
import { HttpResponse } from '../http/IHttpClient';


async function approveRegistry(
  registryId: number,
  AdminMatricula: string,
  registryType: "pendingHourDiscount" | "pendingHourRegistry"
): Promise<HttpResponse> {
  const httpClient = new HttpClient();
  
  const httpResponse = await httpClient.request({
    method: "post",
    url:
      registryType === "pendingHourDiscount"
        ? `/administrator/approve-discount`
        : `/administrator/approve-hour`,
    body: {
      ID: String(registryId),
      AdminMatricula,
    },
  });

  return httpResponse;
}

export default approveRegistry;