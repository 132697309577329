import React, { useContext, useEffect } from 'react';
import LoginIcon from '@mui/icons-material/Login';
import style from './Home.module.scss'
import logo from '../../assets/Group3@3x.png'
import smallLogo from '../../assets/Group3@2x.png'
import guyOnComputer2x from '../../assets/dev@2x.png'
import guyOnComputer from '../../assets/dev.png'
import Button from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowsSize';
import { AuthContext } from '../../context/authContext';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import theme from '../../assets/theme'
import TemplateWithoutSidenav from '../../components/TemplateWithoutSidenav/TemplateWithoutSidenav'
function Main() {

  const [windowWidth] = useWindowSize()

  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    document.title = "O'Clock"
  }, [])
  return (
    <ThemeProvider
      theme={theme}
    >
      {
        isAuthenticated &&
        <TemplateWithoutSidenav />
      }
      <div className={style.container}>
        <section className={style.holdsContent}>

          <div className={style.left}>
            <div className={style.brand}>
              <img alt="logo" src={windowWidth <= 768 ? smallLogo : logo} />
              <p>Sistema para gerenciamento</p>
              <p>de horas</p>
            </div>
            <div className={style.quote}>
              {/* <p>
              Cadastre o período trabalhado além do 
              expediente
              </p>
              <p>
              e tenha suas horas salvas na casa 
              para aproveitar futuramente  
              </p> */}
              <p>
                Chega de papéis e processos manuais!
              </p>
              <p style={{marginTop: "15px"}}>
                Registre e gerencie aqui as suas horas da folha de ponto com praticidade e velocidade
              </p>
            </div>
          </div>

          <div className={style.right}>
            <div
              className={style.holdsLoginBtn}
            >
              {!isAuthenticated &&
                <Link to={'/login'}>
                  <Typography
                    sx={{
                      fontSize: '26px',
                      fontWeight: '500',
                      color: 'primary.main',
                      letterSpacing: '.1em'
                    }}
                  >
                    Entrar
                  </Typography>
                  <LoginIcon
                    fontSize={'large'}
                    sx={{ color: 'primary.main' }}
                  />
                </Link>
              }
            </div>
            <img className={style.guyOnComputer} src={windowWidth <= 768 ? guyOnComputer : guyOnComputer2x} alt="" />
          </div>
        </section>

        <footer className={style.footer}>
          {/* <Link 
            to={{
              pathname: isAuthenticated ? "/cadastrar-hora" : "/login",
              state: {
                comingFrom: "main-page"
              }
            }}

          >
            <Button fontSize="1.3em">
              Cadastrar hora
            </Button>
          </Link> */}

          <Link
            to={{
              pathname: isAuthenticated ? "/folha-de-ponto" : "/login",
              state: {
                comingFrom: "main-page"
              }
            }}
          >
            <Button style={{ width: "40px!important", backgroundColor: "#FDC600", padding: "15px", marginBottom: "30px" }} fontSize="1.3em">
              Visualizar folha de ponto
            </Button>
          </Link>
        </footer>
      </div>
    </ThemeProvider>
  );
}

export default Main;
