import { HttpClient } from "../http/httpClient";

export async function validateToken(){
    const httpClient = new HttpClient();

    const httpResponse = await httpClient.request(
			{
				url: "/Auth/validate",
				method: "post",
			},
			"PontoCerto"
		);
    
    return httpResponse;
}