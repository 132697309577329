import { ThemeProvider } from "@emotion/react";
import { Button, LinearProgress, Paper, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import oclockTheme from "../../assets/theme";
import { HourRegistry } from "../../domain/entities";
import CardType from "../../domain/enums/cardType";
import { approvedHourRegistry, repprovedHourRegistry, pendingHourRegistry, approvedHourDiscount, repprovedHourDiscount, pendingHourDiscount } from "../../domain/types";
import useIsUserAdmin from "../../hooks/useIsUserAdmin";

import style from './HourRegistryCard.module.scss'
import HourRegistryCardSkeleton from "./HourRegistryCardSkeleton";

type Props = {
    registry: any,
    approveRegistry?: (reg: pendingHourRegistry) => void;
    repproveRegistry?: (reg: pendingHourRegistry) => void;
    cardType: CardType,
    isFetching: boolean
}

function HourRegistryCard({ registry, approveRegistry, repproveRegistry, isFetching, cardType }: Props) {

    const isUserAdmin = useIsUserAdmin()

    return (
        <ThemeProvider theme={oclockTheme}>
            <Box
                sx={{
                    width: "600px",
                    padding: "10px"
                }}
            >
                <Paper
                    elevation={3}
                >
                    <main className={style.hourCard}>
                        {!isFetching && <div className={style.hourCardInfo}>
                            <div>
                                <p className={style.totalHoursInRegistry}>{registry.hours} hora(s) e {registry.minutes} minuto(s)</p>
                                <p>{registry.tecnicoName} - {registry.tecnicoMatricula}</p>
                            </div>
                            <div>
                                <Typography variant="h5" sx={{ color: 'primary.main' }}>Período</Typography>
                                <span>{registry.startDate} - {registry.endDate}</span>
                            </div>

                            {
                                (cardType !== CardType.ApprovedDiscount && cardType !== CardType.RepprovedDiscount && cardType !== CardType.PendingDiscount) && (
                                    <div>
                                        <Typography variant="h5" sx={{ color: 'primary.main' }}>Motivo</Typography>
                                        <p>{registry.reason}</p>
                                    </div>
                                )
                            }

                            {
                                (cardType === CardType.PendingDiscount || cardType === CardType.PendingRegistry) ?
                                    (
                                        isUserAdmin &&
                                        <>
                                            <Button
                                                style={{ color: 'white' }}
                                                variant="contained"
                                                color="success"
                                                onClick={() => approveRegistry && approveRegistry(registry)}
                                                disabled={isFetching}
                                            >
                                                Aprovar
                                            </Button>
                                            <Button
                                                style={{ color: 'white', marginLeft: "25px" }}
                                                variant="contained"
                                                color="error"
                                                onClick={() => repproveRegistry && repproveRegistry(registry)}
                                                disabled={isFetching}
                                            >
                                                Reprovar
                                            </Button>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div>
                                                <Typography variant="h5" sx={{ color: 'primary.main' }}>
                                                    {cardType === CardType.ApprovedRegistry || cardType === CardType.ApprovedDiscount ?
                                                        'Registro aprovado em'
                                                        :
                                                        'Registro reprovado em '}
                                                </Typography>
                                                <p>
                                                    {cardType === CardType.ApprovedRegistry || cardType === CardType.ApprovedDiscount ?
                                                        registry.approvedIn
                                                        :
                                                        registry.repprovedIn}
                                                </p>
                                            </div>
                                            <div>
                                                <Typography variant="h5" sx={{ color: 'primary.main' }}>
                                                    {cardType === CardType.ApprovedRegistry || cardType === CardType.ApprovedDiscount ?
                                                        'Registro aprovado por'
                                                        :
                                                        'Registro reprovado por '}
                                                </Typography>
                                                <p>
                                                    {cardType === CardType.ApprovedRegistry || cardType === CardType.ApprovedDiscount ?
                                                        registry.approvedBy
                                                        :
                                                        registry.repprovedBy}
                                                </p>
                                            </div>
                                        </>
                                    )

                            }
                        </div>}

                        {
                            isFetching && <HourRegistryCardSkeleton />
                           
                        }
                    </main>
                </Paper>
            </Box>
        </ThemeProvider>
    )
}

export default HourRegistryCard