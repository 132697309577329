import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import createStringHHmmFromDate from "../../helpers/createStringHHmmFromDate";
import atualizarLoteDeDiasDaFolha from "../../services/folhaDePonto/atualizarLoteDeDiasDaFolha";
import { DiaNaFolhaDePonto } from "../../domain/entities/folhaDePonto";
import createDateFromTime from "../../helpers/createDateFromTime";

type props = {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    diasIds: number[]
    recarregarFolhaDePonto: () => Promise<void>
    diaUnicoParaPreencher: DiaNaFolhaDePonto | undefined
}

const ModalEditarDiasEmLote = ({ show, setShow, diasIds, recarregarFolhaDePonto, diaUnicoParaPreencher }: props) => {
    const [dadosExpediente, setDadosExpediente] = useState<{
        horaInicioExpediente: string | null
        horaFimExpediente: string | null
        horaInicioAlmoco: string | null
        horaFimAlmoco: string | null
        codAbono: number | null
    }>({
        horaInicioExpediente: null,
        horaFimExpediente: null,
        horaInicioAlmoco: null,
        horaFimAlmoco: null,
        codAbono: null,
    })

    const [isFetching, setIsFetching] = useState(false)
    useEffect(() => {
        if (show && !diasIds) setShow(false)
    }, [show])

    useEffect(() => {
        if (diaUnicoParaPreencher) {
            setDadosExpediente({
                horaInicioExpediente: diaUnicoParaPreencher.horaInicioExpediente,
                horaFimExpediente: diaUnicoParaPreencher.horaFimExpediente,
                horaInicioAlmoco: diaUnicoParaPreencher.horaInicioAlmoco,
                horaFimAlmoco: diaUnicoParaPreencher.horaFimAlmoco,
                codAbono: diaUnicoParaPreencher.codAbono,
            })
        }
    }, [diaUnicoParaPreencher])

    return (<Dialog disableEscapeKeyDown open={show}>
        <DialogTitle>
            Atualizar dias selecionados
        </DialogTitle>
        <DialogContent>
            <Box>
                <div>
                    <p>
                        Todos os dias selecionados serão atualizados com as informações definidas nos campos abaixo
                    </p>
                </div>

                <Grid sx={{ padding: "15px 0" }} spacing={8} container>
                    <Grid item>
                        <p>Entrada</p>
                        <MobileTimePicker
                            defaultValue={diaUnicoParaPreencher?.horaInicioExpediente ? createDateFromTime(diaUnicoParaPreencher.horaInicioExpediente) : null}
                            value={dadosExpediente.horaInicioExpediente ? createDateFromTime(dadosExpediente.horaInicioExpediente) : null}
                            onChange={(e: Date | null) => {
                                if (e === null)
                                    setDadosExpediente({ ...dadosExpediente, horaInicioExpediente: null })

                                else
                                    setDadosExpediente({ ...dadosExpediente, horaInicioExpediente: createStringHHmmFromDate(e) })
                            }}
                            ampm={false} />
                    </Grid>

                    <Grid item >
                        <p>Saída</p>
                        <MobileTimePicker
                            defaultValue={diaUnicoParaPreencher?.horaInicioAlmoco ? createDateFromTime(diaUnicoParaPreencher.horaInicioAlmoco) : null}
                            value={dadosExpediente.horaInicioAlmoco ? createDateFromTime(dadosExpediente.horaInicioAlmoco) : null}
                            onChange={(e: Date | null) => {
                                if (e === null)
                                    setDadosExpediente({ ...dadosExpediente, horaInicioAlmoco: null })

                                else
                                    setDadosExpediente({ ...dadosExpediente, horaInicioAlmoco: createStringHHmmFromDate(e) })
                            }}
                            ampm={false} />
                    </Grid>

                    <Grid item>
                        <p>Entrada</p>
                        <MobileTimePicker
                            defaultValue={diaUnicoParaPreencher?.horaFimAlmoco ? createDateFromTime(diaUnicoParaPreencher.horaFimAlmoco) : null}
                            value={dadosExpediente.horaFimAlmoco ? createDateFromTime(dadosExpediente.horaFimAlmoco) : null}
                            onChange={(e: Date | null) => {
                                if (e === null)
                                    setDadosExpediente({ ...dadosExpediente, horaFimAlmoco: null })

                                else
                                    setDadosExpediente({ ...dadosExpediente, horaFimAlmoco: createStringHHmmFromDate(e) })
                            }}
                            ampm={false} />
                    </Grid>

                    <Grid item >
                        <p>Saída</p>
                        <MobileTimePicker
                            defaultValue={diaUnicoParaPreencher?.horaFimExpediente ? createDateFromTime(diaUnicoParaPreencher.horaFimExpediente) : null}
                            value={dadosExpediente.horaFimExpediente ? createDateFromTime(dadosExpediente.horaFimExpediente) : null}
                            onChange={(e: Date | null) => {
                                if (e === null)
                                    setDadosExpediente({ ...dadosExpediente, horaFimExpediente: null })

                                else
                                    setDadosExpediente({ ...dadosExpediente, horaFimExpediente: createStringHHmmFromDate(e) })
                            }}
                            ampm={false}
                        />
                    </Grid>
                </Grid>
                <p>Código abono</p>
                <Select
                    onChange={(e) => {
                        if (e.target.value === "") setDadosExpediente({ ...dadosExpediente, codAbono: null })
                        else setDadosExpediente({ ...dadosExpediente, codAbono: e.target.value as any })
                    }}
                    displayEmpty
                    fullWidth
                    defaultValue={diaUnicoParaPreencher?.codAbono ?? ""}
                    value={dadosExpediente?.codAbono ?? ""}
                >
                    <MenuItem value={""}>Nenhum</MenuItem>
                    <MenuItem value={1}>1 - Afastamento</MenuItem>
                    <MenuItem value={2}>2 - Compensação</MenuItem>
                    <MenuItem value={3}>3 - Falta justificada</MenuItem>
                    <MenuItem value={4}>4 - Feriado</MenuItem>
                    <MenuItem value={5}>5 - Férias</MenuItem>
                    <MenuItem value={6}>6 - Parcial justificada</MenuItem>
                    <MenuItem value={7}>7 - Falta não justificada</MenuItem>
                    <MenuItem value={8}>8 - Seriço externo</MenuItem>
                    <MenuItem value={9}>9 - Horas extras</MenuItem>
                    <MenuItem value={10}>10 - Banco de horas</MenuItem>
                </Select>
            </Box>

        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    setDadosExpediente({
                        horaInicioExpediente: null,
                        horaFimExpediente: null,
                        horaInicioAlmoco: null,
                        horaFimAlmoco: null,
                        codAbono: null,
                    })
                }}
                disabled={isFetching}
            >
                Limpar
            </Button>
            <Button
                onClick={() => {
                    setShow(false)
                }}
                disabled={isFetching}
            >
                Fechar
            </Button>
            <Button
                onClick={async () => {
                    try {
                        setIsFetching(true)
                        await atualizarLoteDeDiasDaFolha({
                            novosDadosDosDias: dadosExpediente,
                            registrosId: diasIds.length > 0 ? diasIds : [diaUnicoParaPreencher!.id]
                        })
                    }
                    catch (err) {
                        console.log("Falha ao editar dias em lote", err)
                    }
                    finally {
                        await recarregarFolhaDePonto()
                        setShow(false)
                        setIsFetching(false)
                    }

                }}
                disabled={isFetching}
            >
                Atualizar
            </Button>
        </DialogActions>
    </Dialog>)
}
export default ModalEditarDiasEmLote