import { Alert, AlertTitle, Box, Modal, Snackbar, ThemeProvider, Typography } from "@mui/material";
import { useContext, useState } from "react";
import oclockTheme from "../../assets/theme";
import { AuthContext } from "../../context/authContext";
import { snackarDataType, tecnico } from "../../domain/types";
import registerTecnico from "../../services/administrador/registerTecnico";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Loader from "../Loader/Loader";

type props = {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    updateTecnicos: () => void
}

export default function ModalRegistrarTecnico({ show, setShowModal, updateTecnicos}: props){
    
    const [tecnicoName, setTecnicoName] = useState<string>("")
    const [tecnicoMatricula, setTecnicoMatricula] = useState<string>("")
    
    const { user } = useContext(AuthContext)
    
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [snackbarData, setSnackbarData] = useState<snackarDataType | null>();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault()

        if (user?.userMatricula) {
            try{
                await registerTecnico(tecnicoName, tecnicoMatricula, user.userMatricula)
                    .then(e => {
                        if(e.statusCode !== 201) {
                            setSnackbarData({
                                show: true,
                                type: "warning",
                                message: e.body.message
                            })
                        }
                        else {
                            setSnackbarData({
                                show: true,
                                type: "success",
                                message: e.body.message
                            })

                            setTecnicoMatricula("")
                            setTecnicoName("")
                            updateTecnicos()
                        }
                    })
            }
            catch(e){
                setSnackbarData({
                    show: true,
                    type: "warning",
                    message: "Um erro inesperado ocorreu. Favor tentar novamente mais tarde"
                })
            }
            finally{
                setIsFetching(false)
            }

        }
    }

    return (
        <ThemeProvider theme={oclockTheme}>
            <Snackbar
                open={snackbarData?.show}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
                <Alert
                    variant="filled"
                    onClose={() => {
                        setSnackbarData({ ...snackbarData, show: false });
                    }}
                    severity={snackbarData?.type}
                >
                    <AlertTitle>
                        {snackbarData?.message}
                    </AlertTitle>
                </Alert>
            </Snackbar>
            
            <Modal
                open={show}
                onClose={() => setShowModal(false)}
                disableEnforceFocus 
            >  
                <Box
                    sx={{
                        backgroundColor: "primary.main",
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: 'translate(-50%, -50%)',
                        padding: "20px 45px",
                        borderRadius: "16px",
                        color: "white"
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "26px",
                            fontWeight: 500,
                            marginBottom: "10px",
                            color: "secondary.main"
                        }}
                    >
                        Cadastrar colaborador
                    </Typography>

                    <form
                        onSubmit={e => handleSubmit(e)}
                        style={{
                            display: 'flex',
                            gap: "20px",
                            flexDirection: "column",
                        }}
                    >
                        <Box>
                            <label htmlFor="tecnico-name">Nome</label>
                            <Input
                                width="100%"
                                id="tecnico-name"
                                type="text"
                                required
                                onChange={(e) => setTecnicoName(e.target.value)}
                                value={tecnicoName}
                                name="nome"
                                />
                        </Box>

                        <Box>
                            <label htmlFor="tecnico-name">Matrícula</label>
                            <Input
                                width="100%"
                                id="tecnico-name"
                                type="text"
                                required
                                onChange={(e) => setTecnicoMatricula(e.target.value)}
                                value={tecnicoMatricula}
                                name="matricula"
                            />
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            {
                                isFetching ? <Loader /> : <Button>Cadastrar</Button>
                            }
                        </Box>
                    </form>
                </Box>
            </Modal>
        </ThemeProvider>
    )
}