import {  Grid, ThemeProvider} from "@mui/material";

import PageTitle from "../../components/PageTitle/PageTitle";
import TemplateWithoutSidenav from "../../components/TemplateWithoutSidenav/TemplateWithoutSidenav";

import useIsUserAdmin from "../../hooks/useIsUserAdmin";

import 'pure-react-carousel/dist/react-carousel.es.css';
import AdminHome from "./Admin/AdminHome";
import TecnicoHome from "./Tecnico/TecnicoHome";
import oclockTheme from "../../assets/theme";
import { useEffect } from "react";


export default function Home() {
  const isUserAdmin = useIsUserAdmin();

  useEffect(() => {document.title = "O'Clock - Home"}, [])

  return (
    <ThemeProvider theme={oclockTheme}>
      <TemplateWithoutSidenav />

      <PageTitle title={"Página inicial"} />
      <Grid
        container
        sx={{
          padding: ".5em 2em",
        }}
      >
          {
            isUserAdmin ? (<AdminHome />) : (<TecnicoHome />)
          }
      </Grid>
    </ThemeProvider>
  );
}
