import { approvedHourRegistry, pendingHourRegistry } from '../../domain/types';
import { HttpClient } from '../http/httpClient';
import { HttpResponse } from '../http/IHttpClient';

type props = {
  TecnicoMatricula?: string;
}

async function getPendingHours({TecnicoMatricula = ''}: props): Promise<Array<pendingHourRegistry>> {

  const httpClient = new HttpClient();
  const httpResponse = await httpClient.request({
    method: "get",
    url: `/HourRegistry/get-pending-hours`,
    params: TecnicoMatricula !== '' && {matriculaDoTecnico: TecnicoMatricula} 
  });

  const requestResult = httpResponse.body;
  return requestResult
}

export default getPendingHours;