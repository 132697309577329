import { Button } from "@mui/material";
import { useState } from "react";
import ModalObterFolhaDePontoPreenchida from "../../../components/ModalObterFolhaDePontoPreenchida/ModalObterFolhaDePontoPreenchida";

type props = {
    folhaParaImprimir: {
        mes: number;
        ano: number;
    };
    funcionarioPodeImprimir: boolean
}

const ImprimirFolha = ({ folhaParaImprimir, funcionarioPodeImprimir }: props) => {
    const [show, setShow] = useState(false);

    return (
        <>
            {
                funcionarioPodeImprimir &&
                <Button onClick={() => setShow(true)}>
                    Imprimir folha
                </Button>
            }
            <ModalObterFolhaDePontoPreenchida
                folhaParaImprimir={folhaParaImprimir}
                setShow={setShow}
                show={show}
            />
        </>
    )
}

export default ImprimirFolha