import { Backdrop, ThemeProvider, Typography } from "@mui/material";
import clockAnimation from '../../assets/clock-animation.json'
import oclockTheme from "../../assets/theme";

type props = {
    open: boolean;
}

export default function OClockBackdrop({ open }: props){
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: clockAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            progressiveLoad: true
        }
    };

    return (
        <ThemeProvider theme={oclockTheme}>
            <Backdrop
                open={open}
                sx={{
                    backgroundColor: "primary.main",
                    flexDirection: "column"
                }}
            >
                {/* <Lottie
                    options={defaultOptions}
                    height={400}
                    width={400}
                    isClickToPauseDisabled={true}
                    speed={2}
                /> */}
                <Typography
                    sx={{
                        fontSize: "32px",
                        display: "block",
                        fontWeight: 500,
                        color: "white",
                        margin: 0,
                        padding: 0
                    }}
                >
                    Carregando...
                </Typography>
            </Backdrop>
        </ThemeProvider>
      
    )
}