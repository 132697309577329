import { Box, CircularProgress, Typography } from "@mui/material";
import { border } from "@mui/system";

function Loader({loaderMsg}: {loaderMsg?: string}){
    return(
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress 
                color="inherit"
            />
            <Typography>
                {loaderMsg ?? "Carregando..."}
            </Typography>
        </Box>
    )
}

export default Loader;