import AppBar from "@mui/material/AppBar";
import { ThemeProvider } from "@mui/system";
import { Link, NavLink } from "react-router-dom";
import oclockTheme from "../../assets/theme";
import oclockLogo from "../../assets/Group3.png";
import { IconButton, Tooltip, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import LogoutIcon from "@mui/icons-material/Logout";
import style from './style.module.scss'
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import CustomAvatar from "../TecnicoCardInfo/CustomAvatar/CustomAvatar";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

export default function TemplateWithoutSidenav() {

  const { signOut, user } = useContext(AuthContext);

  const isUserAdmin = user?.role === "Administrator";

  return (
    <ThemeProvider theme={oclockTheme}>
      <AppBar
        position="sticky"
        style={{
          backgroundColor: "primary.main",
          padding: " 0.5em 2em",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Link to="/home">
          <img src={oclockLogo} alt="O'Clock logo" />
        </Link> */}
        <Link to="/">
          <img src={oclockLogo} alt="O'Clock logo" />
        </Link>
        <section>
          <NavLink
            to="/home"
            activeClassName={style.AppBarActiveLink}
          >
            <Tooltip
              title={
                <Typography>
                  Página inicial
                </Typography>
              }
              arrow
            >

              <IconButton>
                <HomeIcon
                  sx={{
                    color: "neutral.main",
                  }}
                />
              </IconButton>
            </Tooltip>
          </NavLink>
          {
            isUserAdmin && (
              <NavLink
                to="/colaboradores"
                activeClassName={style.AppBarActiveLink}
              >
                <Tooltip
                  title={
                    <Typography>
                      Colaboradores
                    </Typography>
                  }
                  arrow
                >


                  <IconButton>
                    <PeopleAltIcon
                      sx={{
                        color: "neutral.main",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </NavLink>
            )
          }
          <NavLink
            to="/hora-extra"
            activeClassName={style.AppBarActiveLink}
            isActive={(param1, param2) => {
              return param2.pathname === "/cadastrar-hora" || param2.pathname === "/hora-extra"
            }}
          >
            <Tooltip
              title={
                <Typography>
                  Horas extras
                </Typography>
              }
              arrow
            >

              <IconButton>
                <WatchLaterIcon
                  sx={{
                    color: "neutral.main",
                  }}
                />
              </IconButton>
            </Tooltip>
          </NavLink>

          <NavLink
            to="/desconto-de-hora"
            activeClassName={style.AppBarActiveLink}
            isActive={(param1, param2) => {
              return param2.pathname === "/cadastrar-desconto" || param2.pathname === "/desconto-de-hora"
            }}
          >
            <Tooltip
              title={
                <Typography>
                  Desconto de horas
                </Typography>
              }
              arrow
            >
              <IconButton>
                <BeachAccessIcon
                  sx={{
                    color: "neutral.main",
                  }}
                />
              </IconButton>
            </Tooltip>
          </NavLink>

          <NavLink
            to="/folha-de-ponto"
            activeClassName={style.AppBarActiveLink}
            isActive={(param1, param2) => {
              return param2.pathname === "/folha-de-ponto" || param2.pathname === "/folha-de-ponto"
            }}
          >
            <Tooltip
              title={
                <Typography>
                  Folha de ponto
                </Typography>
              }
              arrow
            >
              <IconButton>
                <PendingActionsIcon
                  sx={{
                    color: "neutral.main",
                  }}
                />
              </IconButton>
            </Tooltip>
          </NavLink>

          {/* <NavLink
            to="/notificacoes"
            activeClassName={style.AppBarActiveLink}
          >
            <IconButton>
              <NotificationsIcon
                sx={{
                  color: "neutral.main",
                }}
              />
            </IconButton>
          </NavLink> */}
        </section>

        <div
          style={{
            display: "flex",
            backgroundColor: "#0F7B88",
            borderRadius: "1em",
            gap: "10px",
            alignItems: "center",
            padding: ".8em",
            fontSize: "12px",
          }}
        >
          <div>
            <CustomAvatar
              name={user?.userName || ""}
            />
          </div>
          <div>
            <p>{user?.userName}</p>
            <p>{user?.userMatricula}</p>
          </div>

          <IconButton>
            <NavLink
              onClick={signOut}
              to="/login"
            >
              <Tooltip
                title={
                  <Typography>
                    Sair
                  </Typography>
                }
                arrow
              >
                <LogoutIcon
                  sx={{
                    color: "neutral.main",
                  }}
                />
              </Tooltip>
            </NavLink>
          </IconButton>
        </div>
      </AppBar>
    </ThemeProvider>
  );
}
