import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useState } from "react";

type props = {
	folhaParaCarregar: {
		mes: number;
		ano: number;
	};
	setFolhaParaCarregar: React.Dispatch<
		React.SetStateAction<{
			mes: number;
			ano: number;
		}>
	>;
}

const AlteraMesAnoDaFolhaParaCarregar = ({ folhaParaCarregar, setFolhaParaCarregar }: props) => {
	const [show, setShow] = useState(false);

	const opcoesMes = [
		{
			label: "JANEIRO",
			value: 1,
		},
		{
			label: "FEVEREIRO",
			value: 2,
		},
		{
			label: "MARÇO",
			value: 3,
		},
		{
			label: "ABRIL",
			value: 4,
		},
		{
			label: "MAIO",
			value: 5,
		},
		{
			label: "JUNHO",
			value: 6,
		},
		{
			label: "JULHO",
			value: 7,
		},
		{
			label: "AGOSTO",
			value: 8,
		},
		{
			label: "SETEMBRO",
			value: 9,
		},
		{
			label: "OUTUBRO",
			value: 10,
		},
		{
			label: "NOVEMBRO",
			value: 11,
		},
		{
			label: "DEZEMBRO",
			value: 12,
		},
	];

	const [formValue, setFormValue] = useState({
		mes: folhaParaCarregar.mes,
		ano: folhaParaCarregar.ano,
	});

	return (
		<div style={{ textAlign: "right" }}>
			<Button onClick={() => setShow(true)}>
				{opcoesMes.find((p) => p.value === folhaParaCarregar.mes)?.label}/
				{folhaParaCarregar.ano}
			</Button>
			<Dialog disableEscapeKeyDown open={show}>
				<DialogContent>
					<Box component='form' sx={{ display: "flex", flexWrap: "wrap" }}>
						<FormControl sx={{ m: 1, minWidth: 120 }}>
							<InputLabel id='demo-dialog-select-label'>Mês</InputLabel>
							<Select
								onChange={(val) =>
									setFormValue({
										mes: val.target.value as number,
										ano: formValue.ano,
									})
								}
								labelId='demo-dialog-select-label'
								id='demo-dialog-select'
								input={<OutlinedInput label='Mês' />}
								defaultChecked
								defaultValue={formValue.mes}
							>
								<MenuItem value={1}>Janeiro</MenuItem>
								<MenuItem value={2}>Fevereiro</MenuItem>
								<MenuItem value={3}>Março</MenuItem>
								<MenuItem value={4}>Abril</MenuItem>
								<MenuItem value={5}>Maio</MenuItem>
								<MenuItem value={6}>Junho</MenuItem>
								<MenuItem value={7}>Julho</MenuItem>
								<MenuItem value={8}>Agosto</MenuItem>
								<MenuItem value={9}>Setembro</MenuItem>
								<MenuItem value={10}>Outubro</MenuItem>
								<MenuItem value={11}>Novembro</MenuItem>
								<MenuItem value={12}>Dezembro</MenuItem>
							</Select>
						</FormControl>
						<FormControl sx={{ m: 1, minWidth: 120 }}>
							<InputLabel id='demo-dialog-select-label'>Ano</InputLabel>
							<Select
								onChange={(val) =>
									setFormValue({
										ano: val.target.value as number,
										mes: formValue.mes,
									})
								}
								labelId='demo-dialog-select-label'
								id='demo-dialog-select'
								input={<OutlinedInput label='Ano' />}
								defaultChecked
								defaultValue={formValue.ano}
							>
								<MenuItem value={2023}>2023</MenuItem>
								<MenuItem value={2024}>2024</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShow(false)}>Cancelar</Button>
					<Button
						onClick={() => {
							setFolhaParaCarregar(formValue);
							setShow(false);
						}}
					>
						Atualizar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default AlteraMesAnoDaFolhaParaCarregar