import { ThemeProvider } from "@emotion/react";
import oclockTheme from "../../assets/theme";
import TemplateWithoutSidenav from "../../components/TemplateWithoutSidenav/TemplateWithoutSidenav";
import style from './DescontoDeHora.module.scss'
import { useHistory } from "react-router-dom";


import { useContext, useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Snackbar, Typography } from "@mui/material";
import useIsUserAdmin from "../../hooks/useIsUserAdmin";

import { AuthContext } from "../../context/authContext";

import { approvedHourDiscount, pendingHourDiscount, repprovedHourDiscount, snackarDataType } from "../../domain/types";
import PaginationOfDiscountCards from "../../components/PaginationOfDiscountCards/PaginationOfDiscountCards";
import { getApprovedDiscounts, getPendingDiscounts, getRepprovedDiscounts } from "../../services/hourDiscount/index";
import approveRegistry from "../../services/administrador/approveRegistry";
import repproveRegistry from "../../services/administrador/repproveRegistry";
import TabsTypeOfRegistry from "../../components/TabsTypeOfRegistry/TabsTypeOfRegistry";
import PageTitle from "../../components/PageTitle/PageTitle";


export default function DescontoDeHora() {
    const history = useHistory()
    const [snackbarData, setSnackbarData] = useState<snackarDataType | null>();

    const [pendingDiscounts, setPendingDiscounts] = useState<Array<pendingHourDiscount>>([]);
    const [approvedDiscounts, setApprovedDiscounts] = useState([{} as approvedHourDiscount]);
    const [repprovedDiscounts, setRepprovedDiscounts] = useState([{} as repprovedHourDiscount]);


    const [isFetching, setIsFetching] = useState(false);

    const { user } = useContext(AuthContext)

    const [sectionToShow, setSectionToShow] = useState<"Pending" | "Approved" | "Repproved">("Pending");

    const isUserAdmin = useIsUserAdmin()

    const getRepprovedDiscountsOnChange = async () => {
        getRepprovedDiscounts(isUserAdmin ? { TecnicoMatricula: "" } : { TecnicoMatricula: user?.userMatricula })
            .then(res => setRepprovedDiscounts(res))
            .catch(e => {
                console.log('Falha ao buscar horas aprovadas', e)
                setSnackbarData({ show: true, message: "Não foi possível carregar os dados no momento. Favor tentar novamente mais tarde." })
            })
            .finally(() => {
                setTimeout(() => {
                    setIsFetching(false)
                }, 100)
            })
    }

    const getApprovedDiscountsOnChange = async () => {
        getApprovedDiscounts(isUserAdmin ? { TecnicoMatricula: "" } : { TecnicoMatricula: user?.userMatricula })
            .then(res => setApprovedDiscounts(res))
            .catch(e => {
                console.log('Falha ao buscar horas aprovadas', e)
                setSnackbarData({ show: true, message: "Não foi possível carregar os dados no momento. Favor tentar novamente mais tarde." })
            })
            .finally(() => {
                setTimeout(() => {
                    setIsFetching(false)
                }, 100)
            })
    }

    const getPendingDiscountsOnChange = async () => {
        getPendingDiscounts(isUserAdmin ? { TecnicoMatricula: "" } : { TecnicoMatricula: user?.userMatricula })
            .then(res => setPendingDiscounts(res))
            .catch(e => {
                console.log('Falha ao buscar horas aprovadas', e)
                setSnackbarData({ show: true, message: "Não foi possível carregar os dados no momento. Favor tentar novamente mais tarde." })
            })
            .finally(() => {
                setTimeout(() => {
                    setIsFetching(false)
                }, 100)
            })
    }

    async function getData() {
        await getPendingDiscountsOnChange()
        await getRepprovedDiscountsOnChange()
        await getApprovedDiscountsOnChange()
    }

    function approve(reg: pendingHourDiscount): void {
        setIsFetching(true)
        approveRegistry(reg.id, user!.userMatricula, "pendingHourDiscount")
            .then((result) => {
                if (result.statusCode === 409) {
                    setSnackbarData({ type: "warning", message: result.body.message, show: true })
                }
                else {
                    setSnackbarData({ type: "success", message: result.body.message, show: true })
                    getData()
                }
            })
            .catch(e => {
                setSnackbarData({ type: "error", message: "Falha ao aprovar esse registro. Tente novamente mais tarde", show: true })
                console.log(e)
            })
            .finally(() => {
                setIsFetching(false)
            })
    }

    function repprove(reg: pendingHourDiscount): void {
        setIsFetching(true)
        repproveRegistry(reg.id, user!.userMatricula, "pendingHourDiscount")
            .then((result) => {
                if (result.statusCode === 409) {
                    setSnackbarData({ type: "warning", message: result.body.message, show: true })
                }
                else {
                    setSnackbarData({ type: "success", message: result.body.message, show: true })
                    getData()
                }
            })
            .catch(e => {
                setSnackbarData({ type: "error", message: "Falha ao reprovar esse registro. Tente novamente mais tarde", show: true })
                console.log(e)
            })
            .finally(() => setIsFetching(false))
    }

    useEffect(() => {
        document.title = "O'Clock - Registros de desconto de horas"

        getPendingDiscountsOnChange()
    }, [])

    useEffect(() => {
        setIsFetching(true)
    }, [sectionToShow])

    return (
        <ThemeProvider theme={oclockTheme}>
            <Snackbar
                open={snackbarData?.show}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
            >
                <Alert
                    variant="filled"
                    severity={snackbarData?.type}
                    onClose={() => {
                        setSnackbarData({ ...snackbarData, show: false });
                    }}
                >
                    <AlertTitle>{snackbarData?.message}</AlertTitle>
                </Alert>
            </Snackbar>

            <TemplateWithoutSidenav />
            {isUserAdmin && <PageTitle title={"Desconto de horas"} />}

            <div className={style.container}>

                {
                    !isUserAdmin && (
                        <div className={style.left}>
                            <div className={style.imgArea}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="386.744" height="284.945" viewBox="0 0 386.744 284.945">
                                    <g id="undraw_researching_22gp" transform="translate(0 -0.001)">
                                        <rect id="Rectangle_166" data-name="Rectangle 166" width="162.563" height="4.769" transform="translate(224.182 105.511)" fill="#e6e6e6" />
                                        <rect id="Rectangle_167" data-name="Rectangle 167" width="41.183" height="41.183" transform="translate(237.62 65.629)" fill="#e6e6e6" />
                                        <rect id="Rectangle_168" data-name="Rectangle 168" width="24.71" height="24.71" transform="translate(245.857 73.866)" fill="#ccc" />
                                        <rect id="Rectangle_169" data-name="Rectangle 169" width="10.404" height="41.183" transform="translate(329.956 65.629)" fill="#e6e6e6" />
                                        <rect id="Rectangle_170" data-name="Rectangle 170" width="10.404" height="4.769" transform="translate(329.956 72.132)" fill="#ccc" />
                                        <rect id="Rectangle_171" data-name="Rectangle 171" width="10.404" height="4.769" transform="translate(329.956 92.94)" fill="#ccc" />
                                        <rect id="Rectangle_172" data-name="Rectangle 172" width="10.404" height="41.183" transform="translate(313.916 65.629)" fill="#e6e6e6" />
                                        <rect id="Rectangle_173" data-name="Rectangle 173" width="10.404" height="4.769" transform="translate(313.916 72.132)" fill="#ccc" />
                                        <rect id="Rectangle_174" data-name="Rectangle 174" width="10.404" height="4.769" transform="translate(313.916 92.94)" fill="#ccc" />
                                        <rect id="Rectangle_175" data-name="Rectangle 175" width="10.404" height="41.183" transform="matrix(0.896, -0.444, 0.444, 0.896, 340, 70.076)" fill="#e6e6e6" />
                                        <rect id="Rectangle_176" data-name="Rectangle 176" width="10.404" height="4.769" transform="matrix(0.896, -0.444, 0.444, 0.896, 342.885, 75.903)" fill="#ccc" />
                                        <rect id="Rectangle_177" data-name="Rectangle 177" width="10.404" height="4.769" transform="matrix(0.896, -0.444, 0.444, 0.896, 352.118, 94.551)" fill="#ccc" />
                                        <path id="Path_493" data-name="Path 493" d="M513.436,170.382l5.24,9.075,5.239,9.075H502.958l5.239-9.075Z" transform="translate(-260.427 -88.222)" fill="#e6e6e6" />
                                        <path id="Path_494" data-name="Path 494" d="M532.315,173.978l5.239,9.075,5.24,9.075H521.837l5.239-9.075Z" transform="translate(-270.203 -90.084)" fill="#e6e6e6" />
                                        <circle id="Ellipse_71" data-name="Ellipse 71" cx="3.035" cy="3.035" r="3.035" transform="translate(262.763 75.166)" fill="#e6e6e6" />
                                        <rect id="Rectangle_178" data-name="Rectangle 178" width="162.563" height="4.769" transform="translate(386.744 44.821) rotate(180)" fill="#e6e6e6" />
                                        <rect id="Rectangle_179" data-name="Rectangle 179" width="41.183" height="41.183" transform="translate(373.306 41.353) rotate(180)" fill="#e6e6e6" />
                                        <rect id="Rectangle_180" data-name="Rectangle 180" width="24.71" height="24.71" transform="translate(365.07 33.117) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_181" data-name="Rectangle 181" width="10.404" height="41.183" transform="translate(280.97 41.353) rotate(180)" fill="#e6e6e6" />
                                        <rect id="Rectangle_182" data-name="Rectangle 182" width="10.404" height="4.769" transform="translate(280.97 11.442) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_183" data-name="Rectangle 183" width="10.404" height="4.769" transform="translate(280.97 32.25) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_184" data-name="Rectangle 184" width="10.404" height="41.183" transform="translate(297.01 41.353) rotate(180)" fill="#e6e6e6" />
                                        <rect id="Rectangle_185" data-name="Rectangle 185" width="10.404" height="4.769" transform="translate(297.01 11.442) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_186" data-name="Rectangle 186" width="10.404" height="4.769" transform="translate(297.01 32.25) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_187" data-name="Rectangle 187" width="10.404" height="41.183" transform="matrix(-0.896, -0.444, 0.444, -0.896, 252.653, 41.524)" fill="#e6e6e6" />
                                        <rect id="Rectangle_188" data-name="Rectangle 188" width="10.404" height="4.769" transform="matrix(-0.896, -0.444, 0.444, -0.896, 265.925, 14.718)" fill="#ccc" />
                                        <rect id="Rectangle_189" data-name="Rectangle 189" width="10.404" height="4.769" transform="matrix(-0.896, -0.444, 0.444, -0.896, 256.692, 33.366)" fill="#ccc" />
                                        <path id="Path_495" data-name="Path 495" d="M730.992,34.634l-5.239,9.075-5.239,9.075h20.957l-5.24-9.075Z" transform="translate(-373.076 -17.933)" fill="#e6e6e6" />
                                        <path id="Path_496" data-name="Path 496" d="M712.113,38.23,706.874,47.3l-5.239,9.075h20.957L717.353,47.3Z" transform="translate(-363.3 -19.795)" fill="#e6e6e6" />
                                        <circle id="Ellipse_72" data-name="Ellipse 72" cx="3.035" cy="3.035" r="3.035" transform="translate(342.094 9.708)" fill="#e6e6e6" />
                                        <rect id="Rectangle_190" data-name="Rectangle 190" width="162.563" height="4.769" transform="translate(386.744 175.739) rotate(180)" fill="#e6e6e6" />
                                        <rect id="Rectangle_191" data-name="Rectangle 191" width="41.183" height="41.183" transform="translate(373.306 172.271) rotate(180)" fill="#e6e6e6" />
                                        <rect id="Rectangle_192" data-name="Rectangle 192" width="24.71" height="24.71" transform="translate(365.07 164.034) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_193" data-name="Rectangle 193" width="10.404" height="41.183" transform="translate(280.97 172.271) rotate(180)" fill="#e6e6e6" />
                                        <rect id="Rectangle_194" data-name="Rectangle 194" width="10.404" height="4.769" transform="translate(280.97 142.359) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_195" data-name="Rectangle 195" width="10.404" height="4.769" transform="translate(280.97 163.167) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_196" data-name="Rectangle 196" width="10.404" height="41.183" transform="translate(297.01 172.271) rotate(180)" fill="#e6e6e6" />
                                        <rect id="Rectangle_197" data-name="Rectangle 197" width="10.404" height="4.769" transform="translate(297.01 142.359) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_198" data-name="Rectangle 198" width="10.404" height="4.769" transform="translate(297.01 163.167) rotate(180)" fill="#ccc" />
                                        <rect id="Rectangle_199" data-name="Rectangle 199" width="10.404" height="41.183" transform="matrix(-0.896, -0.444, 0.444, -0.896, 252.653, 172.441)" fill="#e6e6e6" />
                                        <rect id="Rectangle_200" data-name="Rectangle 200" width="10.404" height="4.769" transform="matrix(-0.896, -0.444, 0.444, -0.896, 265.925, 145.635)" fill="#ccc" />
                                        <rect id="Rectangle_201" data-name="Rectangle 201" width="10.404" height="4.769" transform="matrix(-0.896, -0.444, 0.444, -0.896, 256.692, 164.283)" fill="#ccc" />
                                        <path id="Path_497" data-name="Path 497" d="M730.992,306.129l-5.239,9.075-5.239,9.075h20.957l-5.24-9.075Z" transform="translate(-373.076 -158.511)" fill="#e6e6e6" />
                                        <path id="Path_498" data-name="Path 498" d="M712.113,309.725l-5.239,9.075-5.239,9.075h20.957l-5.239-9.075Z" transform="translate(-363.3 -160.372)" fill="#e6e6e6" />
                                        <circle id="Ellipse_73" data-name="Ellipse 73" cx="3.035" cy="3.035" r="3.035" transform="translate(342.094 140.625)" fill="#e6e6e6" />
                                        <ellipse id="Ellipse_74" data-name="Ellipse 74" cx="191.499" cy="13.601" rx="191.499" ry="13.601" transform="translate(0 257.744)" fill="#e6e6e6" />
                                        <path id="Path_499" data-name="Path 499" d="M797.716,688.651c-31.225-5.558-65.372-4.634-101.19,0,16.325-13.782,30.415-27.564,14.09-41.346,31.845,6.57,36.305,5.9,71.729,0C774.129,661.086,789.5,674.869,797.716,688.651Z" transform="translate(-456.608 -409.689)" fill="#fdc600" />
                                        <path id="Path_500" data-name="Path 500" d="M797.716,688.651c-31.225-5.558-65.372-4.634-101.19,0,16.325-13.782,30.415-27.564,14.09-41.346,31.845,6.57,36.305,5.9,71.729,0C774.129,661.086,789.5,674.869,797.716,688.651Z" transform="translate(-456.608 -409.689)" opacity="0.2" />
                                        <path id="Path_501" data-name="Path 501" d="M798.278,685.415c-26.525-4.436-55.531-3.7-85.957,0,13.868-11,25.837-22,11.969-33,27.051,5.244,30.84,4.713,60.931,0C778.242,663.413,791.3,674.414,798.278,685.415Z" transform="translate(-464.787 -412.334)" fill="#fdc600" />
                                        <circle id="Ellipse_75" data-name="Ellipse 75" cx="14.133" cy="14.133" r="14.133" transform="translate(309.255 227.089)" fill="#2f2e41" />
                                        <path id="Path_502" data-name="Path 502" d="M520.65,466.249l9.793,1.632,4.9,14.145-14.145,8.7-16.321-24.482H520.65Z" transform="translate(-261.419 -241.419)" fill="#a0616a" />
                                        <circle id="Ellipse_76" data-name="Ellipse 76" cx="22.849" cy="22.849" r="22.849" transform="translate(264.128 200.892)" fill="#a0616a" />
                                        <path id="Path_503" data-name="Path 503" d="M442.6,445.919s-55.491,5.984-65.284,4.9a121.233,121.233,0,0,1-14.145-2.176s-6.528,8.7-3.264,12.513a23.542,23.542,0,0,0,6.528,5.44s5.984-1.632,10.337,0,48.419,8.7,56.035,2.176S442.6,445.919,442.6,445.919Z" transform="translate(-281.834 -305.414)" fill="#2f2e41" />
                                        <path id="Path_504" data-name="Path 504" d="M452.643,588.917s-36.45-10.337-42.979-56.035V528.53s-7.212,12.652-9.793,15.233c-2.992,2.992-13.6,29.922-13.057,32.642s0,3.808,0,3.808l-15.777-1.632v-4.9s8.432-25.3,8.977-31.282,20.945-47.6,20.945-47.6,7.616-15.777,20.673,0,14.145,26.658,14.145,26.658L452.643,554.1Z" transform="translate(-288.073 -327.093)" fill="#2f2e41" />
                                        <path id="Path_505" data-name="Path 505" d="M338.29,436.037l-17.409-5.984s-16.321-11.969-12.513,0,21.217,38.082,26.113,35.362,10.785-9.472,9.473-10.448S333.938,446.918,338.29,436.037Z" transform="translate(-255.325 -294.444)" fill="#2f2e41" />
                                        <path id="Path_506" data-name="Path 506" d="M349.153,659.259,335.1,666.122s-16.995,2.615-7.517,7.517,35.624,10.132,36.931,5.556.789-12.169-.586-11.967S353.075,668.41,349.153,659.259Z" transform="translate(-264.084 -415.88)" fill="#2f2e41" />
                                        <path id="Path_507" data-name="Path 507" d="M523.375,607.275s-7.072,30.466,4.9,36.994,95.75,7.616,102.278-4.353,3.264-11.969,3.264-11.969L616.4,608.363l14.145-1.088a5.078,5.078,0,0,0-4.9-4.9c-4.9-.544-20.129-5.984-33.186-2.176s-20.673-7.616-20.673-7.616Z" transform="translate(-365.878 -381.357)" fill="#575a89" />
                                        <path id="Path_508" data-name="Path 508" d="M297.506,268.148l-9.249,50.6,63.652-7.616,11.424-45.7Z" transform="translate(-149.257 -137.436)" fill="#3f3d56" />
                                        <path id="Path_509" data-name="Path 509" d="M303.207,272.1l-7.616,45.155,57.939-6.8,11.152-41.075Z" transform="translate(-153.054 -139.481)" fill="#fff" />
                                        <path id="Path_510" data-name="Path 510" d="M289.265,376.537l-2.176-.544L286,378.169l17.409,48.419,1.637-.626.539-3.182Z" transform="translate(-148.089 -194.686)" fill="#b3b3b3" />
                                        <path id="Path_511" data-name="Path 511" d="M288.257,367.27l17.409,50.6,65.828-9.793L351.909,360.2Z" transform="translate(-149.257 -186.507)" fill="#d0cde1" />
                                        <path id="Path_512" data-name="Path 512" d="M306.309,365.638v2.72l48.419-5.44-.544-2.72Z" transform="translate(-158.604 -186.507)" fill="#3f3d56" />
                                        <path id="Path_513" data-name="Path 513" d="M308.565,377.5l7.616,21.217,49.507-5.984-8.7-20.129Z" transform="translate(-159.772 -192.933)" fill="#3f3d56" />
                                        <path id="Path_514" data-name="Path 514" d="M612.569,575.37l-1.632-4.352s-12.513-21.761-19.041-17.409,13.6,25.569,13.6,25.569h6.528Z" transform="translate(-401.757 -360.877)" fill="#a0616a" />
                                        <path id="Path_515" data-name="Path 515" d="M547.342,578.622l-1.632-6.528s-4.352-20.129,3.264-19.041,9.793,20.129,9.793,20.129l-.544,4.352Z" transform="translate(-377.876 -360.865)" fill="#a0616a" />
                                        <path id="Path_516" data-name="Path 516" d="M561.1,599.72s-14.145-2.176-14.689,1.088-3.264,52.227,10.337,53.859,81.061,8.7,73.988-8.16-17.953-14.689-17.953-14.689l-46.242,4.9Z" transform="translate(-378.577 -384.683)" fill="#575a89" />
                                        <path id="Path_517" data-name="Path 517" d="M441.374,448.109l-14.417-10.065-9.521,7.345,11.969,13.057Z" transform="translate(-216.145 -226.815)" fill="#575a89" />
                                        <path id="Path_518" data-name="Path 518" d="M386.976,479.224l2.176,20.673,37.538-2.176-32.642-.544Z" transform="translate(-200.373 -248.138)" opacity="0.2" />
                                        <circle id="Ellipse_77" data-name="Ellipse 77" cx="27.202" cy="27.202" r="27.202" transform="translate(271.055 200.692)" fill="#2f2e41" />
                                        <path id="Path_519" data-name="Path 519" d="M328.309,293.132l33.73-1.174,3.264-12.426-34.818,1.051Z" transform="translate(-169.995 -144.738)" fill="#fdc600" />
                                        <path id="Path_520" data-name="Path 520" d="M310.258,318.313l51.139-1.632.544-2.176-51.139,1.8Z" transform="translate(-160.649 -162.848)" fill="#e6e6e6" />
                                        <path id="Path_521" data-name="Path 521" d="M308,329.6l51.139-1.632.544-2.176-51.139,1.8Z" transform="translate(-159.48 -168.689)" fill="#e6e6e6" />
                                        <path id="Path_522" data-name="Path 522" d="M306.873,338.621l51.139-1.632.544-2.176-51.139,1.8Z" transform="translate(-158.896 -173.363)" fill="#e6e6e6" />
                                    </g>
                                </svg>
                            </div>
                            <div className={style.description}>
                                <p className={style.header}>Aqui é onde vêm parar seus cadastros de desconto de horas!</p>
                                <Typography
                                    component="span"
                                    sx={{
                                        fontSize: "22px"
                                    }}
                                >
                                    Para cadastrar novas horas acesse o&nbsp;
                                    <Typography
                                        component="a"
                                        sx={{
                                            color: "secondary.main",
                                            fontSize: "22px",
                                            fontWeight: 500,
                                            ":hover": {
                                                cursor: "pointer",
                                                textDecoration: "underline"
                                            }
                                        }}
                                        onClick={() => { history.push("/cadastrar-desconto") }}
                                    >
                                        ambiente de cadastro de desconto de hora
                                    </Typography>
                                </Typography>
                            </div>

                        </div>
                    )
                }

                <div className={style.right}>
                    {!isUserAdmin && <PageTitle title={"Desconto de horas"} />}

                    <div className={style.form} >
                        <fieldset className={style.fieldset}>
                            <TabsTypeOfRegistry
                                sectionToShow={sectionToShow}
                                onApprovedSelect={() => {
                                    setSectionToShow("Approved")
                                    getApprovedDiscountsOnChange()
                                }}
                                onRepprovedSelect={() => {
                                    setSectionToShow("Repproved")
                                    getRepprovedDiscountsOnChange()
                                }}
                                onPendingSelect={() => {
                                    setSectionToShow("Pending")
                                    getPendingDiscountsOnChange()
                                }}
                            />
                            <Box
                                marginTop="5%"
                            >
                                <PaginationOfDiscountCards
                                    approvedDiscounts={approvedDiscounts}
                                    repprovedDiscounts={repprovedDiscounts}
                                    pendingDiscounts={pendingDiscounts}
                                    sectionToShow={sectionToShow}
                                    approveRegistry={approve}
                                    repproveRegistry={repprove}
                                    isFetching={isFetching}
                                />
                            </Box>
                        </fieldset>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}