import { repprovedHourRegistry } from "../../domain/types";
import { HttpClient } from "../http/httpClient";

type props = {
  TecnicoMatricula?: string;
};

async function getRepprovedHours({
  TecnicoMatricula = "",
}: props): Promise<Array<repprovedHourRegistry>> {
  const httpClient = new HttpClient();
  const httpResponse = await httpClient.request({
    method: "get",
    url: `/HourRegistry/get-repproved-hours`,
    params: TecnicoMatricula !== "" && { matriculaDoTecnico: TecnicoMatricula },
  });

  const requestResult = httpResponse.body;
  return requestResult;
}

export default getRepprovedHours;
