import { Avatar, Grid, Typography } from "@mui/material";
import Box from "@mui/system/Box";
import { tecnico } from "../../domain/types";
import CustomAvatar from "./CustomAvatar/CustomAvatar";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
type props = {
    tecnico: tecnico;
}



export default function TecnicoCardInfo({ tecnico }: props) {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                alignItems: "center",
                padding: "20px 10px",
                backgroundColor: "#FFFFFF",
                gap: "5px",
                borderRadius: "10px",
                boxShadow: "0 0 5px #0000001f",
                width: "400px",
                maxWidth: "400px",
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}
        >
            <Grid item >
                <CustomAvatar name={tecnico.name} />
            </Grid>
            
            <Grid 
                item
                xs
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: '250px',
                }}
            >
                <Box
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis!important'
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "110%",
                            overflow: 'hidden',
                            textOverflow: 'ellipsis!important'
                        }}
                        title={tecnico.name}
                    >
                        {tecnico.name}
                    </Typography>
                    <Typography>
                        {tecnico.matricula}
                    </Typography>
                    <Typography>
                        {`Possui ${tecnico.hourBalance} hora(s) e ${tecnico?.minutesBalance} minuto(s)`}
                    </Typography>
                </Box>
                
            </Grid>
        
            <Grid item>
                <Box>
                    {
                        tecnico.hasNegativeBalance ?
                            (
                                <ArrowDropDownIcon
                                    color="error"
                                    sx={{
                                        fontSize: "60px",
                                        padding: 0,
                                        margin: 0
                                    }}
                                />
                            ) :
                            (
                                <ArrowDropUpIcon
                                    color="success"
                                    sx={{
                                        fontSize: "60px"
                                    }}
                                />
                            )
                    }
                </Box>
            </Grid>
        </Grid>
    )
}