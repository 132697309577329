import { ThemeProvider } from "@emotion/react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useContext } from "react";
import theme from "../../assets/theme";
import { AuthContext } from "../../context/authContext";
import { approvedHourDiscount, approvedHourRegistry } from "../../domain/types";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import useIsUserAdmin from "../../hooks/useIsUserAdmin";

type props = {
  registroAprovado: approvedHourDiscount;
  key: number;
};

export default function LembreteCard({ registroAprovado, key }: props) {
  const isUserAdmin = useIsUserAdmin();
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "white",
          marginBottom: "16px",
          borderRadius: "0 0 16px 16px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "white",
            backgroundColor: "#0D8493",
            padding: "10px 25px",
            borderRadius: "16px 16px 0 0",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
            }}
          >
            {registroAprovado.hours} hora(s) e {registroAprovado.minutes}{" "}
            minuto(s)
          </Typography>
          <BeachAccessIcon fontSize="large" />
        </Box>

        <Box
          sx={{
            padding: "10px 25px"
          }}
        >
          <Typography
            sx={{
              color: "#0D8493",
              fontSize: "20px",
            }}
          >
            Período
          </Typography>
          <p>
            {registroAprovado.startDate} - {registroAprovado.endDate}
          </p>


          <Typography
            sx={{
              color: "#0D8493",
              fontSize: "20px",
            }}
          >
            {isUserAdmin ? 'Colaborador' : 'Aprovado por'}
          </Typography>
          <p>
            {isUserAdmin? registroAprovado.tecnicoName : registroAprovado.approvedBy}
          </p>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
