import { useState } from "react";
import {
  approvedHourDiscount,
  pendingHourDiscount,
  pendingHourRegistry,
  tecnico,
} from "../../domain/types";
import isApprovedRegistryInThePast from "../../helpers/isApprovedRegistryInThePast";
import getTecnicos from "../administrador/getTecnicos";
import { getApprovedDiscounts, getPendingDiscounts } from "../hourDiscount";
import getPendingHours from "../hourRegistries/getPendingHours";

type funcReturn = {
  tecnicoWithLessHours: tecnico | undefined;
  tecnicoWithMoreHours: tecnico | undefined;
  pendingHours: pendingHourRegistry[];
  pendingDiscounts: pendingHourDiscount[];
  approvedDiscountsToRemember: approvedHourDiscount[];
};

const getDataToAdminHomePage = async () => {
  let funcReturn: funcReturn = {
    tecnicoWithLessHours: undefined,
    tecnicoWithMoreHours: undefined,
    pendingHours: [],
    pendingDiscounts: [],
    approvedDiscountsToRemember: [],
  };

  await getTecnicos().then((tecnicos) => {
    if (tecnicos.length === 0) return;

    let tecnicoWithMoreHours = tecnicos?.reduce((tecWithMoreHours, tecnico) =>
      tecnico.hourBalance + tecnico.minutesBalance >
      tecWithMoreHours.hourBalance + tecnico.minutesBalance
        ? tecnico
        : tecWithMoreHours
    );

    var tecnicoWithLessHours = tecnicos?.reduce((tecWithLessHours, tecnico) => {
     
      const totalOfNewTec = tecnico.hourBalance + tecnico.minutesBalance
      const total_of_tec_with_less_hours_so_far = tecWithLessHours.hourBalance + tecWithLessHours.minutesBalance
      return totalOfNewTec < total_of_tec_with_less_hours_so_far
        ? tecnico
        : tecWithLessHours;
    }, tecnicoWithMoreHours);

    funcReturn.tecnicoWithLessHours = tecnicoWithLessHours;
    funcReturn.tecnicoWithMoreHours = tecnicoWithMoreHours;
  });

  await getPendingHours({}).then(
    (result) => (funcReturn.pendingHours = result)
  );

  await getPendingDiscounts({}).then(
    (result) => (funcReturn.pendingDiscounts = result)
  );

  await getApprovedDiscounts({}).then((registries) => {
    let adminLembretes: Array<approvedHourDiscount> = [];

    if (registries.length === 0) return;

    registries?.forEach((r) => {
      const date = r.startDate.split(" ")[0];

      const day = date.split("/")[0];
      const month = date.split("/")[1];
      const year = date.split("/")[2];

      let startDate = new Date();
      startDate.setDate(Number(day));
      startDate.setMonth(Number(month) - 1);
      startDate.setFullYear(Number(year));

      if (isApprovedRegistryInThePast(new Date(startDate))) {
        adminLembretes.push(r);
      }
    });

    funcReturn.approvedDiscountsToRemember = adminLembretes;
  });

  return funcReturn;
};

export default getDataToAdminHomePage;
