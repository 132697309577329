import { request } from "http";
import { SignInCredentials } from "../../domain/types";
import { HttpClient } from "../http/httpClient";

export async function setNewPassword(novaSenha: string, token: string) {
	const httpClient = new HttpClient();

	const httpResponse = await httpClient.request(
		{
			url: "/Auth/redefinir-senha",
			method: "post",
			body: {
				token,
                novaSenha
			},
		},
		"PontoCerto"
	);
	return httpResponse;
}
