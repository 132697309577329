import { createSlice } from "@reduxjs/toolkit";

type Period = {
  started: string | undefined;
  finished: string | undefined;
};

type FuncionarioData = {
  name: string;
  matricula: string;
};

type SliceState = {
  period: Period;
  reason: string;
  funcionarioData: FuncionarioData;
};

const initialState: SliceState = {
  period: {
    started: undefined,
    finished: undefined
  },
  reason: "",
  funcionarioData: {
    name: "",
    matricula: "",
  },
};

const formSlice = createSlice({
  name: "formData",
  initialState,
  reducers: {
    setPeriodStart(state, action) {
      state.period.started = action.payload
    },
    setPeriodEnd(state, action) {
      state.period.finished = action.payload
    },
    setReason(state, action) {
      state.reason = action.payload;
    },
    setFuncionarioName(state, action) {
      state.funcionarioData.name = action.payload;
    },
    setFuncionarioMatricula(state, action) {
      
      state.funcionarioData.matricula = action.payload;
    },
  },
});

export const formSelector = (state: any) => state.form; 
export const {
  setPeriodStart,
  setPeriodEnd,
  setReason,
  setFuncionarioName,
  setFuncionarioMatricula,
} = formSlice.actions;
export default formSlice.reducer
