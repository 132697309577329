import { HttpClient } from '../http/httpClient';
import { HttpResponse } from '../http/IHttpClient';

function registerHour(
  TecnicoMatricula: string,
  Start: number,
  End: number,
  Reason: string
): Promise<HttpResponse> {

  const httpClient = new HttpClient();
  const httpResponse = httpClient.request({
    method: "post",
    url: `/tecnico/register-hour`,
    body: {
      TecnicoMatricula,
      Start,
      End,
      Reason,
    },
  });

  return httpResponse;
}

export default registerHour;