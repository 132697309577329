import { InputHTMLAttributes } from "react";

import style from './InputStyle.module.scss'

type ButtonProps = InputHTMLAttributes<HTMLInputElement> & {
    width?: string;
};

export default function Input({ width,...props}: ButtonProps){
    return <input style={{width: width}} className={style.customInput} {...props} />
}