import { configureStore } from "@reduxjs/toolkit";
import form from "../slices/formSlice";

export const store = configureStore({
	reducer: {
		form,
	},
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ["formData/setPeriodStart", "formData/setPeriodFinish"],
			},
		});
	},
});

export type RootState = ReturnType<typeof store.getState>;
