import { Box } from "@mui/material"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import { Link } from "react-router-dom"
import Button from "../../components/Button/Button"
import horaExtraIcon from "../../assets/hora-extra-icon.png"
const RegisterHourCarousel = () => (
    <Box>
        <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={2}
            interval={3000}
            isPlaying
            infinite
        >
            <Slider>
                <Slide
                    index={0}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="447.819" height="298.972" viewBox="0 0 447.819 298.972">
                        <g id="undraw_Gaming_re_cma2_1_" data-name="undraw_Gaming_re_cma2 (1)" transform="translate(0 0)">
                            <path id="Path_168" data-name="Path 168" d="M793.255,518.256l-4.423,1.884-9.374-16.161,6.527-2.781Z" transform="translate(-356.872 -229.472)" fill="#ffb6b6" />
                            <path id="Path_169" data-name="Path 169" d="M731.505,524.721H726.7l-2.287-18.542h7.1Z" transform="translate(-331.669 -231.752)" fill="#ffb6b6" />
                            <path id="Path_170" data-name="Path 170" d="M852.915,672.6H838.132v-.187a5.754,5.754,0,0,1,5.754-5.754h0l2.7-2.049,5.038,2.049h1.29Z" transform="translate(-452.909 -374.616)" fill="#2f2e41" />
                            <path id="Path_171" data-name="Path 171" d="M925.519,663.684l-13.6,5.8-.073-.172a5.754,5.754,0,0,1,3.037-7.549h0l1.681-2.943,5.438-.091,1.187-.506Z" transform="translate(-486.447 -371.69)" fill="#2f2e41" />
                            <path id="Path_172" data-name="Path 172" d="M849.7,465.087l-7.306,44.488,1.988,6.629-2.609,4.7,3.832,41.234-1.555,3.424,4.345,2.68h8.843l.469-37.33,2.184-5.481v-3.02l4.447-22.763.819,26.038,1.293,5.489-1.086,3.909,1.42,2.738,12.166,26.123h9.915l-.767-5.908h0c1.776-.9-2.667-3.528-3.094-5.473l1.46-3.285L885,543.07l-1.339-12.125,1.412-25.593-.664-34.465c-3.329-4.463-7.022-7.906-11.6-8.87Z" transform="translate(-454.574 -281.86)" fill="#2f2e41" />
                            <circle id="ab6171fa-7d69-4734-b81c-8dff60f9761b" cx="10.02" cy="10.02" r="10.02" transform="translate(402.893 104.599)" fill="#ffb6b6" />
                            <path id="Path_173" data-name="Path 173" d="M812.926,471.686a3.943,3.943,0,0,1,3.685-4.794l4.036-13.419,4.667,5.59-4.548,11.886a3.964,3.964,0,0,1-7.839.737Z" transform="translate(-441.326 -277.948)" fill="#ffb6b6" />
                            <path id="f348f81f-b961-4ae4-bc6b-758f6acfb12a" d="M841.028,382.542l-1.809-3.305-2.331.778s-2.573,1.661-6.177,7.929c-1.667,2.9-5.269,21.491-5.269,21.491l-8.421,22.3,8.585,1.451,7.163-18.375,8.3-17.585Z" transform="translate(-443.244 -243.959)" fill="#fdc600" />
                            <path id="Path_174" data-name="Path 174" d="M873.792,420.682c7.055,0,14.174,1.389,17.089,6.355-.076-1.08-.267-2.952-.466-4.67l-.729-4.615.739-4.232.977-.977-1.75-1.749,1.194-8.834,3.939-20.81a7.557,7.557,0,0,0-4.164-8.218l-3.785-1.82-6.136-5.691-11.1-.083-3.621,4.707-9.532,3.238,1.6,33.1L857,408.829c.235.216.731.677,1,.974.38.42-.1,2.584-.426,3.837l-.443,3.337,1.395,1-1.813,2.142-.362,2.718A82.965,82.965,0,0,1,873.792,420.682Z" transform="translate(-461.25 -237.595)" fill="#fdc600" />
                            <path id="Path_175" data-name="Path 175" d="M893.935,358.469l.128-.284-.049,0Z" transform="translate(-478.458 -234.321)" fill="#2f2e41" />
                            <path id="Path_176" data-name="Path 176" d="M888.585,319.479a2.789,2.789,0,0,0-1.747-.755c-.648.021-1.3.606-1.171,1.24a10.3,10.3,0,0,0-12.391-4.647,5.161,5.161,0,0,0-3.56,3.372,3.039,3.039,0,0,1-.383,1.245,1.421,1.421,0,0,1-1.512.383l-.016,0a.473.473,0,0,0-.572.668l.456.85a3.652,3.652,0,0,1-1.74-.037.473.473,0,0,0-.5.736,8.245,8.245,0,0,0,6.574,3.379c1.821-.011,3.66-.639,5.432-.22a4.718,4.718,0,0,1,3.173,6.624,1.539,1.539,0,0,1,2.153.129,2.953,2.953,0,0,1,.645,2.26,10.483,10.483,0,0,1-1.076,3.52,10.522,10.522,0,0,0,6.237-18.742Z" transform="translate(-465.374 -214.425)" fill="#2f2e41" />
                            <circle id="Ellipse_11" data-name="Ellipse 11" cx="24.912" cy="24.912" r="24.912" transform="translate(74.461 17.82)" fill="#fdc600" />
                            <path id="Path_177" data-name="Path 177" d="M174.589,272.574c-12.5-27.649-12.039-62.7,7.36-86.027a139.8,139.8,0,0,0,50.039,81.91c9.988,7.886,21.918,15.618,24.646,28.048,1.7,7.734-.709,15.915-4.869,22.652s-9.96,12.279-15.693,17.741l-.643,1.869C210.481,321.495,187.089,300.224,174.589,272.574Z" transform="translate(-145.234 -155.737)" fill="#f2f2f2" />
                            <path id="Path_178" data-name="Path 178" d="M193.566,186.44a119.494,119.494,0,0,0,9.7,72.877,51.458,51.458,0,0,0,8.9,13.445,29.514,29.514,0,0,0,13.4,7.856c4.718,1.378,9.7,2.24,14.005,4.741a18.071,18.071,0,0,1,8.443,12.13c1.341,6.205.373,12.522-.789,18.667-1.29,6.822-2.738,13.888-.869,20.747.227.831-1.076,1.134-1.3.3-3.252-11.932,3.071-23.7,2.187-35.7-.412-5.6-2.514-11.105-7.314-14.349-4.2-2.836-9.338-3.73-14.127-5.084a31.493,31.493,0,0,1-13.388-7.193,47.571,47.571,0,0,1-9.382-13.053,115.933,115.933,0,0,1-11.138-34.479,121.614,121.614,0,0,1,.4-41.305c.154-.844,1.431-.444,1.278.395Z" transform="translate(-156.31 -155.293)" fill="#fff" />
                            <path id="Path_179" data-name="Path 179" d="M188.825,300.145a17.928,17.928,0,0,1-21.436-8.914c-.389-.768.785-1.408,1.175-.639a16.6,16.6,0,0,0,19.956,8.251c.818-.267,1.118,1.037.3,1.3Z" transform="translate(-145.777 -203.214)" fill="#fff" />
                            <path id="Path_180" data-name="Path 180" d="M250.682,338.367a34.555,34.555,0,0,0,2.8-25.027c-.224-.832,1.079-1.135,1.3-.3a35.942,35.942,0,0,1-2.962,26.037C251.415,339.835,250.281,339.125,250.682,338.367Z" transform="translate(-183.909 -213.424)" fill="#fff" />
                            <path id="Path_181" data-name="Path 181" d="M191.855,233.315a10.148,10.148,0,0,0,7.953-5.43c.394-.767,1.527-.056,1.136.706a11.372,11.372,0,0,1-8.785,6.026.691.691,0,0,1-.8-.5.672.672,0,0,1,.5-.8Z" transform="translate(-156.777 -174.512)" fill="#fff" />
                            <path id="Path_182" data-name="Path 182" d="M333.693,187.046c-.232.486-.464.971-.691,1.467a133.654,133.654,0,0,0-7.629,20.293c-.162.536-.319,1.081-.465,1.62a140.894,140.894,0,0,0-4.28,51.7,136.834,136.834,0,0,0,3.712,20.455c2.423,9.273,5.788,19.134,5.029,28.393a23.475,23.475,0,0,1-.384,2.88l-42.219,25.787c-.125.01-.245.031-.371.042l-1.639,1.107c-.115-.34-.226-.7-.341-1.035-.067-.2-.125-.4-.193-.6-.042-.133-.083-.267-.129-.384-.014-.044-.028-.089-.036-.123-.045-.118-.075-.232-.115-.34q-.944-2.974-1.849-5.973c-.006-.01-.006-.01,0-.025-4.561-15.241-7.889-31-8.72-46.757-.025-.474-.056-.958-.062-1.444a130.555,130.555,0,0,1,.736-21.293,115.42,115.42,0,0,1,1.977-11.51,95.982,95.982,0,0,1,11.119-27.874c10.259-17.244,25.919-31.014,45.084-36.015C332.716,187.287,333.2,187.164,333.693,187.046Z" transform="translate(-194.241 -155.965)" fill="#f2f2f2" />
                            <path id="Path_183" data-name="Path 183" d="M343.256,187.086a119.5,119.5,0,0,0-36.129,64.031,51.458,51.458,0,0,0-.99,16.092,29.514,29.514,0,0,0,5.969,14.34c2.938,3.941,6.4,7.631,8.328,12.217a18.071,18.071,0,0,1-.561,14.769c-2.665,5.762-7.242,10.223-11.869,14.429-5.138,4.671-10.548,9.441-13.185,16.042-.319.8-1.542.257-1.223-.541,4.587-11.486,16.722-17.075,23.239-27.186,3.041-4.718,4.679-10.381,2.8-15.86-1.644-4.792-5.21-8.6-8.219-12.565a31.493,31.493,0,0,1-6.359-13.8,47.573,47.573,0,0,1,.368-16.071,115.931,115.931,0,0,1,11.866-34.236A121.613,121.613,0,0,1,342.473,186c.632-.581,1.41.507.783,1.085Z" transform="translate(-203.571 -155.411)" fill="#fff" />
                            <path id="Path_184" data-name="Path 184" d="M310.566,274.084a17.928,17.928,0,0,1-11.748-20.022c.152-.847,1.475-.651,1.323.2a16.6,16.6,0,0,0,10.966,18.6c.814.28.268,1.5-.541,1.223Z" transform="translate(-205.862 -186.398)" fill="#fff" />
                            <path id="Path_185" data-name="Path 185" d="M324.215,343.27a34.555,34.555,0,0,0,17.3-18.3c.322-.8,1.545-.257,1.223.541A35.942,35.942,0,0,1,324.7,344.518c-.781.365-1.259-.884-.482-1.247Z" transform="translate(-217.433 -218.934)" fill="#fff" />
                            <path id="Path_186" data-name="Path 186" d="M350.531,226.4a10.148,10.148,0,0,0,9.619.453c.776-.375,1.253.875.482,1.247a11.372,11.372,0,0,1-10.642-.477.691.691,0,0,1-.341-.882.672.672,0,0,1,.882-.341Z" transform="translate(-229.236 -173.959)" fill="#fff" />
                            <path id="Path_187" data-name="Path 187" d="M562.013,216.713c-12.5-27.649-12.039-62.7,7.36-86.027a139.8,139.8,0,0,0,50.039,81.91c9.988,7.886,21.918,15.618,24.646,28.048,1.7,7.734-.709,15.915-4.869,22.652s-9.96,12.279-15.693,17.741l-.643,1.869C597.9,265.634,574.513,244.363,562.013,216.713Z" transform="translate(-322.614 -130.161)" fill="#f2f2f2" />
                            <path id="Path_188" data-name="Path 188" d="M580.99,130.579a119.494,119.494,0,0,0,9.7,72.877,51.46,51.46,0,0,0,8.9,13.445,29.514,29.514,0,0,0,13.4,7.856c4.718,1.378,9.7,2.24,14.005,4.741a18.071,18.071,0,0,1,8.443,12.13c1.341,6.205.372,12.522-.789,18.667-1.29,6.822-2.738,13.888-.869,20.747.227.831-1.076,1.134-1.3.3-3.252-11.932,3.071-23.7,2.187-35.7-.412-5.6-2.514-11.105-7.314-14.349-4.2-2.836-9.338-3.73-14.127-5.084a31.493,31.493,0,0,1-13.388-7.193,47.569,47.569,0,0,1-9.382-13.053,115.931,115.931,0,0,1-11.138-34.479,121.614,121.614,0,0,1,.4-41.305c.154-.845,1.431-.444,1.278.395Z" transform="translate(-333.691 -129.717)" fill="#fff" />
                            <path id="Path_189" data-name="Path 189" d="M576.248,244.284a17.928,17.928,0,0,1-21.436-8.914c-.389-.768.785-1.408,1.175-.639a16.6,16.6,0,0,0,19.956,8.251c.818-.267,1.118,1.037.3,1.3Z" transform="translate(-323.157 -177.638)" fill="#fff" />
                            <path id="Path_190" data-name="Path 190" d="M638.106,282.506a34.555,34.555,0,0,0,2.8-25.027c-.224-.832,1.079-1.135,1.3-.3a35.942,35.942,0,0,1-2.962,26.037C638.838,283.973,637.7,283.264,638.106,282.506Z" transform="translate(-361.289 -187.848)" fill="#fff" />
                            <path id="Path_191" data-name="Path 191" d="M579.278,177.454a10.148,10.148,0,0,0,7.953-5.43c.394-.767,1.527-.056,1.136.706a11.372,11.372,0,0,1-8.784,6.026.691.691,0,0,1-.8-.5.672.672,0,0,1,.5-.8Z" transform="translate(-334.158 -148.937)" fill="#fff" />
                            <path id="Path_192" data-name="Path 192" d="M721.116,131.185c-.232.486-.464.971-.691,1.467a133.638,133.638,0,0,0-7.629,20.293c-.162.536-.319,1.081-.465,1.62a140.9,140.9,0,0,0-4.28,51.7,136.825,136.825,0,0,0,3.712,20.455c2.423,9.273,5.788,19.134,5.029,28.393a23.454,23.454,0,0,1-.384,2.88l-42.219,25.787c-.125.01-.245.031-.371.042l-1.639,1.107c-.115-.34-.226-.7-.341-1.035-.067-.2-.125-.4-.193-.6-.042-.133-.083-.267-.129-.384-.014-.044-.028-.089-.036-.124-.046-.118-.075-.232-.115-.34q-.944-2.975-1.849-5.973c-.006-.01-.006-.01,0-.025-4.561-15.241-7.889-31-8.72-46.757-.025-.474-.056-.958-.062-1.444a130.569,130.569,0,0,1,.736-21.294,115.4,115.4,0,0,1,1.977-11.51,95.981,95.981,0,0,1,11.119-27.874c10.259-17.244,25.919-31.014,45.084-36.015C720.139,131.425,720.62,131.3,721.116,131.185Z" transform="translate(-371.621 -130.389)" fill="#f0f0f0" />
                            <path id="Path_193" data-name="Path 193" d="M730.68,131.225a119.5,119.5,0,0,0-36.129,64.031,51.46,51.46,0,0,0-.99,16.093,29.514,29.514,0,0,0,5.969,14.34c2.938,3.941,6.4,7.631,8.328,12.218a18.071,18.071,0,0,1-.561,14.769c-2.665,5.762-7.242,10.223-11.869,14.429-5.138,4.671-10.548,9.441-13.185,16.042-.319.8-1.542.257-1.223-.541,4.587-11.486,16.722-17.075,23.239-27.186,3.041-4.718,4.679-10.381,2.8-15.86-1.644-4.792-5.21-8.6-8.219-12.565a31.494,31.494,0,0,1-6.359-13.8,47.574,47.574,0,0,1,.368-16.071,115.931,115.931,0,0,1,11.866-34.236A121.612,121.612,0,0,1,729.9,130.14c.632-.581,1.41.507.783,1.085Z" transform="translate(-380.952 -129.835)" fill="#fff" />
                            <path id="Path_194" data-name="Path 194" d="M697.989,218.223A17.928,17.928,0,0,1,686.241,198.2c.152-.847,1.475-.651,1.323.2A16.6,16.6,0,0,0,698.531,217c.814.28.268,1.5-.541,1.223Z" transform="translate(-383.242 -160.822)" fill="#fff" />
                            <path id="Path_195" data-name="Path 195" d="M711.638,287.409a34.554,34.554,0,0,0,17.3-18.3c.322-.8,1.545-.256,1.223.541a35.942,35.942,0,0,1-18.041,19.006c-.781.365-1.259-.884-.482-1.248Z" transform="translate(-394.813 -193.358)" fill="#fff" />
                            <path id="Path_196" data-name="Path 196" d="M737.955,170.542a10.148,10.148,0,0,0,9.619.453c.776-.375,1.253.875.482,1.247a11.372,11.372,0,0,1-10.642-.477.691.691,0,0,1-.341-.882.672.672,0,0,1,.882-.341Z" transform="translate(-406.616 -148.383)" fill="#fff" />
                            <path id="Path_197" data-name="Path 197" d="M275.295,525.666l4.614,3.574,17.941,3.113,19.941-49.571s12.212-38.2,46.4-30.387L474.1,450.441s33.745-3.459,39.322,12.456c1.056,3.014,29.553,69.607,29.553,69.607l19.979-2.82-2.394-56.774L513.11,362.76l-82.481-5.15-84.994,5.883Z" transform="translate(-195.216 -234.057)" fill="#3f3d56" />
                            <circle id="Ellipse_12" data-name="Ellipse 12" cx="31.751" cy="31.751" r="31.751" transform="translate(139.673 158.255)" fill="#2f2e41" />
                            <circle id="Ellipse_13" data-name="Ellipse 13" cx="31.751" cy="31.751" r="31.751" transform="translate(246.159 158.255)" fill="#2f2e41" />
                            <path id="Path_198" data-name="Path 198" d="M375.3,281.425l13.073-2.564s98.429-14.045,160.644,2.564l-4.706,13.189L379.7,293.637Z" transform="translate(-241.005 -195.463)" fill="#e6e6e6" />
                            <path id="Path_199" data-name="Path 199" d="M524.1,350.245H440.57a21.123,21.123,0,0,1-20.843-16.7l-9.142-45.723a7.323,7.323,0,0,1,1.265-5.776,7.785,7.785,0,0,1,5.348-3.142,515.3,515.3,0,0,1,129.665-.028,7.669,7.669,0,0,1,5.271,3.1,7.333,7.333,0,0,1,1.3,5.7L545,333.264A21.059,21.059,0,0,1,524.1,350.245Z" transform="translate(-257.092 -196.141)" fill="#fdc600" />
                            <path id="Path_200" data-name="Path 200" d="M306.766,285.74l.364-6.543a6.319,6.319,0,0,1,3-5.034,52.738,52.738,0,0,1,37.658-6.625,6.339,6.339,0,0,1,4.988,5.3l.486,3.4Z" transform="translate(-209.625 -192.335)" fill="#3f3d56" />
                            <path id="Path_201" data-name="Path 201" d="M739.741,285.735l-46.5-9.5.781-5.47a3.269,3.269,0,0,1,2.447-2.721,52.4,52.4,0,0,1,41.379,7.062,3.3,3.3,0,0,1,1.442,2.539Z" transform="translate(-386.572 -192.329)" fill="#3f3d56" />
                            <path id="Path_202" data-name="Path 202" d="M256.339,494.681a5.859,5.859,0,0,1-3.171-.892c-12.451-7.27-20.213-20.594-23.053-39.589-2.346-15.69-1.366-35.384,2.912-58.535,7.585-41.045,23.484-82.071,29.967-97.8a14.349,14.349,0,0,1,9.8-8.443c13.365-3.287,36.7-8.8,42.791-8.8,8.35,0,12.868,7.861,12.913,7.94l.025.065,7.333,31.8v.459c4.046,13.688,1.04,23.576-2.2,29.461a30.255,30.255,0,0,1-8.1,9.608c-7.137,5.433-18.765,22.937-30.337,45.674-12.026,23.628-20.94,46.943-23.846,62.368-3.557,18.879-8.771,24.659-12.519,26.182A6.644,6.644,0,0,1,256.339,494.681Z" transform="translate(-173.962 -198.805)" fill="#e6e6e6" />
                            <path id="Path_203" data-name="Path 203" d="M524.308,344.774H445.38a20.109,20.109,0,0,1-19.707-16.248l-8.638-44.469a7.337,7.337,0,0,1,6.259-8.68,473.177,473.177,0,0,1,122.521-.028,7.33,7.33,0,0,1,6.224,8.569l-7.973,44.332A20.059,20.059,0,0,1,524.308,344.774Z" transform="translate(-260.049 -194.578)" fill="#e6e6e6" />
                            <path id="Path_204" data-name="Path 204" d="M737.441,494.681a6.649,6.649,0,0,1-2.521-.509c-3.748-1.524-8.962-7.3-12.519-26.182-2.906-15.425-11.821-38.739-23.846-62.368-11.572-22.737-23.2-40.24-30.346-45.68a30.257,30.257,0,0,1-8.094-9.6c-3.236-5.885-6.242-15.773-2.2-29.461l.006-.514,7.335-31.779.017-.031c.045-.079,4.563-7.94,12.913-7.94,6.093,0,29.425,5.515,42.791,8.8a14.351,14.351,0,0,1,9.8,8.443h0c6.483,15.734,22.381,56.76,29.967,97.8,4.278,23.151,5.258,42.845,2.912,58.535-2.84,19-10.6,32.318-23.07,39.6A5.836,5.836,0,0,1,737.441,494.681Z" transform="translate(-369.507 -198.805)" fill="#e6e6e6" />
                            <circle id="Ellipse_14" data-name="Ellipse 14" cx="19.05" cy="19.05" r="19.05" transform="translate(152.373 165.583)" fill="#3f3d56" />
                            <circle id="Ellipse_15" data-name="Ellipse 15" cx="12.7" cy="12.7" r="12.7" transform="translate(158.723 171.933)" fill="#2f2e41" />
                            <circle id="Ellipse_16" data-name="Ellipse 16" cx="19.05" cy="19.05" r="19.05" transform="translate(258.86 165.583)" fill="#3f3d56" />
                            <circle id="Ellipse_17" data-name="Ellipse 17" cx="12.7" cy="12.7" r="12.7" transform="translate(265.21 171.933)" fill="#2f2e41" />
                            <circle id="Ellipse_18" data-name="Ellipse 18" cx="1.465" cy="1.465" r="1.465" transform="translate(211.478 158.744)" fill="#2f2e41" />
                            <circle id="Ellipse_19" data-name="Ellipse 19" cx="1.465" cy="1.465" r="1.465" transform="translate(217.828 158.744)" fill="#2f2e41" />
                            <circle id="Ellipse_20" data-name="Ellipse 20" cx="1.465" cy="1.465" r="1.465" transform="translate(214.897 164.117)" fill="#2f2e41" />
                            <circle id="Ellipse_21" data-name="Ellipse 21" cx="1.465" cy="1.465" r="1.465" transform="translate(220.759 164.117)" fill="#2f2e41" />
                            <circle id="Ellipse_22" data-name="Ellipse 22" cx="1.465" cy="1.465" r="1.465" transform="translate(226.621 164.117)" fill="#2f2e41" />
                            <circle id="Ellipse_23" data-name="Ellipse 23" cx="1.465" cy="1.465" r="1.465" transform="translate(232.482 164.117)" fill="#2f2e41" />
                            <circle id="Ellipse_24" data-name="Ellipse 24" cx="1.465" cy="1.465" r="1.465" transform="translate(224.178 158.744)" fill="#2f2e41" />
                            <circle id="Ellipse_25" data-name="Ellipse 25" cx="1.465" cy="1.465" r="1.465" transform="translate(230.528 158.744)" fill="#2f2e41" />
                            <circle id="Ellipse_26" data-name="Ellipse 26" cx="1.465" cy="1.465" r="1.465" transform="translate(236.878 158.744)" fill="#2f2e41" />
                            <path id="Path_205" data-name="Path 205" d="M542.018,504.852h-12.7a2.2,2.2,0,0,1,0-4.4h12.7a2.2,2.2,0,0,1,0,4.4Z" transform="translate(-310.513 -299.458)" fill="#2f2e41" />
                            <path id="Path_206" data-name="Path 206" d="M394.774,319.182a3.646,3.646,0,0,1-3.539-2.808l-1.948-8.269a3.642,3.642,0,1,1,7.091-1.67l1.948,8.269a3.647,3.647,0,0,1-3.552,4.479Z" transform="translate(-247.363 -209.341)" fill="#3f3d56" />
                            <path id="Path_207" data-name="Path 207" d="M684.1,318.281a3.647,3.647,0,0,1-3.552-4.479l1.948-8.269a3.643,3.643,0,1,1,7.091,1.67l-1.948,8.269A3.646,3.646,0,0,1,684.1,318.281Z" transform="translate(-380.718 -208.928)" fill="#3f3d56" />
                            <path id="Path_208" data-name="Path 208" d="M341.537,356.98a5.835,5.835,0,0,1-3.351-1.058l-4.087-2.861a5.846,5.846,0,0,1-2.492-4.786V339.51a6.646,6.646,0,0,1,6.638-6.638h6.262a6.646,6.646,0,0,1,6.638,6.638v8.851a5.863,5.863,0,0,1-2.376,4.7L345,355.84a5.833,5.833,0,0,1-3.465,1.14Z" transform="translate(-220.998 -222.731)" fill="#3f3d56" />
                            <path id="Path_209" data-name="Path 209" d="M344.507,416.127h-6.262a6.646,6.646,0,0,1-6.638-6.638v-8.765a5.846,5.846,0,0,1,2.492-4.786l4.088-2.862a5.837,5.837,0,0,1,6.815.083l3.767,2.776a5.863,5.863,0,0,1,2.377,4.7v8.851A6.646,6.646,0,0,1,344.507,416.127Z" transform="translate(-220.999 -249.811)" fill="#3f3d56" />
                            <path id="Path_210" data-name="Path 210" d="M376.688,386.2h-8.765a5.846,5.846,0,0,1-4.786-2.492l-2.862-4.088a5.837,5.837,0,0,1,.083-6.815l2.776-3.767a5.863,5.863,0,0,1,4.7-2.377h8.851a6.646,6.646,0,0,1,6.639,6.639v6.262A6.646,6.646,0,0,1,376.688,386.2Z" transform="translate(-233.641 -238.2)" fill="#3f3d56" />
                            <path id="Path_211" data-name="Path 211" d="M310.07,386.2h-8.765a6.646,6.646,0,0,1-6.639-6.638V373.3a6.646,6.646,0,0,1,6.638-6.638h8.851a5.863,5.863,0,0,1,4.7,2.377l2.776,3.767a5.837,5.837,0,0,1,.083,6.815l-2.862,4.088a5.846,5.846,0,0,1-4.786,2.492Z" transform="translate(-204.086 -238.2)" fill="#3f3d56" />
                            <circle id="Ellipse_27" data-name="Ellipse 27" cx="11.235" cy="11.235" r="11.235" transform="translate(318.942 102.081)" fill="#3f3d56" />
                            <circle id="Ellipse_28" data-name="Ellipse 28" cx="11.235" cy="11.235" r="11.235" transform="translate(343.854 127.97)" fill="#3f3d56" />
                            <circle id="Ellipse_29" data-name="Ellipse 29" cx="11.235" cy="11.235" r="11.235" transform="translate(318.942 152.394)" fill="#3f3d56" />
                            <circle id="Ellipse_30" data-name="Ellipse 30" cx="11.235" cy="11.235" r="11.235" transform="translate(294.03 127.482)" fill="#3f3d56" />
                            <path id="Path_212" data-name="Path 212" d="M776.361,387.62a7.327,7.327,0,1,1,7.327-7.327A7.327,7.327,0,0,1,776.361,387.62Zm0-13.677a6.35,6.35,0,1,0,6.35,6.35A6.35,6.35,0,0,0,776.361,373.943Z" transform="translate(-421.273 -241.088)" fill="#e6e6e6" />
                            <path id="Path_213" data-name="Path 213" d="M738.645,337.926l-14.725-.208,7.543-12.648Zm-13.019-1.161,11.341.161-5.531-9.9Z" transform="translate(-400.618 -219.159)" fill="#e6e6e6" />
                            <path id="Path_214" data-name="Path 214" d="M691.56,386.491H679.837V374.768H691.56Zm-10.746-.977h9.769v-9.769h-9.769Z" transform="translate(-380.434 -241.913)" fill="#e6e6e6" />
                            <path id="Path_215" data-name="Path 215" d="M609.952,302.013l-5.371-5.36,4.736-4.727-.691-.692-4.737,4.728-4.738-4.728-.69.692,4.736,4.727-5.371,5.36.69.692,5.372-5.362,5.372,5.362Z" transform="translate(-273.712 -133.34)" fill="#e6e6e6" />
                            <path id="Path_216" data-name="Path 216" d="M343.7,183.87a7.327,7.327,0,1,1,7.327-7.327A7.327,7.327,0,0,1,343.7,183.87Zm0-13.677a6.35,6.35,0,1,0,6.35,6.35A6.35,6.35,0,0,0,343.7,170.193Z" transform="translate(-223.183 -147.802)" fill="#e6e6e6" />
                            <path id="Path_217" data-name="Path 217" d="M306.889,163.008l-14.725-.208,7.543-12.648Zm-13.019-1.161,11.341.161-5.531-9.9Z" transform="translate(-202.94 -139.073)" fill="#e6e6e6" />
                            <path id="Path_218" data-name="Path 218" d="M269.449,196.834H257.726V185.111h11.723Zm-10.746-.977h9.769v-9.769H258.7Z" transform="translate(-187.172 -155.079)" fill="#e6e6e6" />
                            <path id="Path_219" data-name="Path 219" d="M161.077,108.174l-5.371-5.36,4.736-4.727-.69-.692-4.738,4.728-4.738-4.728-.69.692,4.736,4.727-5.371,5.36.69.692,5.372-5.362,5.372,5.362Z" transform="translate(-68.197 -44.592)" fill="#e6e6e6" />
                            <path id="Path_220" data-name="Path 220" d="M765.536,310.372l-18.96,14.27,21.4-9.385Z" transform="translate(-341.816 -142.103)" opacity="0.2" />
                            <path id="Path_221" data-name="Path 221" d="M111.952,324.657l-23.935.977-2.931-16.12,20.516-6.35Z" transform="translate(-38.956 -138.803)" fill="#a0616a" />
                            <path id="Path_222" data-name="Path 222" d="M241.491,365.656l-5.339-5.436,6.546-10.009,11.2-18.649a4.36,4.36,0,1,1,5.326,2.335l-9.248,19.91Z" transform="translate(-177.295 -219.307)" fill="#a0616a" />
                            <path id="Path_223" data-name="Path 223" d="M225.88,405.716a6.016,6.016,0,0,1-5.936,2.688,5.625,5.625,0,0,1-4.726-3.962,5.363,5.363,0,0,1,.591-4.478,139.009,139.009,0,0,1,10.224-14.051l.1-.126,11.109,2.407Z" transform="translate(-167.598 -246.958)" fill="#fdc600" />
                            <path id="Path_224" data-name="Path 224" d="M124.117,516.961h6.638l3.157-25.6h-9.8Z" transform="translate(-56.825 -224.966)" fill="#a0616a" />
                            <path id="Path_225" data-name="Path 225" d="M248.585,664.3h13.073a8.331,8.331,0,0,1,8.331,8.33v.271h-21.4Z" transform="translate(-182.987 -374.475)" fill="#2f2e41" />
                            <path id="Path_226" data-name="Path 226" d="M28.785,511.9l6.563.992,6.95-24.843-8.6-1.464Z" transform="translate(-13.179 -222.783)" fill="#a0616a" />
                            <path id="Path_227" data-name="Path 227" d="M152.8,658.581l12.926,1.954h0a8.331,8.331,0,0,1,6.992,9.482l-.04.268-21.163-3.2Z" transform="translate(-138.543 -371.856)" fill="#2f2e41" />
                            <path id="Path_228" data-name="Path 228" d="M187.027,462.017l22.958-3.908,7.1,14.642.271,95.563-12.6-.079-5.267-77.347-33.569,71.47-13.536-1.624L181.9,484.122s-1.624-13.536,3.519-20.3Z" transform="translate(-138.944 -280.07)" fill="#2f2e41" />
                            <path id="Path_229" data-name="Path 229" d="M200.312,402.3a5.736,5.736,0,0,1,1.582-6.055l3.263-2.966,9.2-1.55,5.751-1.045,9.244,26.787L206.9,427.783c-1.97-.425-2.757-1.5-1.611-3.646Z" transform="translate(-160.751 -249.199)" fill="#fdc600" />
                            <path id="Path_230" data-name="Path 230" d="M238.566,425.315l6.423-4.1,8.438,8.475,15.929,14.819a4.36,4.36,0,1,1-3.386,4.728L248.4,436.072Z" transform="translate(-178.4 -263.179)" fill="#a0616a" />
                            <path id="Path_231" data-name="Path 231" d="M216.926,404.762a6.016,6.016,0,0,1-1.4-6.363,5.628,5.628,0,0,1,4.854-3.8,5.363,5.363,0,0,1,4.258,1.5,139.033,139.033,0,0,1,11.632,12.91l.1.129-4.653,10.371Z" transform="translate(-167.678 -250.98)" fill="#fdc600" />
                            <circle id="Ellipse_31" data-name="Ellipse 31" cx="10.097" cy="10.097" r="10.097" transform="translate(39.47 119.074)" fill="#a0616a" />
                            <path id="Path_232" data-name="Path 232" d="M198.219,371.961a38.655,38.655,0,0,1-4.677-.285l-.22-.027-1.627-8.83a14.9,14.9,0,0,1,11.132-17.175,1.384,1.384,0,0,1,1.378.427l.388-.135a11.977,11.977,0,0,1,15.252,7.365l.1.276-2.589.9a5.747,5.747,0,0,0-3.034,8.386l2.647,4.406-.282.141A40.909,40.909,0,0,1,198.219,371.961Z" transform="translate(-156.826 -228.405)" fill="#2f2e41" />
                            <path id="Path_233" data-name="Path 233" d="M843.706,461.088a3.943,3.943,0,0,1,5.326-2.862l9.183-10.584,1.964,7.012-9.022,8.976a3.965,3.965,0,0,1-7.452-2.542Z" transform="translate(-455.421 -275.278)" fill="#ffb6b6" />
                            <path id="f348f81f-b961-4ae4-bc6b-758f6acfb12a-2" data-name="f348f81f-b961-4ae4-bc6b-758f6acfb12a" d="M889.873,386.443l3.762-.2.567,2.391s-.056,3.062-3.486,9.427-30.009,34.7-30.009,34.7l-5.756-6.532,14.227-14.654,8.616-15.359Z" transform="translate(-460.61 -247.165)" fill="#fdc600" />
                            <path id="Path_2960" data-name="Path 2960" d="M128.2,680.141H574.8a.609.609,0,0,0,0-1.218H128.2a.609.609,0,1,0,0,1.218Z" transform="translate(-127.591 -381.169)" fill="#ccc" />
                        </g>
                    </svg>

                    <Link to={"/cadastrar-desconto"}>
                        <Button
                            margin="10px auto"
                        >
                            Cadastrar desconto de hora
                        </Button>
                    </Link>
                </Slide>


                <Slide
                    index={1}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <img src={horaExtraIcon} />
                    <Link to={"/cadastrar-desconto"}>
                        <Button
                            margin="20px auto"
                        >
                            Cadastrar hora extra
                        </Button>
                    </Link>
                </Slide>
            </Slider>
        </CarouselProvider>
    </Box >
)
export default RegisterHourCarousel