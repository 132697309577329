import { Typography } from "@mui/material";
import { ThemeProvider } from "@mui/private-theming";
import oclockTheme from "../../assets/theme";

type Props = {
  title: string;
};

export default function PageTitle({ title }: Props) {
  return (
    <ThemeProvider theme={oclockTheme}>
      <Typography
        sx={{
          color: "error.main",
          fontWeight: 300,
          fontSize: "26px",
          padding: ".5em 1.3em",
        }}
      >
        {title}
      </Typography>
    </ThemeProvider>
  );
}
