import { pendingHourDiscount, pendingHourRegistry } from '../../domain/types';
import { HttpClient } from '../http/httpClient';
import { HttpResponse } from '../http/IHttpClient';


async function repproveRegistry(
  registryId: number, 
  AdminMatricula: string,
  registryType: "pendingHourDiscount" | "pendingHourRegistry"): Promise<HttpResponse> {

  const httpClient = new HttpClient();
  const httpResponse = await httpClient.request({
    method: "post",
    url:
      registryType === "pendingHourDiscount"
        ? `/administrator/repprove-discount`
        : `/administrator/repprove-hour`,
    body: {
      ID: String(registryId),
      AdminMatricula,
    },
  });
  return httpResponse
}

export default repproveRegistry;