import { Box, Grid, Tooltip, Typography } from "@mui/material"
import LembreteCard from "../../../components/Lembretes/LembreteCard"
import RegistrosPendentesHomeSection from "../../../components/RegistrosPendentesHomeSection/RegistrosPendentesHomeSection"
import SectionTitle from "../../../components/SectionTitle/SectionTitle"
import { approvedHourDiscount, pendingHourDiscount, pendingHourRegistry } from "../../../domain/types"
import semLembretesIcon from "../../../assets/sem-lembretes.png";
import HelpIcon from '@mui/icons-material/Help';

type props = {
    approvedDiscountsToRemember: approvedHourDiscount[] | undefined
    pendingDiscounts: pendingHourDiscount[] | undefined
    pendingHours: pendingHourRegistry[] | undefined
}

const TecnicoHomeSide = ({ approvedDiscountsToRemember, pendingDiscounts, pendingHours }: props) => (
    <Grid
        md={4}
        item
        sx={{
            display: "flex",
            height: "100vh",
            flexDirection: "column",
        }}
    >
        <section>
            <header style={{
                display: "flex",
                alignItems: "baseline",
                gap: "5px"
            }}>

                <SectionTitle title="Lembretes" />
                <Tooltip
                    title={
                        <Typography>
                            Os lembretes não deixarão você se esquecer de um período de desconto de horas que foi aprovado e ainda não passou
                        </Typography>
                    }

                    arrow
                >
                    <HelpIcon
                        sx={{
                            color: '#0D8493',
                            fontSize: "18px"
                        }} />
                </Tooltip>
            </header>
            {approvedDiscountsToRemember && approvedDiscountsToRemember.length > 0 && <Typography sx={{ marginBottom: "10px" }}>Você tem registros de desconto de horas aprovados.</Typography>}
            {
                approvedDiscountsToRemember && approvedDiscountsToRemember.length > 0 ?
                    (
                        approvedDiscountsToRemember && approvedDiscountsToRemember.map((registry, index) => (
                            <LembreteCard key={index} registroAprovado={registry} />
                        ))
                    )
                    :
                    (
                        <Box
                            sx={{
                                textAlign: "center",
                            }}
                        >
                            <img src={semLembretesIcon} alt="Ícone de não há lembretes" />
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                }}
                            >
                                Não há lembretes para mostrar
                            </Typography>
                        </Box>
                    )
            }
        </section>

        <Box
            sx={{
                marginTop: "15px"
            }}
        >
            <RegistrosPendentesHomeSection
                pendingDiscounts={pendingDiscounts}
                pendingHours={pendingHours}
            />

        </Box>
    </Grid>
)

export default TecnicoHomeSide