import { request } from "http";
import { SignInCredentials } from "../../domain/types"
import { HttpClient } from '../http/httpClient';


export async function login({matricula, password}: SignInCredentials){
    const httpClient = new HttpClient();

    const httpResponse = await httpClient.request({
        url: "/Auth/login",
        method: "post",
        body: {
            matricula,
            senha: password
        },
    }, "PontoCerto")
    return httpResponse;
}

 