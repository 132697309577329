import { Typography, Box, Grid } from "@mui/material"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/authContext";
import TecnicoHomeSide from "./TecnicoHomeSide";
import getDataToTecnicoHomePage from "../../../services/prepareHome/getDataToTecnicoHomePage";
import { approvedHourDiscount, pendingHourDiscount, pendingHourRegistry } from "../../../domain/types";
import RegisterHourCarousel from "../RegisterHourCarousel";

const TecnicoHome = () => {
    const { user } = useContext(AuthContext);
    const [pendingHours, setPendingHours] = useState<pendingHourRegistry[]>()
    const [approvedDiscountsToRemember, setApprovedDiscountsToRemember] = useState<approvedHourDiscount[]>()
    const [pendingDiscounts, setPendingDiscounts] = useState<pendingHourDiscount[]>()

    useEffect(() => {
        async function myAsyncFunction() {
            const { pendingHours, approvedDiscountsToRemember, pendingDiscounts } = await getDataToTecnicoHomePage(user!.userMatricula)
            setPendingHours(pendingHours)
            setApprovedDiscountsToRemember(approvedDiscountsToRemember)
            setPendingDiscounts(pendingDiscounts)
        }
        myAsyncFunction()
    }, [])

    return (
        <>
            <Grid
                md={8} item
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: "30px",
                            fontWeight: "400",
                        }}
                    >
                        Olá, {user?.userName}
                    </Typography>
                    <p>
                        Você possui &nbsp;
                        <strong>
                            {user?.hourBalance} hora(s) e {user?.minutesBalance} minuto(s)
                        </strong>
                        &nbsp; em seu banco de horas...
                    </p>
                    <p>O que gostaria de fazer?</p>

                    <RegisterHourCarousel />
                </Box>
            </Grid>
            <TecnicoHomeSide
                approvedDiscountsToRemember={approvedDiscountsToRemember}
                pendingDiscounts={pendingDiscounts}
                pendingHours={pendingHours}
            />
        </>
    )
}

export default TecnicoHome