import { request } from "http";
import { SignInCredentials } from "../../domain/types";
import { HttpClient } from "../http/httpClient";

export async function getEmailResetPassword(email: string) {
	const httpClient = new HttpClient();

	const httpResponse = await httpClient.request(
		{
			url: "/Auth/gerar-token?email=" + email,
			method: "post",
		},
		"PontoCerto"
	);
	return httpResponse;
}
