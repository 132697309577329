import { Box, CircularProgress, Alert, AlertTitle, Snackbar } from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import Input from "../../../components/Input/Input";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { snackarDataType } from "../../../domain/types";
import Button from "../../../components/Button/Button";


const GerarTokenForm = () => {
    const { isAuthenticated, signIn, sendEmailResetPassword } = useContext(AuthContext);

    const history = useHistory();
    const [email, setEmail] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [snackbarData, setSnackbarData] = useState<snackarDataType>();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions

        setIsFetching(true);

        await sendEmailResetPassword(email)
            .then((result) => {
                setTimeout(() => {
                    if (result.statusCode !== 200) {
                        setSnackbarData({
                            show: true,
                            type: "error",
                            message: result.body.message,
                        });
                    }
                    else {
                        setSnackbarData({
                            show: true,
                            type: "info",
                            message: "O link para redefinição da senha foi enviado para o e-mail informado"
                        });
                        setEmail("")
                    }
                    setIsFetching(false)
                }, 500)
            })
            .catch(erro => {
                setIsFetching(false)
                setSnackbarData({
                    show: true,
                    type: "error",
                    message: "Um erro inesperado ocorreu. Favor entrar em contato com o administrador do sistema",
                });
            })
    };

    return (
        <>
            <Snackbar
                open={snackbarData?.show}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
            >
                <Alert
                    variant="filled"
                    severity={snackbarData?.type}
                    onClose={() => {
                        setSnackbarData({ ...snackbarData, show: false });
                    }}
                >
                    <AlertTitle>{snackbarData?.message}</AlertTitle>
                </Alert>
            </Snackbar>
            <p style={{fontSize: ".9em"}}>Informe no campo abaixo o e-mail cadastrado para receber o link de redefinição de senha</p>

            <section>
                <form
                    style={{
                        textAlign: "center",
                    }}
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <div style={{ margin: "20px 0" }}>
                        <Input
                            id="matricula"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            width="400px"
                        />
                    </div>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        {isFetching ? <CircularProgress color="inherit" /> : <div style={{ display: "flex", gap: "15px" }}>
                            <Button type="button" onClick={() => history.push("/login")}>Voltar</Button>
                            <Button type="submit">Enviar</Button>
                        </div>}
                    </Box>
                </form>
            </section></>
    )
}

export default GerarTokenForm