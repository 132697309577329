import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client"

import "./assets/global.css";
import AuthProvider from "./context/authContext";
import Routes from "./routes";
import history from "./services/http/history";
import { apolloClient } from "./graphql/apollo/client.js"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from "@mui/x-date-pickers";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <Router history={history}>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <Routes />
          </LocalizationProvider>
        </AuthProvider>
      </Router>
    </ApolloProvider>

  </React.StrictMode>,
  document.getElementById("root")
);
