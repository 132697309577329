import { HttpClient } from "../http/httpClient";

type props = {
	registrosId: number[];
	novosDadosDosDias: {
		horaInicioExpediente: string | null | undefined;
		horaFimExpediente: string | null;
		horaInicioAlmoco: string | null;
		horaFimAlmoco: string | null;
		codAbono?: number | null;
	};
};

async function atualizarLoteDeDiasDaFolha({
	novosDadosDosDias,
	registrosId,
}: props): Promise<boolean> {
	const httpClient = new HttpClient();
	const httpResponse = await httpClient.request(
		{
			method: "put",
			url: `/FolhaDePonto`,
			body: {
				IdRegistroDosDiasParaAtualizar: registrosId,
				dadosDiaDeTrabalho: novosDadosDosDias,
			},
		},
		"PontoCerto"
	);

	return httpResponse.statusCode === 200;
}

export default atualizarLoteDeDiasDaFolha;
