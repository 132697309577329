import { approvedHourDiscount, approvedHourRegistry, pendingHourDiscount, pendingHourRegistry } from "../../domain/types";
import isApprovedRegistryInThePast from "../../helpers/isApprovedRegistryInThePast";
import { getApprovedDiscounts, getPendingDiscounts } from "../hourDiscount";
import getPendingHours from "../hourRegistries/getPendingHours";

type funcReturn = {
  pendingHours: pendingHourRegistry[];
  approvedDiscountsToRemember: approvedHourDiscount[];
  pendingDiscounts: pendingHourDiscount[];
};

async function getDataToTecnicoHomePage(userMatricula?: string) {
    let funcReturn: funcReturn = {
      pendingHours: [],
      approvedDiscountsToRemember: [],
      pendingDiscounts: [],
    };

    await getApprovedDiscounts({ TecnicoMatricula: userMatricula }).then(
      (registries) => {
        console.log('registries', registries)
        let userLembretes: Array<approvedHourDiscount> = [];

        if (registries.length === 0) return;
        registries?.forEach((i) => {
          const date = i.startDate.split(" ")[0];

          const day = date.split("/")[0];
          const month = date.split("/")[1];
          const year = date.split("/")[2];

          let startDate = new Date();
          startDate.setDate(Number(day));
          startDate.setMonth(Number(month) - 1);
          startDate.setFullYear(Number(year));

          if (isApprovedRegistryInThePast(new Date(startDate))) {
            userLembretes.push(i);
          }
        });

        funcReturn.approvedDiscountsToRemember = userLembretes;
      }
    );

    await getPendingHours({ TecnicoMatricula: userMatricula }).then(
      (registries) => funcReturn.pendingHours = registries
    );

    await getPendingDiscounts({ TecnicoMatricula: userMatricula }).then(
      (registries) => funcReturn.pendingDiscounts = registries
    );

    return funcReturn
}

export default getDataToTecnicoHomePage;