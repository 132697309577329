import { ThemeProvider } from '@emotion/react';
import { Grid } from '@mui/material';
import oclockTheme from '../../assets/theme';
import PageTitle from '../../components/PageTitle/PageTitle';
import TemplateWithoutSidenav from '../../components/TemplateWithoutSidenav/TemplateWithoutSidenav';
import ConfiguraExpedienteFuncionario from './components/ConfiguraExpedienteFuncionario';
import TabelaFolhaDePonto from './components/TabelaFolhaDePonto';

export default function FolhaDePonto() {
   
    return (
        <ThemeProvider theme={oclockTheme}>
            <TemplateWithoutSidenav />
            <PageTitle title={"Folha de ponto"} />
            <Grid
                spacing={2}
                container
                sx={{
                    padding: "1em 2em",
                }}
            >
                <Grid item xs={12}>
                   <ConfiguraExpedienteFuncionario/>
                </Grid>
                

                <Grid item xs={12}>
                    <div style={{ height: "60vh"}}>
                        <TabelaFolhaDePonto />
                    </div>
                </Grid>
            </Grid>

        </ThemeProvider>
    )
}