import { Box, Grid, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import oclockTheme from '../../assets/theme'
import Button from "../../components/Button/Button";
import OClockBackdrop from "../../components/OClockBackdrop/OClockBackdrop";
import PageTitle from "../../components/PageTitle/PageTitle";
import TecnicoCardInfo from "../../components/TecnicoCardInfo/TecnicoCardInfo";
import TemplateWithoutSidenav from "../../components/TemplateWithoutSidenav/TemplateWithoutSidenav";
import { tecnico } from "../../domain/types";
import getTecnicos from "../../services/administrador/getTecnicos";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ModalRegistrarTecnico from "../../components/ModalRegistrarTecnico/ModalRegistrarTecnico";

export default function Colaboradores() {
    const [openModalRegistrarTecnico, setOpenModalRegistrarTecnico] = useState<boolean>(false)
    const [isFetching, setIsFetching] = useState(false)
    const [tecnicos, setTecnicos] = useState<Array<tecnico>>([])

    const updateTecnicos = () => {
        getTecnicos()
            .then(res => setTecnicos(res))
    }

    useEffect(() => {
        document.title = "O'Clock - Colaboradores cadastrados"

        getTecnicos()
            .then(res => setTecnicos(res))
    }, [])

    if (isFetching) {
        return (
            <OClockBackdrop
                open={isFetching}
            />
        )
    }
    else {
        return (
            <ThemeProvider theme={oclockTheme}>
                <TemplateWithoutSidenav />
                <ModalRegistrarTecnico
                    show={openModalRegistrarTecnico}
                    setShowModal={setOpenModalRegistrarTecnico}
                    updateTecnicos={updateTecnicos}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0.5em 2em 0 0",
                    }}
                >
                    <PageTitle title={"Colaboradores"} />

                    <Button
                        onClick={() => setOpenModalRegistrarTecnico(true)}
                    >
                        <PeopleAltIcon
                            sx={{
                                color: "black",
                            }}
                        />
                        Cadastrar colaborador
                    </Button>
                </Box>

                <Grid
                    container
                    sx={{
                        padding: "0 2em",
                        display: "flex",
                        justifyContent: 'center',
                        gap: '1em 4em'
                    }}
                >

                    {
                        tecnicos.map((tecnico, index) => (

                            <TecnicoCardInfo
                                tecnico={tecnico}
                            />
                        ))
                    }
                </Grid>
            </ThemeProvider>
        )
    }
}